import React from "react"
import styled from "@emotion/styled"

const Styles = styled("div")`
  .st0 {
    opacity: 0.9;
  }
  .st1 {
    fill: none;
  }
  .st2 {
    fill: #efd59c;
  }
  .st3 {
    fill: #eacf95;
  }
  .st4 {
    fill: #e3c990;
  }
  .st5 {
    fill: #e1c588;
  }
  .st6 {
    fill: #f1daa3;
  }
  .st7 {
    fill: none;
    stroke: #eacf95;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .st8 {
    fill: #d0b170;
  }
  .st9 {
    fill: #bf9d5a;
  }
  .st10 {
    fill: #cbab68;
  }
  .st11 {
    fill: #e9cc8e;
  }
  .st12 {
    fill: #d5b875;
  }
  .st13 {
    fill: #dabd7f;
  }
  .st14 {
    fill: none;
    stroke: #dabd7f;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .st15 {
    fill: #b28f49;
  }
  .st16 {
    fill: #a07838;
  }
  .st17 {
    fill: none;
    stroke: #bf9d5a;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .st18 {
    fill: none;
    stroke: #a07838;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .st19 {
    fill: none;
    stroke: #b28f49;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .st20 {
    fill: none;
    stroke: #d5b875;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .st21 {
    fill: #84d3b4;
  }
`

const Logo = ({ baseLayer }) => (
  <Styles>
    <svg
      version="1.1"
      className={baseLayer}
      x="0px"
      y="0px"
      viewBox="0 0 230 115"
    >
      <g className="st0">
        <path
          className="st1"
          d="M86,55.4c0.1-0.1,0.2-0.1,0.3-0.2C86.2,55.3,86.1,55.3,86,55.4z"
        />
        <path
          className="st1"
          d="M30.2,54c-0.1,0-0.2,0-0.2,0C30,53.9,30.1,54,30.2,54z"
        />
        <path
          className="st1"
          d="M33.1,50.1c-0.3,0-0.6,0-0.8,0.2C32.5,50.1,32.8,50,33.1,50.1C33.1,50.1,33.1,50.1,33.1,50.1z"
        />
        <path
          className="st1"
          d="M33.1,49.4L33.1,49.4c-0.1,0-0.1,0-0.2,0C33,49.4,33,49.4,33.1,49.4z"
        />
        <path
          className="st1"
          d="M29.7,53.5c0-0.1,0.1-0.3,0.2-0.4C29.8,53.2,29.7,53.4,29.7,53.5z"
        />
        <path
          className="st1"
          d="M12.8,50.9c0.1,0,0.2-0.1,0.2-0.2C13,50.8,13,50.9,12.8,50.9z"
        />
        <path
          className="st1"
          d="M60.6,57.6c0.1-0.1,0.2-0.1,0.3-0.2h0C60.8,57.5,60.7,57.6,60.6,57.6z"
        />
        <path
          className="st1"
          d="M83.9,53.1c0.1-0.1,0.1-0.1,0.2-0.2l0,0l0,0l0,0c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0-0.2,0
		C84,52.9,84,53,83.9,53.1z"
        />
        <path
          className="st1"
          d="M84.5,52.9L84.5,52.9c0.1-0.1,0.1-0.1,0.2-0.2C84.7,52.7,84.6,52.8,84.5,52.9z"
        />
        <path
          className="st1"
          d="M24,59.6c0.1,0.1,0.1,0.1,0.2,0.2h0C24.2,59.7,24.1,59.6,24,59.6z"
        />
        <path
          className="st1"
          d="M34.6,52.2c0.1,0.1,0.1,0.3,0.2,0.5C34.7,52.5,34.6,52.3,34.6,52.2z"
        />
        <polygon className="st1" points="34.2,50.3 34.2,50.3 34.2,50.3 	" />
        <path
          className="st1"
          d="M34.5,50.9c0-0.2-0.1-0.4-0.4-0.6l0,0C34.5,50.5,34.6,50.7,34.5,50.9z"
        />
        <path
          className="st1"
          d="M15.2,50.7C15.2,50.7,15.2,50.7,15.2,50.7L15.2,50.7C15.2,50.7,15.2,50.7,15.2,50.7z"
        />
        <path
          className="st1"
          d="M12.9,51.2c-0.1-0.1-0.1-0.2-0.2-0.2c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0-0.2,0C12.7,51,12.8,51.1,12.9,51.2z"
        />
        <path
          className="st1"
          d="M82.4,52c0.1-0.1,0.1-0.1,0.2-0.1C82.5,51.9,82.5,51.9,82.4,52c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.2-0.2-0.2-0.2
		c0.1,0.1,0.2,0.2,0.2,0.2C82.1,52,82.3,52,82.4,52z"
        />
        <path
          className="st1"
          d="M34.6,52.2c-0.1-0.1-0.2-0.3-0.4-0.4v0C34.3,51.9,34.5,52,34.6,52.2z"
        />
        <path
          className="st1"
          d="M14.3,59.8c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0C14.4,59.9,14.4,59.9,14.3,59.8z"
        />
        <path
          className="st1"
          d="M12.8,51.3c0-0.1,0.1-0.1,0.1-0.2c0.2,0,0.4,0.1,0.5,0.2c-0.1-0.1-0.3-0.2-0.5-0.2
		C12.8,51.2,12.8,51.3,12.8,51.3z"
        />
        <path
          className="st1"
          d="M34.7,52.6c0,0.2,0,0.3,0.1,0.5C34.8,53,34.8,52.8,34.7,52.6z"
        />
        <path
          className="st1"
          d="M84.1,52c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0-0.2,0-0.2,0c0.1,0,0.1,0,0.2,0C84,52.3,84.1,52.2,84.1,52z"
        />
        <path
          className="st1"
          d="M86.5,54.8c0.1,0,0.2,0,0.3,0C86.6,54.8,86.5,54.8,86.5,54.8C86.5,54.8,86.5,54.8,86.5,54.8z"
        />
        <path
          className="st1"
          d="M87.9,57.9c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.1-0.3,0.1-0.4,0.2
		C88.1,57.8,88,57.9,87.9,57.9z"
        />
        <path
          className="st1"
          d="M88.8,55.4c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.1,0-0.2,0c0.1,0,0.1,0,0.2,0C89,55.7,88.9,55.5,88.8,55.4z"
        />
        <path
          className="st1"
          d="M87.2,54.5c-0.1,0.1-0.3,0.2-0.3,0.3c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0C86.9,54.7,87.1,54.6,87.2,54.5z"
        />
        <path
          className="st1"
          d="M7.1,55.9c-0.1,0-0.2,0-0.3,0C6.7,56,6.9,56,7.1,55.9c0.1,0,0.1,0,0.1,0C7.1,55.9,7.1,55.9,7.1,55.9z"
        />
        <path
          className="st1"
          d="M84.8,55.9c0.1,0,0.1,0,0.2,0c0,0.2,0.1,0.3,0.2,0.3c-0.1,0-0.2-0.1-0.2-0.3C84.9,55.9,84.8,55.9,84.8,55.9
		L84.8,55.9z"
        />
        <path
          className="st1"
          d="M11.4,58L11.4,58c-0.1-0.3-0.3-0.6-0.4-0.9c0,0,0,0,0,0C11.1,57.5,11.2,57.7,11.4,58z"
        />
        <path
          className="st1"
          d="M11,57c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0C11,57,11,57,11,57z"
        />
        <path
          className="st1"
          d="M92.5,56.1c0.1-0.3,0-0.6,0.5-0.6c0,0.3,0,0.6,0,0.8c0,0,0,0,0,0c0-0.3,0-0.6,0-0.8
		C92.5,55.5,92.5,55.8,92.5,56.1z"
        />
        <path
          className="st1"
          d="M63,58.7c-0.1,0-0.1,0-0.2,0l0,0C62.9,58.7,62.9,58.7,63,58.7c0-0.3,0-0.6-0.4-0.6c-0.2,0.1-0.4,0.1-0.6,0.2
		c0.2-0.1,0.4-0.1,0.6-0.2C63,58.1,63,58.4,63,58.7z"
        />
        <path
          className="st1"
          d="M94.5,54.5c0-0.1,0-0.3,0-0.4c0,0,0,0,0,0C94.5,54.3,94.5,54.4,94.5,54.5z"
        />
        <path
          className="st1"
          d="M83.5,55.5c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0,0.4,0,0.8,0.1,1.1c-0.1-0.4-0.1-0.8-0.1-1.1c0,0-0.1,0-0.1,0
		C83.6,55.5,83.6,55.5,83.5,55.5z"
        />
        <polygon className="st1" points="80,53.5 80,53.5 80,53.5 	" />
        <path
          className="st1"
          d="M82,55.2C82,55.2,82,55.2,82,55.2C82,55.2,82,55.2,82,55.2z"
        />
        <path
          className="st1"
          d="M13,56.1c0-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0C13,55.7,13,55.9,13,56.1z"
        />
        <path
          className="st1"
          d="M78.1,54.8L78.1,54.8c0,0.2,0,0.3,0,0.4C78.1,55.1,78.1,55,78.1,54.8z"
        />
        <path
          className="st1"
          d="M61.6,58.7c0,0.2,0.1,0.4,0.1,0.6l0,0C61.7,59.1,61.6,58.9,61.6,58.7z"
        />
        <path
          className="st1"
          d="M79.9,52.6c0-0.2,0-0.3,0.1-0.5C79.9,52.3,79.9,52.4,79.9,52.6z"
        />
        <polygon className="st1" points="57.4,59.3 57.4,59.3 57.4,59.3 	" />
        <path
          className="st1"
          d="M13,56.1c0,0.2-0.1,0.4-0.2,0.6C12.8,56.4,12.9,56.3,13,56.1z"
        />
        <path
          className="st1"
          d="M58.1,59.3c-0.2,0-0.4,0-0.6,0c-0.1-0.2-0.2-0.3-0.3-0.4c0.1,0.1,0.2,0.2,0.3,0.4
		C57.6,59.3,57.9,59.3,58.1,59.3z"
        />
        <path
          className="st1"
          d="M56.6,50.5c0-0.1,0-0.2-0.1-0.3C56.5,50.3,56.5,50.4,56.6,50.5L56.6,50.5z"
        />
        <path
          className="st1"
          d="M11.8,58.4c-0.3,0-0.5,0-0.8,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.3,0-0.5,0
		c-0.1,0.3,0.2,0.3,0.4,0.2c0.3-0.1,0.5-0.1,0.7,0c0.1,0,0.2,0,0.3,0h0.1c0.3,0,0.5,0,0.7-0.1c0.1,0.1,0.2,0.1,0.2,0.1
		c0.1,0,0.1,0,0.2-0.1h0.1h0.1h0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0
		c-0.1,0-0.1,0-0.2-0.1l-0.1-0.1c-0.1,0-0.1,0-0.2,0C12.4,58.4,12.1,58.4,11.8,58.4c-0.1-0.1-0.2-0.2-0.3-0.3
		C11.6,58.2,11.7,58.3,11.8,58.4z"
        />
        <path
          className="st1"
          d="M56.8,50.5c0.1,0,0.2-0.1,0.3-0.1C57,50.4,56.9,50.5,56.8,50.5c-0.1,0.1-0.1,0.1-0.2,0.2
		C56.6,50.7,56.7,50.6,56.8,50.5z"
        />
        <path
          className="st1"
          d="M51.5,56.4C51.5,56.4,51.5,56.4,51.5,56.4C51.5,56.4,51.5,56.4,51.5,56.4z"
        />
        <path
          className="st1"
          d="M52.1,57.8c0.1-0.2,0.2-0.4,0.3-0.6C52.3,57.4,52.2,57.6,52.1,57.8z"
        />
        <path
          className="st1"
          d="M53.6,55.4c-0.1-0.1-0.2-0.1-0.4-0.1C53.3,55.3,53.5,55.3,53.6,55.4z"
        />
        <path
          className="st1"
          d="M67.7,49.1c-0.2,0-0.4,0.3-0.6,0.5c-0.4,0.2-0.7,0.4-1,0.7c0.3-0.2,0.7-0.5,1-0.7
		C67.3,49.4,67.5,49.2,67.7,49.1z"
        />
        <path
          className="st1"
          d="M55.9,48.3c-0.2,0-0.3,0-0.5,0C55.6,48.3,55.8,48.4,55.9,48.3C55.9,48.3,55.9,48.3,55.9,48.3z"
        />
        <path
          className="st1"
          d="M54.4,48.4c0.1,0.1,0.1,0.1,0.2,0.2h0C54.6,48.5,54.5,48.5,54.4,48.4z"
        />
        <polygon className="st1" points="85.8,57.6 85.8,57.6 85.8,57.6 	" />
        <path
          className="st1"
          d="M86.5,58c-0.1,0.1-0.2,0.1-0.2,0.2C86.3,58.2,86.4,58.1,86.5,58c0-0.1,0.1-0.1,0.1-0.2
		C86.6,57.9,86.5,58,86.5,58z"
        />
        <path
          className="st1"
          d="M39.1,53.2c0,0.1,0,0.2,0,0.4C39.1,53.5,39.1,53.3,39.1,53.2z"
        />
        <path
          className="st1"
          d="M51.9,54.8c0.1,0,0.2,0,0.3,0l0,0C52.2,54.8,52,54.8,51.9,54.8z"
        />
        <path
          className="st1"
          d="M45.6,54.2c-0.1,0.1-0.2,0.1-0.2,0.2C45.4,54.3,45.5,54.2,45.6,54.2c0.2,0,0.4,0,0.6,0.1
		C46,54.2,45.8,54.1,45.6,54.2z"
        />
        <path
          className="st1"
          d="M42.3,53.8c-0.1,0-0.2,0-0.3-0.1C42.1,53.8,42.2,53.8,42.3,53.8z"
        />
        <path
          className="st1"
          d="M66.2,50.9C66.2,50.9,66.2,50.9,66.2,50.9c0-0.3,0.2-0.4,0.4-0.4C66.5,50.5,66.3,50.6,66.2,50.9z"
        />
        <path
          className="st1"
          d="M68.3,51.3c-0.6-0.4-1.3-0.5-2-0.4c0,0,0,0,0,0C67,50.8,67.7,50.9,68.3,51.3z"
        />
        <path
          className="st1"
          d="M88.6,57.6c-0.1,0.1-0.1,0.3-0.2,0.4C88.5,57.9,88.6,57.8,88.6,57.6C88.6,57.6,88.6,57.6,88.6,57.6L88.6,57.6
		C88.6,57.6,88.6,57.6,88.6,57.6z"
        />
        <path
          className="st1"
          d="M90.6,58.5c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.1-0.1-0.1-0.3-0.2-0.4
		C90.8,58.6,90.7,58.5,90.6,58.5z"
        />
        <path
          className="st1"
          d="M46,52.7c0.1-0.1,0.1-0.2,0.2-0.3C46.1,52.5,46,52.6,46,52.7z"
        />
        <path
          className="st1"
          d="M45.4,53.1c0.3,0,0.4,0,0.5-0.2C45.8,53,45.6,53.1,45.4,53.1c-0.1,0-0.1,0-0.2,0
		C45.2,53.1,45.3,53.1,45.4,53.1z"
        />
        <polygon className="st1" points="32,50.9 32,50.9 32,50.9 	" />
        <path
          className="st1"
          d="M80.6,50.9c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0C80.5,50.9,80.6,50.9,80.6,50.9z"
        />
        <path
          className="st1"
          d="M33.7,53.5c-0.4,0-0.7,0-1.1,0C33,53.5,33.4,53.5,33.7,53.5c0,0.2-0.2,0.3-0.4,0.4
		C33.6,53.8,33.8,53.8,33.7,53.5z"
        />
        <path
          className="st1"
          d="M80,51.2c-0.1,0-0.2,0-0.3-0.1C79.8,51.1,79.9,51.2,80,51.2L80,51.2z"
        />
        <path
          className="st1"
          d="M31.4,53.4c0,0,0,0.1,0,0.1h0C31.4,53.5,31.4,53.4,31.4,53.4z"
        />
        <path
          className="st1"
          d="M31.8,54c0.1,0,0.3,0.1,0.4,0.2C32.1,54.1,32,54,31.8,54c-0.2,0.2-0.5,0.3-0.8,0.4
		C31.3,54.2,31.6,54.2,31.8,54z"
        />
        <path
          className="st1"
          d="M80.9,52c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.2,0.1-0.2c0,0.1-0.1,0.2-0.1,0.2C80.9,51.9,80.9,51.9,80.9,52z"
        />
        <path
          className="st1"
          d="M64.2,53.5c0.2,0,0.3,0,0.5,0c0,0.1,0,0.3,0,0.4c0-0.1,0-0.3,0-0.4C64.6,53.5,64.4,53.5,64.2,53.5z"
        />
        <path
          className="st1"
          d="M32,51.4c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0,0.3,0,0.4c0-0.1,0-0.3,0-0.4C32.2,51.7,32.1,51.5,32,51.4z"
        />
        <path
          className="st1"
          d="M81.1,51.4C81.1,51.4,81.1,51.4,81.1,51.4c-0.1,0-0.2-0.1-0.3-0.1C80.9,51.3,81,51.4,81.1,51.4z"
        />
        <path
          className="st1"
          d="M87.8,48.7c0.2,0,0.3,0,0.5,0C88.2,48.6,87.9,48.7,87.8,48.7z"
        />
        <path
          className="st1"
          d="M83.2,56.5c-0.3,0-0.5,0-0.8,0.2C82.8,56.6,83,56.6,83.2,56.5c0.2,0.1,0.3,0.2,0.4,0.4
		C83.6,56.8,83.5,56.6,83.2,56.5z"
        />
        <path
          className="st1"
          d="M31.2,58.7c0-0.2,0-0.4-0.1-0.6C31.1,58.3,31.2,58.5,31.2,58.7c0,0.2-0.1,0.4-0.2,0.5
		C31.1,59.1,31.2,58.9,31.2,58.7z"
        />
        <path
          className="st1"
          d="M77.4,55c0.1,0.3,0.3,0.5,0.2,0.9c0,0,0,0,0,0C77.7,55.6,77.6,55.3,77.4,55z"
        />
        <path
          className="st1"
          d="M30.3,60.4c-0.1,0-0.3-0.1-0.4-0.2c0.2,0,0.4,0,0.6,0c-0.2,0-0.4,0-0.6,0c0-0.1-0.1-0.1-0.1-0.2
		c0-0.2,0-0.4,0-0.6c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.2,0
		c0.2,0.1,0.4,0.1,0.7,0.1h0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1C30.1,60.3,30.2,60.4,30.3,60.4z"
        />
        <path
          className="st1"
          d="M84.8,57.2c0.4,0.1,0.7,0.3,1.1,0.4C85.5,57.5,85.1,57.3,84.8,57.2L84.8,57.2z"
        />
        <path
          className="st1"
          d="M79.8,56.7c-0.1,0.1-0.2,0.2-0.4,0.2C79.6,56.9,79.7,56.9,79.8,56.7L79.8,56.7z"
        />
        <path
          className="st1"
          d="M31,53.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0.1,0.1,0.2,0.1,0.2,0.2
		C30.9,53.1,31,53.1,31,53.2z"
        />
        <polygon
          className="st1"
          points="79.8,56.1 79.8,56.1 79.8,56.1 79.8,56.1 	"
        />
        <path
          className="st1"
          d="M30.7,53.9c0,0.1,0,0.3,0,0.4C30.7,54.2,30.7,54.1,30.7,53.9L30.7,53.9z"
        />
        <polygon className="st1" points="30.7,54.4 30.7,54.4 30.7,54.4 	" />
        <path
          className="st1"
          d="M46.9,54.6c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0-0.2c0,0.1,0,0.1,0,0.2C47,54.6,46.9,54.6,46.9,54.6z"
        />
        <path
          className="st1"
          d="M23.8,58.7c0-0.2,0-0.3,0-0.5C23.9,58.4,23.9,58.6,23.8,58.7z"
        />
        <path
          className="st1"
          d="M23.8,58.7c0,0.2,0,0.5,0,0.7C23.8,59.2,23.8,58.9,23.8,58.7z"
        />
        <path
          className="st1"
          d="M24.4,59.7c0.1,0,0.1,0,0.2,0C24.6,59.7,24.5,59.7,24.4,59.7z"
        />
        <path
          className="st1"
          d="M33.6,60.5c-0.1,0-0.2,0.1-0.3,0.1C33.4,60.6,33.8,60.6,33.6,60.5z"
        />
        <path
          className="st1"
          d="M24.7,59.1c-0.1-0.1-0.1-0.3-0.1-0.5C24.6,58.8,24.6,58.9,24.7,59.1z"
        />
        <polygon className="st1" points="24.9,57.6 24.9,57.6 24.9,57.6 	" />
        <path
          className="st1"
          d="M89.5,46.9C89.5,46.9,89.6,46.9,89.5,46.9c0.1-0.1-0.1,0-0.2,0C89.4,46.9,89.5,46.9,89.5,46.9z"
        />
        <path
          className="st1"
          d="M27.6,60.2c-0.1,0-0.2,0-0.2,0.1c0,0,0,0,0,0C27.5,60.3,27.5,60.3,27.6,60.2c0.1,0.1,0.2,0.1,0.3,0.1
		c0.1,0,0.1,0,0.1,0s0.1,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0C28.1,60.2,27.9,60.2,27.6,60.2C27.7,60.2,27.6,60.2,27.6,60.2z"
        />
        <path
          className="st1"
          d="M23.2,56.7L23.2,56.7c0.2,0.1,0.4,0.1,0.6,0.2l0,0c0,0,0,0,0,0C23.6,56.9,23.4,56.8,23.2,56.7z"
        />
        <path
          className="st1"
          d="M87.6,47c0.2-0.1,0.3-0.1,0.4-0.2C87.9,46.8,87.6,46.8,87.6,47z"
        />
        <path
          className="st1"
          d="M24.7,58c0-0.1,0-0.1,0-0.2C24.7,57.9,24.7,58,24.7,58L24.7,58z"
        />
        <path
          className="st1"
          d="M23,56.2c0,0,0.1,0.1,0.2,0.1v0C23.1,56.3,23.1,56.3,23,56.2z"
        />
        <path
          className="st1"
          d="M81.5,59.6c-0.2,0.1-0.3,0.2-0.5,0.3C81.2,59.7,81.4,59.6,81.5,59.6c0.1,0.3,0.3,0.4,0.6,0.5
		C81.8,60,81.6,59.9,81.5,59.6z"
        />
        <path
          className="st1"
          d="M77,53.7c0-0.1,0-0.1,0-0.2C77,53.6,77,53.6,77,53.7z"
        />
        <path
          className="st1"
          d="M109.1,51.1c0.1-0.1,0.3-0.2,0.4-0.2C109.3,51,109.2,51,109.1,51.1c-0.4,0-0.7,0-1.1,0c0,0,0,0,0,0
		C108.4,51.1,108.7,51.1,109.1,51.1z"
        />
        <path
          className="st1"
          d="M22.5,57.5c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0C22.7,57.6,22.5,57.6,22.5,57.5z"
        />
        <path
          className="st1"
          d="M81.1,59.3c0-0.1,0-0.2,0-0.2C81.1,59.2,81.1,59.2,81.1,59.3L81.1,59.3z"
        />
        <path
          className="st1"
          d="M21.6,58.3c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3,0,0.4l0,0c0-0.1,0-0.3,0-0.4C21.7,58.4,21.6,58.4,21.6,58.3z"
        />
        <path
          className="st1"
          d="M83.7,57.5c0-0.2,0-0.4-0.1-0.5C83.7,57.1,83.7,57.3,83.7,57.5z"
        />
        <path
          className="st1"
          d="M27.3,59.3c0.3,0,0.6,0.2,0.9,0.3C27.9,59.5,27.7,59.3,27.3,59.3c-0.2-0.4-0.1-0.7,0-1.2
		C27.2,58.6,27.1,58.9,27.3,59.3z"
        />
        <path
          className="st1"
          d="M5.4,54.8c0.1,0,0.1,0,0.2,0v0C5.5,54.8,5.4,54.8,5.4,54.8z"
        />
        <path
          className="st1"
          d="M20.7,48.2c0.1,0.1,0.2,0,0.3,0C20.9,48.2,20.8,48.2,20.7,48.2C20.8,48.2,20.7,48.2,20.7,48.2z"
        />
        <path
          className="st1"
          d="M11.7,53.1c0.1-0.1,0.1-0.2,0.1-0.3C11.8,52.9,11.7,53,11.7,53.1z"
        />
        <path
          className="st1"
          d="M75.6,53.9C75.5,53.9,75.5,53.9,75.6,53.9C75.5,53.9,75.5,53.9,75.6,53.9z"
        />
        <path
          className="st1"
          d="M9.1,54.2c0,0-0.1-0.1-0.1-0.2C9.1,54.1,9.1,54.1,9.1,54.2z"
        />
        <path
          className="st1"
          d="M9.4,55.4c0,0.1,0,0.1,0,0.2C9.4,55.6,9.4,55.5,9.4,55.4L9.4,55.4z"
        />
        <path
          className="st1"
          d="M20.2,54.8c0.1,0.1,0.2,0.1,0.2,0.2C20.3,55,20.3,54.9,20.2,54.8L20.2,54.8z"
        />
        <path
          className="st1"
          d="M10.4,53.4c-0.1-0.1-0.3,0-0.4,0c0,0,0.1,0,0.1,0C10.2,53.4,10.3,53.4,10.4,53.4z"
        />
        <path
          className="st1"
          d="M20.6,47.9c0.2,0,0.5-0.1,0.7-0.1c-0.1,0-0.2-0.1-0.2-0.1C21,47.8,20.8,47.8,20.6,47.9z"
        />
        <path
          className="st1"
          d="M23.1,47.7c0.1,0.1,0.2,0.1,0.3,0.1C23.3,47.7,23.2,47.7,23.1,47.7z"
        />
        <path
          className="st1"
          d="M104.1,56.2c-0.1-0.1-0.3-0.2-0.4-0.2C103.8,56,103.9,56.1,104.1,56.2z"
        />
        <path
          className="st1"
          d="M23.8,50.1c0.2,0,0.3,0,0.4,0c-0.2-0.1-0.5,0-0.7,0c0,0,0.1,0,0.1,0C23.7,50.1,23.8,50.1,23.8,50.1z"
        />
        <path
          className="st1"
          d="M14.4,52.7L14.4,52.7c0,0.1,0,0.1,0,0.2C14.4,52.8,14.4,52.7,14.4,52.7z"
        />
        <path
          className="st1"
          d="M22.1,50.1L22.1,50.1L22.1,50.1c0.6,0,1,0,1.4,0c-0.1,0-0.1,0-0.2-0.1c0.2,0,0.1,0,0.1-0.1c-0.2,0-0.4,0-0.6,0
		C22.6,49.9,22.4,50,22.1,50.1C22.2,50,22.1,50,22.1,50.1c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0-0.3,0-0.5,0c0.1,0,0.2,0,0.3,0h0.1
		c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0C21.9,50.1,22,50.1,22.1,50.1z"
        />
        <path
          className="st1"
          d="M23,51.2c0,0.1,0,0.1,0,0.2l0,0C23,51.3,23,51.2,23,51.2z"
        />
        <polygon className="st1" points="14.4,52.2 14.4,52.2 14.4,52.2 	" />
        <path
          className="st1"
          d="M11.6,54.8c0,0.2,0,0.4,0,0.7C11.6,55.2,11.6,55,11.6,54.8L11.6,54.8z"
        />
        <path
          className="st1"
          d="M10.9,54.1C10.9,54.1,10.9,54.1,10.9,54.1c0.2,0,0.4,0,0.5,0.1C11.3,54.2,11.1,54.1,10.9,54.1z"
        />
        <path
          className="st1"
          d="M20,47.9c-0.3,0-0.5,0-0.7,0c-0.4,0-0.7,0-1.1,0c-0.2,0-0.3,0-0.5,0c0.2,0.1,0.4,0.1,0.7,0.1h0.1
		c0.6,0,1.1-0.1,1.7-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0C20.1,47.9,20.1,47.9,20,47.9z"
        />
        <polygon className="st1" points="19.5,48.1 19.5,48.1 19.5,48.1 	" />
        <path
          className="st1"
          d="M9.3,54.3c0.1,0,0.2,0,0.4-0.1c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0-0.2,0C9.5,54.2,9.4,54.3,9.3,54.3z"
        />
        <path
          className="st1"
          d="M90.6,57.6c-0.1-0.1-0.2-0.1-0.5,0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0.1,0.1,0.1C90.4,57.6,90.5,57.6,90.6,57.6z"
        />
        <path
          className="st1"
          d="M15,58.9c0.1,0,0.2,0,0.3,0C15.1,58.9,15,58.9,15,58.9L15,58.9z"
        />
        <path
          className="st1"
          d="M89.4,56.5c-0.1,0-0.1-0.1-0.1-0.2C89.3,56.4,89.3,56.5,89.4,56.5z"
        />
        <path
          className="st1"
          d="M89.4,56.5c0.1,0,0.2,0,0.4,0v0C89.5,56.6,89.4,56.6,89.4,56.5z"
        />
        <path
          className="st1"
          d="M90,55.2c0,0.3-0.2,0.7,0,0.8c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0-0.2,0C89.8,55.9,90,55.5,90,55.2z"
        />
        <path
          className="st1"
          d="M11.3,55.7c0,0.1,0,0.1,0,0.2C11.2,55.8,11.2,55.8,11.3,55.7z"
        />
        <path
          className="st1"
          d="M11.6,55.5c0.1-0.1,0.2-0.2,0.3-0.3C11.8,55.2,11.7,55.4,11.6,55.5c-0.2,0-0.3,0.1-0.3,0.3
		C11.3,55.6,11.4,55.5,11.6,55.5z"
        />
        <path
          className="st1"
          d="M15.8,58.8c0,0.1-0.1,0.1-0.2,0.1C15.7,58.8,15.8,58.8,15.8,58.8z"
        />
        <path
          className="st1"
          d="M77,57.5c0,0-0.1,0.1-0.1,0.2C76.9,57.6,77,57.5,77,57.5C77,57.5,77,57.5,77,57.5z"
        />
        <path
          className="st1"
          d="M11.2,56.3c0,0.2-0.1,0.4-0.3,0.5c0,0,0,0,0,0C11.2,56.7,11.2,56.5,11.2,56.3z"
        />
        <path
          className="st1"
          d="M75.8,53.9c-0.1,0-0.2,0-0.3,0C75.6,53.9,75.7,53.9,75.8,53.9c0.3,0,0.5,0,0.5-0.4
		C76.3,53.9,76.1,53.9,75.8,53.9z"
        />
        <path
          className="st1"
          d="M91.2,55.7c0-0.1,0-0.1,0-0.2c0.1,0.1,0.3,0.1,0.4,0.2c-0.1-0.1-0.3-0.1-0.4-0.2
		C91.2,55.5,91.2,55.6,91.2,55.7z"
        />
        <path
          className="st1"
          d="M75.4,52.6c0.1-0.1,0.2-0.1,0.3-0.2h0C75.6,52.5,75.5,52.6,75.4,52.6z"
        />
        <path
          className="st1"
          d="M9.2,58.7c0,0,0.1-0.1,0.1-0.1C9.3,58.6,9.2,58.6,9.2,58.7L9.2,58.7z"
        />
        <path
          className="st1"
          d="M12.3,54.8c-0.1,0.1-0.3,0.2-0.4,0.3C12,55,12.2,54.9,12.3,54.8z"
        />
        <path
          className="st1"
          d="M91.6,55.7c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.1,0.3c0-0.1,0-0.2,0.1-0.3C91.6,55.9,91.6,55.8,91.6,55.7z"
        />
        <path
          className="st1"
          d="M12.2,59.1c0.1,0,0.2,0,0.3,0.1C12.4,59.2,12.3,59.2,12.2,59.1z"
        />
        <path
          className="st1"
          d="M12.7,59.4c0-0.1-0.1-0.1-0.2-0.2C12.6,59.3,12.6,59.3,12.7,59.4z"
        />
        <path
          className="st1"
          d="M90.4,54.8L90.4,54.8c0.2,0,0.4,0,0.6,0.2C90.8,54.9,90.6,54.8,90.4,54.8z"
        />
        <path
          className="st1"
          d="M90.1,55.9c0,0.1,0,0.1,0,0.2C90.1,56,90.1,55.9,90.1,55.9c0.1-0.4,0.2-0.7,0.2-1.1
		C90.3,55.2,90.2,55.5,90.1,55.9z"
        />
        <path
          className="st1"
          d="M9.9,58c0,0.1,0,0.1,0,0.2C9.9,58.2,9.9,58.1,9.9,58C9.9,58,9.9,58,9.9,58z"
        />
        <path
          className="st1"
          d="M80.6,50.5c-0.1-0.1-0.1-0.2-0.2-0.2l0,0C80.5,50.4,80.6,50.5,80.6,50.5c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0
		C80.6,50.7,80.6,50.6,80.6,50.5z"
        />
        <path
          className="st1"
          d="M20,57.6c0.1,0.1,0.1,0.2,0.2,0.2C20.1,57.8,20,57.7,20,57.6L20,57.6z"
        />
        <path
          className="st1"
          d="M20.4,56.5c0.1-0.1,0.3-0.3,0.4-0.4C20.7,56.2,20.5,56.4,20.4,56.5L20.4,56.5z"
        />
        <path
          className="st1"
          d="M80.5,50.7C80.5,50.7,80.5,50.7,80.5,50.7c0,0,0,0.1,0,0.1C80.5,50.8,80.5,50.8,80.5,50.7z"
        />
        <path
          className="st1"
          d="M20.3,57.9c0,0-0.1,0-0.2-0.1C20.2,57.8,20.3,57.9,20.3,57.9z"
        />
        <path
          className="st1"
          d="M79,49.7c0.3,0,0.7,0.1,1,0.2v0C79.7,49.8,79.3,49.7,79,49.7z"
        />
        <path
          className="st1"
          d="M21,55.4c0.1,0.1,0.1,0.2,0.1,0.3C21.1,55.5,21.1,55.5,21,55.4z"
        />
        <path
          className="st1"
          d="M24.5,56.7c0.1-0.1,0.4-0.3,0.8-0.5C24.9,56.4,24.6,56.6,24.5,56.7L24.5,56.7z"
        />
        <path
          className="st1"
          d="M80.7,50.9L80.7,50.9c0.1,0.1,0.3,0.1,0.4,0.2C81,51.1,80.8,51,80.7,50.9z"
        />
        <path
          className="st1"
          d="M20.9,55.3c0.1,0,0.1,0.1,0.2,0.1C21,55.3,20.9,55.3,20.9,55.3z"
        />
        <path
          className="st1"
          d="M13.2,54.8c0.2,0,0.4,0,0.5-0.2c0.2,0,0.4,0,0.6-0.1c-0.2,0.1-0.4,0-0.6,0.1C13.6,54.8,13.4,54.8,13.2,54.8z"
        />
        <path
          className="st1"
          d="M93.1,58.4c0,0-0.1,0-0.1-0.1c-0.1-0.2-0.1-0.5-0.1-0.8c0.1,0.2,0.1,0.6,0.1,0.8C93,58.3,93,58.3,93.1,58.4z"
        />
        <path
          className="st1"
          d="M89.9,56.8c0,0.1,0,0.2,0,0.3C89.9,57,89.9,56.9,89.9,56.8z"
        />
        <path
          className="st1"
          d="M13.2,54.2c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1,0-0.2,0c0.1,0,0.1,0,0.2,0C13.1,54.1,13.1,54.1,13.2,54.2z"
        />
        <path
          className="st1"
          d="M89.9,57.2c0,0.1,0,0.3,0,0.4C89.9,57.4,89.9,57.3,89.9,57.2z"
        />
        <path
          className="st1"
          d="M12.7,54.8c0-0.2,0-0.3,0-0.4C12.7,54.5,12.6,54.7,12.7,54.8c-0.1,0-0.3,0-0.4,0
		C12.4,54.8,12.5,54.8,12.7,54.8z"
        />
        <path
          className="st1"
          d="M83.3,52c0,0.1,0.1,0.3,0.2,0.3C83.4,52.2,83.3,52.1,83.3,52z"
        />
        <path
          className="st1"
          d="M15.3,53.4c0.1-0.2,0.3-0.3,0.6-0.2c-0.1,0.3,0,0.4,0.2,0.5c-0.2-0.1-0.3-0.2-0.2-0.5
		C15.6,53.1,15.4,53.1,15.3,53.4z"
        />
        <path
          className="st1"
          d="M84.5,51.8L84.5,51.8c0.1,0,0.3,0,0.4,0C84.8,51.8,84.7,51.8,84.5,51.8z"
        />
        <path
          className="st1"
          d="M81.5,50.9c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.1,0.1-0.2,0.2c0.1-0.1,0.1-0.1,0.2-0.2
		C81.4,50.9,81.4,50.9,81.5,50.9z"
        />
        <path
          className="st1"
          d="M86.3,51.2c-0.2,0-0.5-0.1-0.7-0.2l0,0C85.8,51,86.1,51.1,86.3,51.2z"
        />
        <path
          className="st1"
          d="M104.5,51.7c0,0.1-0.1,0.2-0.2,0.2C104.3,51.8,104.4,51.8,104.5,51.7z"
        />
        <path
          className="st1"
          d="M63.4,54.5C63.4,54.4,63.4,54.4,63.4,54.5c0.2-0.1,0.2-0.1,0.3,0C63.6,54.4,63.5,54.4,63.4,54.5
		C63.4,54.4,63.4,54.4,63.4,54.5z"
        />
        <path
          className="st1"
          d="M105.1,50.2c-0.2,0.2-0.1,0.5-0.2,0.7c-0.1,0-0.4,0-0.5,0.1c0.1-0.1,0.3-0.1,0.5-0.1
		C105,50.7,105,50.4,105.1,50.2z"
        />
        <path
          className="st1"
          d="M64.8,54.4c0,0,0.1,0,0.2,0C64.9,54.4,64.8,54.4,64.8,54.4z"
        />
        <path
          className="st1"
          d="M104.2,51.9c-0.1,0-0.1,0-0.2,0C104.1,51.9,104.2,51.9,104.2,51.9z"
        />
        <path
          className="st1"
          d="M67.6,57.8C67.6,57.8,67.6,57.8,67.6,57.8c-0.4,0.1-0.7,0.2-1,0.3C66.9,58,67.2,57.9,67.6,57.8z"
        />
        <path
          className="st1"
          d="M62.6,56.1L62.6,56.1c0.1-0.2,0.1-0.4,0.2-0.6h0C62.7,55.7,62.7,55.9,62.6,56.1z"
        />
        <path
          className="st1"
          d="M60,53.5c-0.1,0-0.1,0-0.2,0C59.8,53.5,59.9,53.5,60,53.5z"
        />
        <path
          className="st1"
          d="M100.7,51.9c0,0,0.1-0.1,0.1-0.1C100.8,51.8,100.7,51.8,100.7,51.9z"
        />
        <path
          className="st1"
          d="M95.5,50.7c0,0.1,0,0.1,0,0.2C95.5,50.9,95.5,50.8,95.5,50.7c-0.1,0-0.2,0-0.3-0.1
		C95.3,50.7,95.4,50.7,95.5,50.7z"
        />
        <path
          className="st1"
          d="M66,53.7c0.1,0,0.1,0,0.2,0C66.2,53.8,66.1,53.7,66,53.7L66,53.7z"
        />
        <path
          className="st1"
          d="M67.3,58.9c0-0.1,0-0.3,0-0.4c0.1-0.1,0.2-0.2,0.2-0.3c0,0.1-0.1,0.2-0.2,0.3C67.3,58.6,67.3,58.7,67.3,58.9z"
        />
        <path
          className="st1"
          d="M64.3,54.8L64.3,54.8c0,0.2-0.1,0.3-0.3,0.4C64.2,55.1,64.3,55,64.3,54.8z"
        />
        <path
          className="st1"
          d="M102.4,53.3c0-0.1,0-0.2,0-0.2l0,0C102.4,53.2,102.4,53.2,102.4,53.3z"
        />
        <path
          className="st1"
          d="M103.5,52.4c0-0.2,0-0.4,0-0.6c0.2,0,0.4,0,0.5,0.1c-0.2,0-0.4-0.1-0.5-0.1C103.5,52,103.5,52.2,103.5,52.4
		L103.5,52.4z"
        />
        <path
          className="st1"
          d="M101.3,52.9c-0.1-0.1-0.1-0.3-0.2-0.4C101.2,52.6,101.3,52.7,101.3,52.9L101.3,52.9z"
        />
        <path
          className="st1"
          d="M49.6,57.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0C49.7,57.1,49.7,57.1,49.6,57.1z"
        />
        <path
          className="st1"
          d="M65.2,54.4C65.2,54.4,65.2,54.4,65.2,54.4C65.2,54.4,65.2,54.4,65.2,54.4z"
        />
        <path
          className="st1"
          d="M102.8,52.9L102.8,52.9c-0.1,0.1-0.3,0.1-0.4,0.2l0,0C102.6,53,102.7,53,102.8,52.9z"
        />
        <path
          className="st1"
          d="M100.9,53.5c0.1,0,0.3,0,0.4,0v0C101.2,53.5,101,53.5,100.9,53.5z"
        />
        <path
          className="st1"
          d="M66,53.5c0,0.1,0,0.1,0,0.2h0C66,53.7,66,53.6,66,53.5z"
        />
        <path
          className="st1"
          d="M101.3,52.9c-0.3,0.1-0.4,0.3-0.4,0.6c0,0,0,0,0,0C100.9,53.2,101.1,53,101.3,52.9z"
        />
        <path
          className="st1"
          d="M65.4,53.9c0,0.1,0,0.3,0,0.4h0C65.4,54.2,65.4,54.1,65.4,53.9z"
        />
        <path
          className="st1"
          d="M92.5,49.7c0.2,0,0.3,0,0.5,0c0,0,0,0,0,0C92.8,49.6,92.6,49.6,92.5,49.7z"
        />
        <path
          className="st1"
          d="M74.9,59.5L74.9,59.5c0-0.1,0-0.2,0-0.2l0,0C74.9,59.4,74.9,59.5,74.9,59.5z"
        />
        <path
          className="st1"
          d="M47.9,59.1c0.5,0,1,0,1.4,0C48.9,59.1,48.4,59.1,47.9,59.1L47.9,59.1z"
        />
        <path
          className="st1"
          d="M94.6,53.1c0,0,0,0.1,0,0.1C94.6,53.2,94.6,53.1,94.6,53.1z"
        />
        <path
          className="st1"
          d="M99.3,52.8c0-0.1,0-0.1,0-0.2C99.3,52.7,99.3,52.8,99.3,52.8z"
        />
        <path
          className="st1"
          d="M98.8,52.3c-0.1,0-0.2-0.1-0.2-0.1C98.6,52.2,98.7,52.3,98.8,52.3z"
        />
        <path
          className="st1"
          d="M88.1,51.1c-0.2,0.3-0.4,0.6-0.5,0.9c0,0,0,0,0,0C87.7,51.7,87.9,51.4,88.1,51.1z"
        />
        <path
          className="st1"
          d="M88,50.9c0,0,0.1,0.1,0.2,0.1l0,0C88.1,50.9,88.1,50.9,88,50.9z"
        />
        <path
          className="st1"
          d="M89.7,49.5c-0.2,0.1-0.4,0.2-0.4,0.4l0,0C89.3,49.6,89.5,49.6,89.7,49.5z"
        />
        <path
          className="st1"
          d="M87,52C87,52,87,52,87,52c0,0.3,0,0.7,0,1C87,52.7,87,52.4,87,52z"
        />
        <path
          className="st1"
          d="M93.6,51.3L93.6,51.3c0,0,0.1,0,0.1-0.1C93.7,51.3,93.6,51.3,93.6,51.3z"
        />
        <path
          className="st1"
          d="M74.4,53.3L74.4,53.3c0.1,0.1,0.3,0.1,0.4,0.2C74.7,53.4,74.6,53.4,74.4,53.3z"
        />
        <path
          className="st1"
          d="M92.9,50.2c0,0.1,0.1,0.2,0.3,0.3C93.1,50.4,93,50.4,92.9,50.2z"
        />
        <path
          className="st1"
          d="M94,50.7c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0,0,0,0C94.2,50.9,94.1,50.8,94,50.7z"
        />
        <path
          className="st1"
          d="M72.5,52.1c0.2-0.3,0.6-0.5,0.9-0.7c0.1-0.2,0.2-0.5,0.2-0.7c0,0.2,0,0.4-0.2,0.7
		C73.1,51.6,72.7,51.8,72.5,52.1z"
        />
        <path
          className="st1"
          d="M58.8,52.9c0.1,0,0.1,0,0.2,0C58.9,52.9,58.8,52.9,58.8,52.9z"
        />
        <path
          className="st1"
          d="M66.5,58.5c-0.2,0.1-0.2,0.2-0.3,0.4C66.3,58.8,66.4,58.6,66.5,58.5z"
        />
        <path
          className="st1"
          d="M68.2,58c0,0.2,0,0.4,0,0.7C68.2,58.5,68.2,58.3,68.2,58c-0.1-0.1-0.3-0.2-0.4-0.2C67.9,57.9,68,58,68.2,58z"
        />
        <path
          className="st1"
          d="M90.9,48.6c0.2,0,0.5,0,0.8,0c0,0,0,0,0,0C91.4,48.6,91.2,48.7,90.9,48.6z"
        />
        <path
          className="st1"
          d="M99.4,51.8c0-0.2-0.1-0.3-0.2-0.3C99.3,51.5,99.4,51.6,99.4,51.8z"
        />
        <path
          className="st1"
          d="M74.9,56.9c0.2-0.3,0.4-0.6,0.6-0.8c0,0,0,0,0,0C75.3,56.4,75.1,56.7,74.9,56.9L74.9,56.9z"
        />
        <path
          className="st1"
          d="M71,52.8C71,52.8,71,52.8,71,52.8c0,0,0.1,0.1,0.1,0.1C71.1,52.9,71,52.9,71,52.8z"
        />
        <path
          className="st1"
          d="M95.8,51.4c0-0.1-0.1-0.2-0.3-0.3v0C95.7,51.2,95.8,51.3,95.8,51.4z"
        />
        <path
          className="st1"
          d="M68,52.7c0-0.1,0-0.2,0-0.2c0.1,0,0.3,0,0.4,0c-0.1,0-0.3,0-0.4,0C68,52.5,68,52.6,68,52.7z"
        />
        <path
          className="st1"
          d="M100.1,56.3C100.1,56.3,100.1,56.3,100.1,56.3c-0.4-0.1-0.7-0.4-0.7-0.7C99.4,55.9,99.7,56.2,100.1,56.3z"
        />
        <path
          className="st1"
          d="M96.8,55.9c0.3,0,0.7-0.1,1-0.1C97.5,55.8,97.2,55.8,96.8,55.9c0,0.1-0.1,0.2-0.2,0.3
		C96.7,56.1,96.8,56,96.8,55.9z"
        />
        <path
          className="st1"
          d="M67.7,57.8c-0.1,0-0.1,0-0.2,0C67.6,57.8,67.7,57.8,67.7,57.8c0.1,0,0.2-0.1,0.3-0.1
		C68,57.7,67.9,57.8,67.7,57.8z"
        />
        <path
          className="st1"
          d="M101,55.3c-0.2-0.4-0.4-0.6-0.6-0.5C100.5,54.7,100.7,54.9,101,55.3z"
        />
        <path
          className="st1"
          d="M99.3,55.3c0.2-0.6-0.1-0.9-0.5-1.2c0-0.1,0-0.2-0.1-0.2c0,0.1,0,0.2,0.1,0.2C99.2,54.4,99.6,54.7,99.3,55.3z"
        />
        <path
          className="st1"
          d="M98.7,58.2C98.7,58.2,98.7,58.1,98.7,58.2C98.7,58.1,98.7,58.2,98.7,58.2z"
        />
        <path
          className="st1"
          d="M68.4,55.9c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0C68.3,56,68.3,56,68.4,55.9z"
        />
        <path
          className="st1"
          d="M50.1,58.5c0.1,0,0.2,0,0.3,0C50.3,58.4,50.2,58.4,50.1,58.5L50.1,58.5z"
        />
        <path
          className="st1"
          d="M99,58.1c0-0.2,0.1-0.4,0.2-0.5C99.1,57.7,99,57.8,99,58.1C99,58.1,99,58.1,99,58.1z"
        />
        <path
          className="st1"
          d="M69.2,56c-0.1,0.1-0.2,0.2-0.4,0.3c0-0.2,0-0.3-0.1-0.4c0,0.1,0.1,0.2,0.1,0.4C69,56.2,69.1,56.1,69.2,56z"
        />
        <path
          className="st1"
          d="M88.4,59.3c0.1,0,0.2,0,0.2,0C88.6,59.3,88.5,59.3,88.4,59.3c-0.4-0.2-0.9-0.3-1.2-0.6
		C87.5,59,88,59.1,88.4,59.3z"
        />
        <path
          className="st1"
          d="M97.1,59.5c0,0.1,0,0.3-0.1,0.4C97,59.8,97.1,59.7,97.1,59.5c0.3-0.6,0.1-1-0.2-1.2
		C97.2,58.5,97.4,58.9,97.1,59.5z"
        />
        <path
          className="st1"
          d="M84.5,57.8c0,0.1,0,0.2-0.1,0.3C84.5,57.9,84.5,57.9,84.5,57.8z"
        />
        <path
          className="st1"
          d="M84,58.3c0,0-0.1-0.1-0.1-0.1C84,58.3,84,58.3,84,58.3z"
        />
        <path
          className="st1"
          d="M81.3,58.8c-0.1,0.1-0.2,0.2-0.2,0.3C81.1,59,81.2,58.9,81.3,58.8z"
        />
        <polygon className="st1" points="83.9,58.1 83.9,58.1 83.9,58.1 	" />
        <path
          className="st1"
          d="M86.3,58.9L86.3,58.9C86.3,58.9,86.3,58.9,86.3,58.9z"
        />
        <path
          className="st1"
          d="M94.7,58.9C94.7,58.9,94.7,58.9,94.7,58.9c-0.1-0.2-0.2-0.3-0.3-0.5C94.5,58.6,94.6,58.7,94.7,58.9z"
        />
        <path
          className="st1"
          d="M94,58.5C94,58.5,94,58.5,94,58.5C94,58.5,94,58.5,94,58.5z"
        />
        <path
          className="st1"
          d="M66.1,55.7c-0.2,0.1-0.5,0.4-0.8,0.6c-0.1,0.1-0.1,0.1-0.2,0.2c0.1-0.1,0.1-0.1,0.2-0.2
		C65.6,56,65.9,55.8,66.1,55.7z"
        />
        <path
          className="st1"
          d="M99,58.7c-0.1-0.1-0.1-0.1-0.1-0.2C98.8,58.6,98.9,58.6,99,58.7z"
        />
        <path
          className="st1"
          d="M71.1,53.1C71.1,53.1,71.1,53.1,71.1,53.1C71.1,53.1,71.1,53.1,71.1,53.1z"
        />
        <path
          className="st1"
          d="M66.1,55.7c0.2-0.1,0.5-0.2,0.7-0.2C66.6,55.5,66.4,55.5,66.1,55.7z"
        />
        <path
          className="st1"
          d="M96.3,49.4C96.3,49.4,96.3,49.4,96.3,49.4c0.2,0.2,0.3,0.4,0.3,0.7C96.5,49.8,96.5,49.6,96.3,49.4z"
        />
        <path
          className="st1"
          d="M97.7,50.4c0.1,0.2,0.2,0.3,0.4,0.3C97.9,50.7,97.7,50.6,97.7,50.4z"
        />
        <path
          className="st1"
          d="M96.6,50.3c-0.1,0.1-0.2,0.1-0.2,0.2C96.5,50.4,96.5,50.4,96.6,50.3L96.6,50.3z"
        />
        <path
          className="st1"
          d="M69.7,55.2c0-0.3,0-0.6,0-0.9C69.7,54.7,69.7,54.9,69.7,55.2c0.2,0,0.3,0,0.4,0C70,55.3,69.8,55.3,69.7,55.2z"
        />
        <path
          className="st1"
          d="M96.2,49.5L96.2,49.5L96.2,49.5C96.2,49.4,96.2,49.4,96.2,49.5C96.2,49.4,96.2,49.5,96.2,49.5
		C96.1,49.5,96.1,49.5,96.2,49.5C96.1,49.5,96.1,49.5,96.2,49.5z"
        />
        <path
          className="st1"
          d="M63.8,55.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c0.1,0,0.1,0,0.2,0
		c0.1,0,0.3,0,0.4,0C63.7,55.4,63.8,55.3,63.8,55.3z"
        />
        <path
          className="st1"
          d="M98,50.7c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0C98.2,50.7,98.1,50.7,98,50.7z"
        />
        <path
          className="st1"
          d="M75.1,54.4c0-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.3,0-0.4c0,0.1,0,0.3,0,0.4C75,54.1,75.1,54.2,75.1,54.4z"
        />
        <path
          className="st1"
          d="M89.9,49.3c0,0.1-0.1,0.1-0.2,0.2C89.8,49.4,89.9,49.3,89.9,49.3z"
        />
        <path
          className="st1"
          d="M97.3,50.3c0.1-0.1,0.1-0.2,0.2-0.2l0,0l0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C97.4,50.2,97.3,50.2,97.3,50.3z"
        />
        <path
          className="st1"
          d="M72.3,53.9c-0.2,0.3-0.6,0.3-1.1,0.3c0,0.1,0,0.1,0.1,0.2c0-0.1,0-0.1-0.1-0.2C71.6,54.2,72,54.1,72.3,53.9z"
        />
        <path
          className="st1"
          d="M101.1,55.6C101.1,55.6,101.1,55.6,101.1,55.6C101.1,55.6,101.1,55.6,101.1,55.6L101.1,55.6z"
        />
        <path
          className="st1"
          d="M102.3,58.6C102.3,58.6,102.3,58.7,102.3,58.6C102.3,58.7,102.3,58.6,102.3,58.6z"
        />
        <path
          className="st1"
          d="M71.4,54.8L71.4,54.8c0,0,0,0.1,0,0.1c-0.4,0.1-0.8,0.2-1.3,0.3c0.4,0,0.8-0.2,1.3-0.3
		C71.4,54.9,71.4,54.9,71.4,54.8z"
        />
        <path
          className="st1"
          d="M73.6,55.5c-0.1,0-0.1,0.1-0.2,0.1C73.4,55.5,73.5,55.5,73.6,55.5c0.1,0,0.3,0,0.4,0
		C73.9,55.4,73.7,55.4,73.6,55.5z"
        />
        <path
          className="st1"
          d="M74.9,55.5c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0,0,0,0C74.7,55.4,74.8,55.5,74.9,55.5z"
        />
        <path
          className="st1"
          d="M100.1,57.9c-0.1,0.2,0,0.4-0.1,0.6c0,0,0,0,0,0C100,58.3,100,58.1,100.1,57.9z"
        />
        <path
          className="st1"
          d="M99.8,53.5c-0.2-0.2-0.4-0.4-0.5-0.7C99.4,53.1,99.6,53.3,99.8,53.5c-0.3,0-0.7,0-1,0c0,0.1,0,0.3,0,0.4
		c0-0.1,0-0.3,0-0.4C99.1,53.5,99.5,53.5,99.8,53.5z"
        />
        <path
          className="st1"
          d="M73.6,57.7c0.1-0.1,0.2-0.2,0.2-0.4c0.1,0,0.3,0,0.4,0c-0.1,0-0.3,0-0.4,0C73.8,57.6,73.7,57.7,73.6,57.7z"
        />
        <path
          className="st1"
          d="M99.2,59c0-0.1,0-0.2,0-0.4c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.1-0.3,0.1-0.4,0.2C99.2,58.8,99.2,58.9,99.2,59z"
        />
        <path
          className="st1"
          d="M98.9,52.4c0.1,0,0.3,0,0.4-0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0-0.1,0-0.2,0.1-0.4C99.2,52.4,99.1,52.4,98.9,52.4z
		"
        />
        <path
          className="st1"
          d="M88.6,52.7c-0.2,0-0.5,0-0.6,0.1C88.1,52.6,88.4,52.7,88.6,52.7L88.6,52.7z"
        />
        <path
          className="st1"
          d="M59.1,48.7c-0.1,0.1-0.2,0.1-0.4,0.1C58.8,48.8,59,48.8,59.1,48.7z"
        />
        <path
          className="st1"
          d="M47.4,57.2c-0.1,0-0.2,0.1-0.2,0.1C47.3,57.3,47.3,57.3,47.4,57.2z"
        />
        <path
          className="st1"
          d="M69.7,54.4C69.7,54.4,69.7,54.4,69.7,54.4c-0.2,0-0.3,0-0.5-0.1C69.4,54.4,69.5,54.4,69.7,54.4
		C69.7,54.4,69.7,54.4,69.7,54.4z"
        />
        <path
          className="st1"
          d="M90.6,54.4c0-0.1,0-0.1,0-0.2c0.1-0.2,0.1-0.3,0.2-0.5c-0.1,0.2-0.1,0.3-0.2,0.5
		C90.6,54.3,90.6,54.3,90.6,54.4z"
        />
        <path
          className="st1"
          d="M90.6,53.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0C90.7,53.2,90.6,53.2,90.6,53.1z"
        />
        <path
          className="st1"
          d="M69.2,54.3c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0C69.1,54.2,69.1,54.2,69.2,54.3z"
        />
        <path
          className="st1"
          d="M39.9,56.5c0.1-0.1,0.1-0.2,0.1-0.4l0,0c0,0,0,0,0,0C40,56.2,39.9,56.4,39.9,56.5z"
        />
        <path
          className="st1"
          d="M93.8,57C93.8,57,93.8,57,93.8,57c-0.1,0.1-0.1,0.2-0.2,0.3C93.7,57.2,93.8,57.1,93.8,57z"
        />
        <polygon className="st1" points="89.7,53.8 89.7,53.7 89.7,53.8 	" />
        <path
          className="st1"
          d="M93.8,57c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0C93.8,56.8,93.8,56.9,93.8,57z"
        />
        <path
          className="st1"
          d="M89.4,53.6c0,0,0.1-0.1,0.1-0.1C89.5,53.5,89.5,53.6,89.4,53.6C89.4,53.6,89.4,53.6,89.4,53.6z"
        />
        <path
          className="st1"
          d="M47.4,57.2c0,0,0.1,0,0.1,0C47.5,57.2,47.5,57.2,47.4,57.2z"
        />
        <path
          className="st1"
          d="M56.8,58.7c0.1,0,0.2,0.1,0.3,0.1C57,58.8,56.9,58.7,56.8,58.7z"
        />
        <path
          className="st1"
          d="M85.6,51.4c0.1,0,0.1,0,0.2,0C85.7,51.4,85.7,51.4,85.6,51.4z"
        />
        <path
          className="st1"
          d="M61.5,55.3c0-0.1,0-0.3,0-0.4C61.5,55,61.5,55.1,61.5,55.3z"
        />
        <path
          className="st1"
          d="M64.8,50.9c0.4-0.1,0.7-0.3,1-0.5C65.5,50.7,65.2,50.8,64.8,50.9z"
        />
        <path
          className="st1"
          d="M86.3,53.8c0-0.2,0-0.4,0.2-0.7c0.2,0,0.3,0,0.5,0c-0.2,0-0.3,0-0.5,0C86.4,53.3,86.3,53.6,86.3,53.8z"
        />
        <path
          className="st1"
          d="M50.6,59.9c0,0-0.1,0.1-0.1,0.1C50.5,60,50.6,59.9,50.6,59.9C50.6,59.9,50.6,59.9,50.6,59.9z"
        />
        <path
          className="st1"
          d="M68.8,54.2L68.8,54.2c-0.1-0.2-0.1-0.4-0.2-0.6C68.7,53.7,68.8,53.9,68.8,54.2C68.8,54.2,68.8,54.2,68.8,54.2z
		"
        />
        <path
          className="st1"
          d="M89.3,53.1c-0.1-0.1-0.3-0.1-0.4-0.1l0,0C89,53.1,89.1,53.1,89.3,53.1z"
        />
        <path
          className="st1"
          d="M86.4,51.7c-0.1-0.1-0.2-0.1-0.3-0.1l0,0C86.2,51.6,86.3,51.6,86.4,51.7z"
        />
        <path
          className="st1"
          d="M37,48.8C37,48.8,37,48.8,37,48.8c0-0.1,0-0.3,0-0.4C37,48.5,37,48.6,37,48.8z"
        />
        <path
          className="st1"
          d="M43.8,51.2c-0.1,0-0.1-0.1-0.2-0.2C43.7,51,43.7,51.1,43.8,51.2z"
        />
        <path
          className="st1"
          d="M95.4,57.9c0.1,0.1,0.1,0.2,0.1,0.3C95.5,58.1,95.5,58,95.4,57.9z"
        />
        <path
          className="st1"
          d="M94.7,58.1c-0.1,0.1-0.2,0.2-0.3,0.3C94.4,58.3,94.5,58.2,94.7,58.1z"
        />
        <path
          className="st1"
          d="M44.3,50.9c-0.2,0.2-0.4,0.2-0.5,0.2C43.9,51.2,44.1,51.1,44.3,50.9L44.3,50.9z"
        />
        <path
          className="st1"
          d="M95.4,57.9c-0.1-0.1-0.2-0.1-0.3,0.1c-0.2,0-0.3,0-0.4,0.1c0.1-0.1,0.3-0.1,0.4-0.1
		C95.3,57.9,95.4,57.9,95.4,57.9z"
        />
        <path
          className="st1"
          d="M52.5,49c0.1-0.1,0.1-0.1,0.2-0.2C52.6,48.9,52.5,48.9,52.5,49L52.5,49z"
        />
        <path
          className="st1"
          d="M54.3,55.7c0,0,0.1,0,0.1,0C54.3,55.7,54.3,55.7,54.3,55.7z"
        />
        <path
          className="st1"
          d="M25.5,50.6c0,0-0.1,0.1-0.1,0.1C25.5,50.7,25.5,50.7,25.5,50.6C25.5,50.6,25.5,50.6,25.5,50.6z"
        />
        <path
          className="st1"
          d="M40,56.1c-0.2,0-0.4,0-0.6,0C39.3,56,39.2,56,39.1,56c0.1,0,0.1,0.1,0.2,0.1C39.6,56.1,39.8,56.1,40,56.1z"
        />
        <path
          className="st1"
          d="M54.2,49.4c-0.1-0.1-0.2-0.2-0.3-0.1C54,49.3,54.1,49.3,54.2,49.4c0.4,0,0.9,0,1.3,0c0-0.1,0-0.3,0-0.4
		c0,0.1,0,0.3,0,0.4C55.1,49.4,54.6,49.4,54.2,49.4z"
        />
        <path
          className="st1"
          d="M42.6,58.9L42.6,58.9c-0.1,0.1-0.2,0.3-0.2,0.4C42.4,59.1,42.4,59,42.6,58.9z"
        />
        <path
          className="st1"
          d="M42.4,58.4c0-0.1,0.1-0.1,0.2-0.1C42.4,58.3,42.4,58.3,42.4,58.4z"
        />
        <path
          className="st1"
          d="M64.1,48.5c0,0.1,0,0.2,0,0.2C64.2,48.7,64.1,48.6,64.1,48.5z"
        />
        <path
          className="st1"
          d="M95.1,58.3c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2-0.1c-0.1,0-0.1,0-0.2,0.1C95.4,58.2,95.2,58.3,95.1,58.3z"
        />
        <path
          className="st1"
          d="M94.5,57.3c0,0.1-0.1,0.2-0.1,0.3l0,0C94.5,57.5,94.5,57.4,94.5,57.3z"
        />
        <path
          className="st1"
          d="M94.4,57.6L94.4,57.6c0.1,0,0.1,0,0.2,0C94.6,57.6,94.5,57.6,94.4,57.6z"
        />
        <path
          className="st1"
          d="M96.2,57.8c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.3,0-0.4c0,0.1,0,0.3,0,0.4C96,57.5,96.1,57.7,96.2,57.8z"
        />
        <path
          className="st1"
          d="M68.8,51.8c-0.2-0.2-0.4-0.4-0.6-0.5C68.5,51.5,68.7,51.6,68.8,51.8L68.8,51.8z"
        />
        <path
          className="st1"
          d="M39.3,54c0,0-0.1,0-0.2-0.1c0-0.1,0-0.1,0-0.2c0,0.1,0,0.1,0,0.2C39.2,54,39.2,54,39.3,54z"
        />
        <path
          className="st1"
          d="M85.4,51.4c0-0.1,0.1-0.1,0.1-0.2C85.5,51.2,85.4,51.3,85.4,51.4L85.4,51.4z"
        />
        <path
          className="st1"
          d="M69.8,54c0.1-0.3,0.2-0.5,0.4-0.7C70,53.5,69.9,53.8,69.8,54z"
        />
        <path
          className="st1"
          d="M85,50.5c0.1,0,0.2,0,0.3,0C85.2,50.5,85.1,50.5,85,50.5z"
        />
        <path
          className="st1"
          d="M83.4,48.6c0.1,0,0.1-0.1,0.2-0.1C83.6,48.6,83.5,48.6,83.4,48.6z"
        />
        <path
          className="st1"
          d="M75.7,48.4c-0.1,0.1-0.2,0.1-0.3,0.1C75.5,48.5,75.6,48.5,75.7,48.4c0.3,0,0.4,0.2,0.4,0.3
		C76.1,48.5,76,48.4,75.7,48.4z"
        />
        <path
          className="st1"
          d="M85.6,49.7c0,0.1,0,0.2-0.1,0.2C85.6,49.8,85.6,49.7,85.6,49.7c0.2,0,0.4,0,0.6,0c0,0.5,0,0.8,0.2,0.9
		c-0.1-0.1-0.1-0.4-0.2-0.9C86.1,49.7,85.8,49.7,85.6,49.7z"
        />
        <path
          className="st1"
          d="M73.5,54.2c0,0,0.1,0,0.1,0C73.6,54.2,73.5,54.2,73.5,54.2z"
        />
        <path
          className="st1"
          d="M84.9,49.2c-0.2,0-0.3-0.1-0.4-0.2C84.6,49.1,84.7,49.1,84.9,49.2z"
        />
        <path
          className="st1"
          d="M81.1,48.6C81.1,48.6,81.1,48.6,81.1,48.6c0.1,0,0.3,0,0.3,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0-0.1c0,0,0,0,0,0
		C81.4,48.5,81.2,48.5,81.1,48.6c-0.1-0.1-0.1-0.1-0.2-0.1C81,48.5,81,48.5,81.1,48.6z"
        />
        <path
          className="st1"
          d="M84.9,49.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0C85.1,49.2,85,49.2,84.9,49.2z"
        />
        <path
          className="st1"
          d="M86.7,50.6c-0.1,0-0.1,0-0.2,0C86.6,50.6,86.7,50.6,86.7,50.6z"
        />
        <polygon className="st1" points="82.8,50.5 82.8,50.5 82.8,50.5 	" />
        <path
          className="st1"
          d="M71.9,50.8c0.1,0,0.2,0,0.3,0C72.1,50.9,72,50.8,71.9,50.8z"
        />
        <path
          className="st1"
          d="M71.8,50.8c0,0,0.1,0,0.2,0C71.9,50.8,71.8,50.8,71.8,50.8z"
        />
        <path
          className="st1"
          d="M69.9,48.4c0.1,0,0.1,0,0.2,0l0,0C70,48.3,70,48.4,69.9,48.4z"
        />
        <path
          className="st1"
          d="M71,49.9c0,0,0,0.1-0.1,0.1C71,50,71,49.9,71,49.9L71,49.9z"
        />
        <path
          className="st1"
          d="M84.9,50.5c0,0-0.1,0-0.1,0c0-0.3-0.1-0.5-0.3-0.7c0.2,0.2,0.3,0.4,0.3,0.7C84.8,50.5,84.9,50.5,84.9,50.5z"
        />
        <path
          className="st1"
          d="M81.5,50.9c0-0.3,0.3-0.6,0.4-0.9C81.9,50.3,81.5,50.6,81.5,50.9L81.5,50.9z"
        />
        <path
          className="st2"
          d="M44.5,46.6c-0.2,0.1-0.1,0.2,0,0.2h0.1C44.6,46.7,44.5,46.6,44.5,46.6z"
        />
        <path
          className="st2"
          d="M31.6,60.5c0.1,0.1,0.2,0.1,0.3,0C31.8,60.5,31.7,60.5,31.6,60.5C31.7,60.5,31.6,60.5,31.6,60.5z"
        />
        <path
          className="st2"
          d="M37.7,48c0,0-0.1,0-0.2,0c0,0,0,0,0,0C37.6,48,37.6,48,37.7,48z"
        />
        <path
          className="st2"
          d="M31.5,60.9c0.3,0,0.6,0,0.9-0.1c0-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.6,0c0,0-0.1,0-0.2,0
		c-0.2-0.1-0.4-0.1-0.6,0c0.1,0.1,0.1,0.2,0.1,0.3C31.2,60.9,31.3,60.9,31.5,60.9z"
        />
        <path
          className="st2"
          d="M36.1,49.9c-0.6,0.1-1,0.3-1.5,0.4c-0.1,0-0.3,0-0.4,0l0,0c0.3,0.2,0.4,0.4,0.4,0.6c0,0.3-0.3,0.6-0.4,0.9
		c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.1,0.3,0.2,0.5c0,0.2,0,0.3,0.1,0.5c0,0.2,0,0.3,0.1,0.5c0,0.3-0.3,0.3-0.5,0.3
		c0.1,0.5,1.2,1.2-0.3,1.5c-0.3,0.1,0.1,0.8,0.3,1.1c0.4,0.7,0.6,1.3,0.5,2.1c-0.3-1.1-1.1-2-1.5-3.1c-0.4,0.1-0.1,0.6-0.4,0.7
		c-0.5,0-0.4-0.7,0.2-2c-0.2,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.5-0.3C32.1,54.1,32,54,31.8,54c-0.2,0.2-0.5,0.3-0.8,0.4
		c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c0,0.3,0,0.6,0,0.9c0,0,0,0,0,0c0.4,0,0.8-0.8,1.2-0.2c0.2,0.4-0.3,0.8-0.8,1
		c0.1,0.4,0.7,0.2,0.8,0.7C31,57,31,57.5,31.1,58.1c0,0.2,0.1,0.4,0.1,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.3-0.8,0-1.1,0.1
		c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.4,0,0.6,0c-0.2,0-0.4,0-0.6,0c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.2,0
		c0,0,0,0,0.1,0c0.2,0,0.4,0,0.5,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.3,0,0.5,0,0.8,0h0.2c0.1-0.1,0.2-0.1,0.4-0.1
		c0-0.3-0.1-0.5-0.6-0.4c-0.3,0.1-0.7,0.2-1.1-0.2c0.7-0.6,1.6-0.6,2.3-0.2c0.5,0.3,0.2,0.5,0,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
		c0.1,0,0.2,0,0.9-0.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.5,0c0.3,0,0.5,0,0.8,0c-0.3,0-0.6-0.2-0.9-0.5c0.5,0,0.8-0.1,1.2-0.1
		c0.1-0.3-0.2-0.8,0.5-0.8c0.7,0.1,0.8-0.4,0.8-1c-0.2,0.1-0.3,0.5-0.6,0.3c-0.2-0.2-0.2-0.6-0.2-0.8c0-0.1,0.1-0.3,0.3-0.2
		c0.4,0.1,0.6,0.4,0.6,0.8c0.6,0,1,0.3,0.7,0.9c-0.4,0.8,0,0.9,0.6,1.1c0.4,0.1,0.8,0.2,1.1,0.2c0.2,0,0.4,0.1,0.5,0.2
		c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6,0h0.1c0,0,0.1,0,0.1,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.2,0-0.3,0s-0.1,0.1,0,0.1
		c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.3,0,0.4-0.1h0.2c0.3,0,0.5,0,0.7-0.1c0.1-0.1,0.1-0.3,0-0.6c-0.7,0.4-1.4-0.3-2.2-0.1
		c-0.3,0.1-0.6-0.5-0.2-0.9c0.6-0.6,0.3-0.9-0.3-0.9c-1.1,0-2-0.2-2.6-1.1c-0.2-0.2-0.6-0.2-0.9-0.3c-1-0.3-1.1-0.9-0.3-1.8
		c0,0,0,0,0,0c0,0,0,0,0,0c0-0.4,0-0.8,0-1.2c0-0.3-0.2-0.8,0.5-0.5c0.2,0.1,0.3-0.1,0.3-0.3c-0.1-0.3-0.2-0.7,0.3-0.8
		c0.5,0,0.7,0.4,1,0.7c0.2,0.2-0.5,0.6,0.1,0.7c0.6,0.1,0.3-0.6,0.6-0.7c0.5-0.9,1.1-1.8,0.9-2.9c0-0.2,0.2-0.3,0.4-0.2
		c0.4,0.1,0.8,0.3,1-0.3c0.2-0.5-0.7-0.2-0.6-0.7c0.2-0.2,0.4-0.3,0.7-0.3c-0.5,0-1.1,0-1.6,0c-0.1,0.1-0.2,0.1-0.3,0.1
		c0,0-0.1-0.1-0.1-0.1c-0.5-0.1-1,0-1.5,0c0,0,0,0,0,0.1c0,0.4-0.3,0.5-0.6,0.5c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1,0.3-0.1,0.6-0.4,0.8c-0.1-0.4-0.1-0.7,0.4-0.8c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.1-0.2
		c-0.6,0.1-1.1,0.1-1.7,0.1c0,0.1-0.1,0.2-0.1,0.3C34.9,49.1,35.3,49.7,36.1,49.9z M31.7,58.5c0.1-0.3,0.2-0.6,0.3-1.1
		C32.1,58,32.1,58,31.7,58.5z M35.4,52c-0.3,0-0.7,0.1-0.7-0.3c0.1-0.5,0.7-0.6,1.3-0.8C35.9,51.4,36.2,52,35.4,52z M36.4,50.6
		L36.4,50.6L36.4,50.6L36.4,50.6z"
        />
        <path
          className="st2"
          d="M49.1,46.7C49.1,46.7,49,46.8,49.1,46.7L49.1,46.7z"
        />
        <path
          className="st2"
          d="M42.5,48.5c0,0.3-0.1,0.5-0.3,0.6c-0.3,0.2-0.8-0.1-0.8,0.4c0,0.5,0.6,0.4,0.9,0.6c0.1,0,0.1,0,0.2,0
		c0.6-1.2,1.1-1.3,2.1-0.2c-0.8-0.4-1.3-0.2-1.6,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0,0.2,0.4,0.2,0.6c0,0.1,0.1,0.2,0.2,0.2
		c0,0,0,0,0,0c0.1,0,0.2,0,0.5-0.2c0-0.3,0.1-0.6,0.3-0.7c0.2-0.1,0.4-0.1,0.8,0c0.6,0.2,1.1,0.5,1.7,0.6c0.5,0.1,0.4,0.6,0.4,1
		c0.1,0,0.1,0,0.2,0c0.3-0.1,0.4-0.4,0.4-0.7c-0.1-0.7,0.8-1.9,1.5-2.1c0.2,0,0.4,0,0.6,0.1c0,0.2-0.2,0.2-0.3,0.3
		c-0.4,0.3-1,0.7-0.9,1.2c0,0.4,0.9-0.1,1.1,0.6c0-0.8-0.4-1.6,0.9-1.1c0.6,0.3,0.7-0.4,0.6-0.9c-0.1-0.4-0.1-0.8,0.3-0.9
		c0.1,0,0.1,0,0.2,0c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0s-0.2,0-0.3,0
		c-0.2,0-0.3,0-0.5,0h-0.1c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.4-0.1-0.6,0c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0h-0.1c-0.1,0-0.1,0-0.2,0
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.5,0
		c-0.4,0-0.8,0-1.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2,0c0,0,0,0-0.1,0c-0.2,0.1-0.6,0.1-0.8,0c-0.1,0-0.2,0-0.2,0
		c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0s-0.1,0-0.2,0c-0.1,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.5,0-1,0-1.5,0
		C42.4,48.2,42.5,48.3,42.5,48.5z M48.4,48.6c0.2,0.4,0,0.7-0.2,1C48,49.2,48.1,48.9,48.4,48.6z M47.6,48.5
		c-0.4,1.3-1.6,0.6-2.5,1.1C45.7,48.4,45.8,48.4,47.6,48.5z"
        />
        <path
          className="st2"
          d="M40.1,60.4c0.1,0,0.2,0,0.3,0C40.3,60.4,40.2,60.4,40.1,60.4C40.1,60.4,40.1,60.4,40.1,60.4z"
        />
        <path
          className="st2"
          d="M52.7,48.8c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0.2,0.1,0.4,0.2,0.6c0.3-0.1,0.6-0.1,0.9-0.2c0-0.1,0-0.1,0.1-0.2
		c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.1,0.4-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
		c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0h-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c0.1,0,0.3,0.1,0.4,0.1C53.5,48.8,53,48.4,52.7,48.8z"
        />
        <path
          className="st2"
          d="M41,46.7c-0.1,0.1,0.2,0.1,0.2,0.1C41.1,46.7,41,46.7,41,46.7z"
        />
        <path
          className="st2"
          d="M35.9,60.2C35.9,60.2,35.9,60.2,35.9,60.2c0.3,0,0.6,0,0.9,0c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.1-0.1-0.3-0.2-0.6-0.1C35.9,59.9,35.9,60,35.9,60.2z"
        />
        <path
          className="st2"
          d="M40.3,46.6C40.2,46.6,40.2,46.6,40.3,46.6c0,0.1,0.1,0.1,0.2,0.1C40.6,46.6,40.4,46.6,40.3,46.6z"
        />
        <path
          className="st2"
          d="M33.6,60.7c0.2,0,0.4,0,0.6,0c0.3,0,0.6,0,0.9,0h0.1h0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.7,0,0.9-0.1
		c-0.2,0-0.4,0-0.8,0c-0.6,0-1.1,0.1-1.7,0.1c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.1c0.1-0.1-0.1-0.1-0.1-0.1
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.3,0.1h-0.1h-0.1c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1C33.4,60.6,33.5,60.7,33.6,60.7z
		 M33.6,60.5c0.2,0.1-0.2,0.1-0.3,0.1C33.4,60.6,33.5,60.5,33.6,60.5z"
        />
        <path
          className="st2"
          d="M35.8,60.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0C35.9,60.2,35.8,60.2,35.8,60.2z"
        />
        <path
          className="st2"
          d="M35.9,48.1c0.3,0,0.5,0,0.8-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0-1.1-0.1c0,0.1,0,0.1-0.1,0.2
		C35.4,48.1,35.7,48.1,35.9,48.1z"
        />
        <path
          className="st2"
          d="M40.1,48c-0.2,0-0.3,0-0.5,0C39.8,48,40,48.1,40.1,48z"
        />
        <path
          className="st3"
          d="M23.4,47.7c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0C23.2,47.7,23.3,47.7,23.4,47.7C23.5,47.8,23.5,47.8,23.4,47.7z"
        />
        <path
          className="st3"
          d="M23.1,47.7c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0C23.3,47.8,23.2,47.8,23.1,47.7z"
        />
        <path
          className="st3"
          d="M22.6,48.1c-0.1,0-0.2,0.1-0.3,0.1C22.5,48.3,22.8,48.3,22.6,48.1z"
        />
        <path
          className="st3"
          d="M26.8,47.7C26.8,47.7,26.8,47.7,26.8,47.7C26.9,47.7,26.9,47.7,26.8,47.7c0.2,0,0.4,0,0.5-0.1
		c-0.1,0-0.2,0-0.3,0c-0.6,0-1.1,0-1.6,0c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.2,0,0.4,0,0.6,0
		C26.5,47.7,26.6,47.7,26.8,47.7z"
        />
        <path
          className="st3"
          d="M22,47.6h-0.2h-0.1c0,0,0,0-0.1,0c-0.2,0.1-0.6,0.1-0.8,0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4,0
		c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0.1,0,0.1
		c0.2,0,0.4,0,0.7,0h0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.3-0.1,0.4-0.1h0.1c0.2-0.1,0.1-0.1-0.1-0.1
		S22,48,21.9,47.8c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c-0.1,0-0.2-0.1-0.2-0.1C22.3,47.6,22.1,47.6,22,47.6z M20.7,48.2
		C20.7,48.2,20.8,48.2,20.7,48.2c0.1,0,0.2,0,0.3,0C20.9,48.2,20.8,48.3,20.7,48.2z M20.6,47.9c0.2,0,0.3-0.1,0.5-0.2
		c0,0,0.1,0.1,0.2,0.1C21.1,47.8,20.9,47.8,20.6,47.9z"
        />
        <path
          className="st3"
          d="M24.3,49.6c0,0-0.1,0-0.1,0C24.2,49.6,24.3,49.6,24.3,49.6C24.3,49.6,24.3,49.6,24.3,49.6z"
        />
        <path
          className="st3"
          d="M27.1,47.2c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0C27.3,47.2,27.2,47.2,27.1,47.2z"
        />
        <path
          className="st3"
          d="M27.2,47.3c0,0-0.2,0-0.3-0.1C26.9,47.4,27.1,47.3,27.2,47.3z"
        />
        <path
          className="st3"
          d="M24.2,47.8c0.1-0.1,0.1-0.1,0.2-0.1C24.3,47.7,24.2,47.7,24.2,47.8C24.1,47.8,24.1,47.8,24.2,47.8z"
        />
        <path
          className="st3"
          d="M24,49.5c0,0-0.1,0.1-0.2,0.1c0.1-0.1,0.3,0,0.5,0c0,0,0-0.1,0-0.1C24.2,49.5,24.1,49.5,24,49.5z"
        />
        <path
          className="st3"
          d="M25.4,47.5c-0.2,0-0.5,0-0.7,0C25,47.5,25.2,47.6,25.4,47.5z"
        />
        <path
          className="st3"
          d="M24.7,47.2c-0.1,0-0.3,0-0.5,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0.1,0.1,0.3,0.1,0.5,0.1
		C24.6,47.3,24.6,47.3,24.7,47.2z"
        />
        <path
          className="st3"
          d="M18.7,47.4c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0-1.3,0.1c0,0,0,0,0.1,0.1c0.4,0,0.9,0.1,1.3,0.1
		C18.5,47.5,18.6,47.5,18.7,47.4z"
        />
        <path
          className="st3"
          d="M25,47.2c0,0-0.1,0-0.2,0v0.1c0.3-0.1,0.5-0.1,0.7-0.1C25.3,47.2,25.2,47.2,25,47.2z"
        />
        <path
          className="st3"
          d="M21.1,47.4c0.1,0,0.1,0,0.2,0c0.5,0,1,0,1.4-0.1c0.2,0,0.3,0,0.4-0.1c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0
		C21.9,47.2,21.4,47.2,21.1,47.4C21,47.3,21,47.3,21,47.3c-0.2,0-0.4,0-0.6,0c-0.4,0-0.7,0-1.1,0.1c0,0,0.1,0.1,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0.1,0,0.3,0,0.4,0C20.4,47.5,20.8,47.5,21.1,47.4z"
        />
        <path
          className="st3"
          d="M23.9,47.5c0.2,0,0.5,0,0.8,0c-0.2,0-0.4-0.1-0.6-0.1C24,47.4,24,47.4,23.9,47.5z"
        />
        <path
          className="st3"
          d="M24.5,47.6c0.2,0.1,0.3,0.1,0.4,0c0,0-0.1,0-0.1,0C24.7,47.6,24.6,47.6,24.5,47.6z"
        />
        <path
          className="st3"
          d="M26.2,49.4c0,0-0.1,0-0.1,0.1C26.1,49.5,26.2,49.5,26.2,49.4c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0-0.1
		C26.5,49.4,26.4,49.4,26.2,49.4z"
        />
        <path
          className="st3"
          d="M19.6,47.3c0.2-0.1,0.3-0.1,0.5-0.1C19.9,47.2,19.7,47.2,19.6,47.3z"
        />
        <path
          className="st3"
          d="M33.1,49.4C33.1,49.4,33.1,49.4,33.1,49.4L33.1,49.4C33.1,49.4,33.1,49.4,33.1,49.4c0.2-0.1,0.3-0.1,0.4-0.2
		c0.5,0,0.7,0.2,0.6,0.6c0.3,0,0.4,0.2,0.4,0.4c0.5-0.1,0.9-0.2,1.5-0.4c-0.8-0.2-1.2-0.8-1-1.3c0-0.1,0.1-0.2,0.1-0.3
		c-0.1,0-0.1,0-0.2,0v-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.2,0c0-0.1,0-0.1,0.1-0.2c-0.5,0-1,0-1.4,0.2
		c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.4,0-1.1,0-1.5,0.1c-0.4,0.1-0.7,0-1,0.1c0.4,0.1,0.7,0.2,1,0c0,0,0,0,0.1,0c0.3,0,0.9,0,1.2,0
		c0.2,0,0.2,0,0.3,0.1H34c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0.1-0.7,0.1-1,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.5,0.1-0.7,0.1
		c0,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6,0c-0.8,0.1-1.5,0.1-2.2,0.1c-0.2,0-0.3,0-0.5,0c0,0,0,0.1-0.1,0.1
		c0.4,0,0.8,0,1.3,0c0.6,0,1.1,0.1,1.6,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.4,0,0.8,0,1.3,0c0.1,0,0.2,0,0.4,0
		c-0.6,0-1.1,0.1-1.7,0.1C33,49.1,33.1,49.2,33.1,49.4z"
        />
        <path
          className="st3"
          d="M28.4,49.1c-0.2,0-0.3,0-0.5,0c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.6-0.1
		C28.6,49.1,28.5,49.1,28.4,49.1z"
        />
        <path
          className="st3"
          d="M22.1,50C22.1,50,22.2,50,22.1,50c0.3,0,0.5-0.1,0.7-0.1c0.2,0,0.4,0,0.6,0c0,0.1,0.1,0.1-0.1,0.1
		c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0,0.5-0.1,0.7,0c-0.1,0-0.2,0-0.4,0c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0C23.1,50.1,22.7,50.1,22.1,50
		L22.1,50c0.3,0.3,0.4,0.4,0.7,0.5c0.3,0.1,0.5,0.3,0.2,0.6c0,0.1,0,0.1,0,0.2l0,0c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.6,0-0.9,0c0.1,0.2,0.2,0.4,0.5,0.8c-1.1-0.9-1.7,0-2.6,0.2c0.7,0.4,1.5-0.5,2,0.3
		c-0.5,0.1-1,0.2-1.5,0.3c-0.4,0-0.6,0.1-0.5,0.6c0.1,0.3-0.1,1,0.7,0.7v0l0,0c0.1-0.1,0.1-0.3,0.2-0.4c-0.2-0.1-0.5-0.3-0.2-0.5
		c0.3-0.3,0.6-0.1,0.7,0.3c0.4,0,0.8-0.2,0.7,0.6c0,0.3,0.1,0.9,0.8,0.5c0-0.6,0.1-1.1,0.2-1.6c0.4,0.1,0.4,0.5,0.4,0.8
		c0.4,0,0.6,0.3,0.9,0.4c0.5-0.3,0.8-0.1,1,0.4c0.1,0.2,0.2,0.5,0.5,0.5c0.4,0,0.3-0.3,0.3-0.6c0-0.4,0.2-0.5,0.6-0.4
		c1.6,0.4,1.6,0.4,2.7,1.7c-0.5,0.1-0.9,0.2-1.3,0.2c-0.4,0-0.9,0-0.4-0.6c0.2-0.2,0.2-0.4-0.1-0.4c-0.2,0-1.1,0.4-1.8,0.7
		c-0.4,0.2-0.8,0.4-0.8,0.5c0.1-0.1,0.3-0.1,0.4-0.2c0.4-0.1,0.9-0.2,1.6-0.2c-0.7,0.5-1.1,0.9-1.6,1.3c0,0,0,0,0,0
		c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1,0,0.1,0,0.2l0,0v0c0,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.3,0.1,0.5c0.1,0.3,0.4,0.5,0.9,0.5
		c0.1,0,0.3,0,0.4,0c0.5-0.6,0.9-1.3,1.3-2.3c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.4-0.2,0.8,0,1.2c0.3,0,0.6,0.2,0.9,0.3
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.2,0.2,0.3c-0.1,0.1-0.3,0.1-0.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0c0.1,0,0.3,0.1,0.4,0c0,0,0,0,0.1,0c0.1,0,0.4-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1
		c-0.1,0-0.1,0-0.1,0h-0.1c-0.3,0-0.5,0-0.7-0.1c0,0,0.1,0,0.2,0c0.3,0,0.6,0,0.9,0c0.1-0.1,0.2-0.1,0.2-0.2c0-0.2,0-0.4,0-0.6
		c0-1,0.8-0.5,1.3-0.6c0-0.2,0-0.4-0.1-0.6C31,57.5,31,57,31.9,56.8c-0.2-0.5-0.7-0.3-0.8-0.7c0.5-0.2,1-0.6,0.8-1
		c-0.3-0.6-0.7,0.2-1.2,0.2c-0.1,0.3,0.1,0.6-0.2,0.7c-0.2,0.1-0.3-0.1-0.3-0.3c0-0.4,0.3-0.4,0.6-0.4c0-0.3,0-0.6,0-0.9
		c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.2,0s-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.2,0.2
		c0.1,0,0.2,0.1,0.3,0.1s0.1,0.2,0.1,0.3c0.1,0,0.1,0,0.2,0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.2c0.2,0.1,0,0.2,0,0.3
		c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.3,0.5,0.3c0.1,0,0.2,0,0.4-0.1c-0.1-0.3,0.1-0.4,0.3-0.5
		s0.4-0.1,0.4-0.4c-0.4,0-0.7,0-1.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0-0.1-0.1-0.1c0.1,0,0.1,0,0.2,0c-0.1-0.1-0.1-0.3-0.2-0.4
		c-0.6-0.2-0.5-0.5-0.2-0.9c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3,0-0.4-0.2-0.4-0.4c0,0,0,0-0.1,0c0,0,0,0,0.1,0l0,0
		c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0-0.3,0.1-0.6,0.3-0.7s0.4-0.2,0.8-0.2c0-0.2,0-0.4,0-0.6c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0
		c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.5-0.2c0,0,0-0.1,0.1-0.2c-0.8,0.1-1.6,0.1-2.4,0.1c-0.2,0-0.4,0-0.5,0c-0.4,0.5-1,0.9,0.1,1.2
		c-0.1,0.3-0.4,0.3-0.5,0.4c-0.6,0.3-0.9,0.3-0.7-0.5c0.2-0.6,0-0.7-0.6-0.5c-0.4,0.2-0.8,0.4-1.1,0c-0.1,0-0.2,0-0.3,0
		c-0.4,0-0.8,0-1.2,0C25.2,50,25,50.1,24.8,50c-0.1,0-0.2-0.1-0.4-0.2c0,0,0,0,0,0c-0.3,0-0.5,0-0.8,0.1c-0.1-0.1-0.2-0.1-0.3-0.1
		c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0.1-0.2,0.1h-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.1
		c-0.1,0-0.2,0-0.3,0C22,49.9,22,49.9,22.1,50z M23.9,53.8c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.1,0.1,0.2,0.2
		C24,53.7,23.9,53.8,23.9,53.8z M29,55.6c0,0-0.1-0.2-0.1-0.2c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.1,0.1,0.2
		C29.2,55.6,29.1,55.6,29,55.6z M29.8,56.1C29.8,56.1,29.7,56.2,29.8,56.1c-0.1,0-0.2,0-0.2,0c0,0,0.1-0.1,0.1-0.1
		C29.7,56,29.7,56.1,29.8,56.1z M28.2,51.4L28.2,51.4L28.2,51.4L28.2,51.4z M27.2,53.3c0.4,0,0.8,0,1.2,0c-0.2-0.3-0.3-0.4-0.2-0.6
		c0.1-0.1,0.3-0.3,0.8-0.5c-0.3,0.4,0.4,0.9-0.4,1.1c0.3,0.3,0.7,0.7,1,1c0.1,0.1,0.1,0.3,0,0.5c-0.2,0.2-0.3,0-0.4-0.1
		c-0.2-0.2-0.3-0.4-0.5-0.6c-0.3-0.4-0.7-0.6-1-0.1c-0.1,0.2-0.3,0.4-0.5,0.2c-0.2-0.1-0.3-0.3-0.3-0.5C26.8,53.5,27,53.3,27.2,53.3
		z M25.5,50.6C25.5,50.6,25.5,50.6,25.5,50.6C25.5,50.6,25.5,50.6,25.5,50.6c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c0,0-0.1,0-0.1-0.1C25.5,50.7,25.5,50.7,25.5,50.6z M25.1,52.9c0.1,0,0.2,0.1,0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.2
		C24.9,53,25,52.9,25.1,52.9z M24.8,53.7c0.2,0,0.2,0.1,0.3,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.2-0.1-0.3-0.3
		C24.7,53.9,24.8,53.7,24.8,53.7z"
        />
        <path
          className="st3"
          d="M28.2,48.8c-0.2,0-0.4,0-0.7,0c0,0,0,0.1,0,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.2-0.1,0.2-0.1
		C29.1,48.8,28.6,48.8,28.2,48.8z"
        />
        <path
          className="st3"
          d="M15.2,47.9C15.1,47.9,15.1,47.9,15.2,47.9c-0.2,0-0.3,0-0.4,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0
		C15,48,15.1,47.9,15.2,47.9z"
        />
        <path
          className="st3"
          d="M62.2,58.9c0,0,0,0.1,0,0.2c0,0.3-0.1,0.5-0.4,0.3l0,0c0,0,0,0,0,0c-0.1-0.1-0.6-0.2-1.2-0.4
		c-0.6-0.1-1.1-0.2-1.3-0.2c-0.1,0-0.3,0.1-0.3,0c-1-0.9-0.7,0.1-0.9,0.5c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.2,0.2,0.4,0.4,0.6
		c1.5,0,3,0,4.5,0c-0.2-0.5-0.4-1-0.5-1.5C62.6,58.6,62.2,58.5,62.2,58.9z"
        />
        <path
          className="st3"
          d="M53.7,60.3c0,0.1,0,0.1,0,0.2c0.1,0,0.2-0.1,0.2-0.1C53.9,60.3,53.8,60.3,53.7,60.3z"
        />
        <path
          className="st3"
          d="M51.3,60.1c0.5,0,1.2,0,1.8,0c-0.2-0.1-0.3-0.2-0.5-0.3C52,59.4,51.5,59.5,51.3,60.1z"
        />
        <polygon className="st3" points="62.8,58.7 62.8,58.7 62.8,58.7 	" />
        <path
          className="st3"
          d="M55.5,59.9c-0.1,0.1,0,0.1,0,0.2c0.5,0,1,0,1.5,0C56.5,59.9,56.1,59.7,55.5,59.9z"
        />
        <path
          className="st3"
          d="M58.1,46.9C58.1,46.9,58,46.9,58.1,46.9C58,46.9,58,46.9,58.1,46.9C58,46.9,58,46.9,58.1,46.9
		C58,46.9,58,46.9,58.1,46.9z"
        />
        <path
          className="st3"
          d="M55,46.9C55,46.9,55,46.9,55,46.9c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0C55.3,46.8,55.1,46.9,55,46.9z"
        />
        <path
          className="st3"
          d="M54.6,46.9c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0C54.9,46.9,54.5,46.8,54.6,46.9z"
        />
        <path
          className="st3"
          d="M54.4,48.4c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0,0.5,0
		c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.5,0h-0.1c-0.1,0-0.2,0-0.3,0
		C54.4,48.3,54.4,48.3,54.4,48.4C54.4,48.4,54.4,48.4,54.4,48.4z"
        />
        <path
          className="st3"
          d="M47.1,53.9c0.7,0,1.2-0.7,1.9-0.6c0.8,0.1,1.7,0.1,2.2,1c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3,0l0,0h0
		c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.2,0,0.3,0
		c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0,0.4-0.2,0.4c-0.4,0.1-0.8,0.2-1.1,0.3c-0.3,0.2-0.5,0.4-0.7,0.7
		c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.2-0.1,0.2c0,0.3,0.2,0.5,0.4,0.6c0.3,0,0.6,0,0.6,0.4c0.5-0.2,1.2-0.2,1.1-1.1
		c-0.2-0.2-0.3-0.4,0-0.5c0.3-0.1,0.7,0,0.8,0.4c0.2,0.7,0.7,0.6,1.2,0.6c0.6,0,0.4-0.6,0.5-0.9c0.5-0.1,0.5,0.6,1,0.6
		c0.1-0.5-0.1-0.7-0.5-0.9c-0.4-0.2-0.5-0.5-0.5-0.9c0-1.1,0-1.1-1.1-1.1c0-0.1,0-0.1,0-0.2c0-0.6,1.2-0.3,0.7-0.9
		c-0.4-0.5-1-1-1.9-0.8c-0.1,0-0.3,0.1-0.5-0.1c1.1-0.1,1.6-0.6,1.6-1.6c-0.1,0-0.3,0-0.4,0c-0.1,0.4-0.6,0.5-0.5,0.1
		c0.2-0.9-0.2-1.6-0.6-2.3c-0.1-0.1-0.2-0.2-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0-0.4,0.3-0.6,0
		c-0.2-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.1-0.1,0.2-0.2c-0.3,0-0.4-0.3-0.6-0.4c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.3,0.5-0.3,0.9
		c0.1,0.5,0,1.2-0.6,0.9c-1.3-0.5-0.9,0.3-0.9,1.1c-0.2-0.7-1.1-0.2-1.1-0.6c0-0.4,0.5-0.9,0.9-1.2c0.1-0.1,0.3-0.1,0.3-0.3
		C50,49,49.8,49,49.6,49c-0.7,0.2-1.5,1.4-1.5,2.1c0,0.3-0.1,0.5-0.4,0.7C48.2,52.7,47.5,53.3,47.1,53.9z M55.5,56
		c0.5,0.6,0.5,1.1,0.2,1.8C55.4,57.2,55.5,56.7,55.5,56z M52.6,53.1c0.2,0,0.4-0.2,0.6,0c0,0.3-0.2,0.4-0.5,0.5
		c-0.3,0.1-0.7,0.4-0.8-0.1C51.8,53,52.4,53.1,52.6,53.1z"
        />
        <path
          className="st4"
          d="M38.2,59.8c-0.6-0.2-1-0.3-0.6-1.1c0.3-0.5-0.1-0.8-0.7-0.9l0,0c0,0.5-0.1,1-0.8,1c-0.7-0.1-0.5,0.4-0.5,0.8
		c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0,0.4,0,0.6,0.1c0.1,0.1,0.2,0.2,0.3,0.3c1.1,0,2.1,0,3.2,0c-0.1-0.1-0.3-0.2-0.5-0.2
		C39,59.9,38.6,59.9,38.2,59.8z"
        />
        <path
          className="st4"
          d="M40,60.4c0,0,0.1,0,0.1,0C40.1,60.4,40.1,60.4,40,60.4C40.1,60.4,40,60.4,40,60.4z"
        />
        <path
          className="st3"
          d="M41,49.2c-0.2,0.5-0.6,0.4-1,0.3c-0.2,0-0.4,0-0.4,0.2c0.2,1.1-0.4,2-0.9,2.9c0.3,0.1,0.4,0.4,0.4,0.6
		c0,0.1,0,0.2,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.1,0,0.3c-0.2,0.1-0.6,0-0.4,0.4
		c0,0.1,0.2,0.3,0.2,0.3c0.3-0.1,0.8-0.3,0.8-0.4c0.1-0.7,0.1-1.2,1-0.8c0.1-0.6,0.4-0.6,0.9-0.3c0-0.6,0.2-1.1,0.4-1.7
		c0.2-0.6,0.3-1.1,0.2-1.7c-0.3-0.2-0.9-0.1-0.9-0.6c0-0.4,0.5-0.2,0.8-0.4c0.2-0.1,0.4-0.3,0.3-0.6c0-0.2-0.1-0.3-0.3-0.3
		c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.5,0.1-0.7,0.3C40.3,49,41.2,48.7,41,49.2z M41.7,48.5
		c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0.1-0.1,0.1C41.7,48.6,41.6,48.6,41.7,48.5C41.6,48.5,41.7,48.5,41.7,48.5z M42.1,51.3
		c-0.7,0.3-1,0.8-1.6,0.7c0,0,0,0,0,0.1c0,0,0,0,0-0.1C40.6,51.3,41.1,51.1,42.1,51.3z"
        />
        <path
          className="st4"
          d="M42.4,59.3c0-0.1,0.1-0.3,0.2-0.4c-0.2-0.2-0.3-0.4-0.2-0.5c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.5,0
		c0.2,0,0.5-0.1,0.5-0.4c0-0.3-0.3-0.2-0.5-0.2c0,0-0.1,0.1-0.1,0.1c0,0,0-0.1-0.1-0.1c0.1,0,0.1,0,0.2,0c0-0.3,0-0.6-0.1-1.2
		c-0.5,1-1,1.5-1.8,1.8c-0.5-0.1,0.6-1.2-0.7-0.9c-0.2,0.1-0.1-0.3,0-0.5c0.2-0.5,0.1-0.7-0.4-0.6l0,0c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.2,0,0.4-0.1,0.5c-0.2,0.1-0.3-0.1-0.4-0.2c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1,0-0.3,0-0.4,0
		c0,0-0.1,0.1-0.1,0c-0.6-0.4-0.6-1.2-1.2-1.6l0,0c-0.5,0-0.3,0.6-0.7,0.7c-0.3-0.3-0.3-0.6-0.4-0.9c-0.8,0.9-0.7,1.5,0.3,1.8
		c0.3,0.1,0.7,0.1,0.9,0.3c0.7,0.9,1.5,1.2,2.6,1.1c0.6,0,0.9,0.3,0.3,0.9c-0.4,0.4-0.1,1,0.2,0.9c0.8-0.1,1.4,0.5,2.2,0.1
		C42.4,59.6,42.4,59.4,42.4,59.3z"
        />
        <path
          className="st5"
          d="M42.6,58.9c-0.1,0.1-0.2,0.3-0.2,0.4s0,0.3,0,0.5c0.1,0.3,0.1,0.5,0,0.6c0,0,0,0,0.1,0
		c0.1-0.1,0.3-0.1,0.4-0.1c0-0.2,0-0.4,0.2-0.7c0-0.1,0.3,0,0.4,0c0.2,0.2,0.2,0.4,0.2,0.6c0,0,0,0,0,0c0.2,0,0.4-0.1,0.6-0.1
		c-0.1-0.6-0.4-1.1-0.4-1.7C43.4,58.4,43,58.9,42.6,58.9z"
        />
        <path
          className="st5"
          d="M45,60.1c0.2,0,0.5,0,0.7,0C45.5,60,45.3,60,45,60.1z"
        />
        <path
          className="st6"
          d="M42,53.7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.7-0.2
		c0.2-0.2,0.5-0.1,0.7-0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.3,0,0.4,0,0.5-0.2c0,0,0.1-0.1,0.1-0.2
		c0.1-0.1,0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2l0,0c0.3-0.9-0.7-0.6-0.9-1.1c-0.3-1-0.9,0.1-1.3-0.2c-0.2,0.2-0.4,0.2-0.5,0.2
		c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.2c0-0.2,0-0.6-0.2-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0
		c-0.1,0-0.1,0-0.2,0c0.1,0.6-0.1,1.2-0.2,1.7c-0.2,0.6-0.4,1.1-0.4,1.7c0,0,0,0,0,0C41.8,53.6,41.9,53.7,42,53.7z M45,52.6
		c-0.8,0.2-1.5,0-2.3-0.1C43.9,51.5,44.2,51.5,45,52.6z"
        />
        <path
          className="st4"
          d="M37,54.8L37,54.8c0.8-0.1,1.5-0.5,2.2-0.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.4
		c0-0.2-0.1-0.4-0.4-0.6c-0.3,0.1,0,0.8-0.6,0.7c-0.6-0.1,0-0.5-0.1-0.7c-0.3-0.3-0.5-0.7-1-0.7c-0.5,0-0.4,0.4-0.3,0.8
		c0,0.2,0,0.4-0.3,0.3c-0.7-0.3-0.5,0.2-0.5,0.5c0,0.4,0,0.8,0,1.2C36.2,54.8,36.8,53.7,37,54.8z M36.5,53.8L36.5,53.8L36.5,53.8
		L36.5,53.8z"
        />
        <path
          className="st4"
          d="M32.9,56.4c0.3-0.1,0-0.6,0.4-0.7c0.4,1.1,1.2,2,1.5,3.1c0.2-0.8,0-1.5-0.5-2.1c-0.2-0.3-0.5-1.1-0.3-1.1
		c1.4-0.3,0.4-1,0.3-1.5c-0.1-0.4-0.3-0.4-0.6-0.4c0,0,0,0,0,0c0,0.2-0.2,0.3-0.4,0.4s-0.4,0.2-0.3,0.5
		C32.6,55.6,32.5,56.4,32.9,56.4z"
        />
        <path
          className="st5"
          d="M33.7,53.5c0.1-0.6,0.3-1.1,0.4-1.7v0c0.1-0.3,0.4-0.6,0.4-0.9c0-0.2-0.1-0.4-0.4-0.6l0,0l0,0l0,0c0,0,0,0,0,0
		l0,0c-0.3-0.1-0.6,0-0.9,0.1c-0.4,0.2-0.8,0.5-1.3,0.6c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0l0,0h0c0,0,0,0,0,0c0,0.3,0.2,0.4,0.4,0.4
		c0.7,0.1,1.2-0.4,1.8-0.6c-0.1,1.2-1.2,0.5-1.6,1c0,0.1,0,0.3,0,0.4c0.3,0.1,0.6,0.1,0.6,0.4c0,0.1-0.3,0.3-0.4,0.5
		c0.1,0.1,0.1,0.3,0.2,0.4l0,0C33,53.5,33.4,53.5,33.7,53.5C33.7,53.5,33.7,53.5,33.7,53.5z"
        />
        <path
          className="st3"
          d="M45.6,51.1c0.1,0.5,1.2,0.3,0.9,1.1c0.5,0.3,0.9,0.2,1.1-0.4c0-0.4,0.1-0.9-0.4-1c-0.6-0.1-1.1-0.4-1.7-0.6
		c-0.4-0.1-0.6-0.1-0.8,0c-0.2,0.1-0.3,0.3-0.3,0.7l0,0C44.7,51.3,45.3,50.1,45.6,51.1z"
        />
        <path
          className="st6"
          d="M46.4,52.2L46.4,52.2L46.4,52.2c0.1,0.6,0.2,1,0,1.2s-0.5,0.2-1,0.1c-0.1,0-0.2,0-0.2,0c0,0.3,0,0.6,0.4,0.6
		c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.5,0.3c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c0-0.1,0-0.1,0-0.2c-0.2-0.1-0.2-0.3,0-0.4
		c0.4-0.7,1.1-1.2,0.6-2.1c-0.1,0-0.1,0-0.2,0C47.4,52.4,47,52.5,46.4,52.2z"
        />
        <path
          className="st5"
          d="M30,60.2c-0.2,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0h0.1c0.2,0.1,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0
		c0.1,0,0.2,0,0.3,0c-0.1,0-0.1,0-0.2,0C30.2,60.4,30.1,60.3,30,60.2z"
        />
        <path
          className="st5"
          d="M30.7,60.8c0.1,0,0.2,0,0.2,0H30.7c0.3,0.1,0.4,0.1,0.5,0.1c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0
		c-0.3,0.1-0.4,0.1-0.6,0.2C30.5,60.8,30.6,60.8,30.7,60.8z"
        />
        <path
          className="st5"
          d="M29.9,60c-0.1,0.1-0.1,0.2-0.2,0.2c0.1,0,0.2,0,0.3,0C29.9,60.1,29.9,60.1,29.9,60z"
        />
        <path
          className="st5"
          d="M33.3,59.5c-0.6-0.4-1.5-0.4-2.3,0.2c0.4,0.4,0.7,0.3,1.1,0.2c0.4-0.1,0.5,0.1,0.6,0.4c0.1,0,0.2,0,0.2,0
		c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2C33.6,60,33.8,59.8,33.3,59.5z"
        />
        <path
          className="st3"
          d="M47.6,48.5c-1.8-0.2-1.9-0.1-2.5,1.1C46,49.1,47.2,49.8,47.6,48.5z"
        />
        <path
          className="st5"
          d="M36.7,48C36.7,48,36.7,48,36.7,48c0.2,0,0.3,0,0.4,0C36.9,48,36.8,48,36.7,48z"
        />
        <path
          className="st5"
          d="M37,48.8c0.4,0,0.6-0.1,0.6-0.5c0,0,0,0,0-0.1c-0.2,0-0.4,0-0.6,0c0,0-0.1,0-0.1,0c0,0.1,0.1,0.1,0.1,0.2
		C37,48.5,37,48.6,37,48.8z"
        />
        <path
          className="st5"
          d="M37.4,48C37.4,48,37.4,48,37.4,48c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0H37.4L37.4,48z"
        />
        <polygon className="st5" points="37,48.8 37,48.8 37,48.8 37,48.8 	" />
        <path
          className="st6"
          d="M34.2,51.8c-0.1,0.6-0.3,1.1-0.4,1.7c0.3,0,0.6,0,0.6,0.4c0.2,0,0.5,0,0.5-0.3c0-0.2,0-0.3-0.1-0.5
		c0-0.2,0-0.3-0.1-0.5c0-0.2-0.1-0.3-0.2-0.5C34.5,52,34.3,51.9,34.2,51.8z"
        />
        <path
          className="st4"
          d="M32.8,60.8c0.3,0,0.5-0.1,0.7-0.1c0,0,0,0,0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.1,0.2-0.2,0.2C32.5,60.8,32.7,60.8,32.8,60.8z"
        />
        <path
          className="st5"
          d="M34.8,51.8c-0.1,0.3,0.4,0.2,0.7,0.3c0.8,0,0.4-0.6,0.6-1C35.5,51.2,34.9,51.2,34.8,51.8z"
        />
        <path
          className="st3"
          d="M43,50.1C43,50.1,43,50.1,43,50.1C43,50.1,43,50.1,43,50.1c0.4-0.4,0.8-0.7,1.6-0.2c-1-1.1-1.5-0.9-2.1,0.2
		C42.7,50.1,42.9,50.1,43,50.1C43,50.1,43,50.1,43,50.1z"
        />
        <path
          className="st4"
          d="M53.9,49.3c0.1,0,0.2,0,0.3,0.1c0.4,0,0.9,0,1.3,0c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.3-0.3-0.4-0.4
		c-0.1,0-0.3,0-0.4,0h0c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0.1-0.4,0.1-0.5,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2
		C53.7,49.4,53.8,49.3,53.9,49.3z"
        />
        <path
          className="st5"
          d="M52.7,48.8c0.3-0.4,0.8,0,1.2-0.5c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-0.8,0
		c-0.1,0-0.3,0.1-0.4,0.2C52.3,48.5,52.4,48.8,52.7,48.8z"
        />
        <path
          className="st5"
          d="M31,59.2c0.1-0.1,0.1-0.3,0.2-0.5c-0.5,0.1-1.2-0.3-1.3,0.6C30.2,59.2,30.8,59.5,31,59.2z"
        />
        <path
          className="st4"
          d="M36.4,57.1c-0.2,0-0.3,0.1-0.3,0.2c0,0.3,0.1,0.7,0.2,0.8c0.2,0.2,0.4-0.2,0.6-0.3l0,0
		C37,57.4,36.8,57.1,36.4,57.1z"
        />
        <path
          className="st5"
          d="M35.9,60.2c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4,0-0.8,0.1-1.2,0.1c0.3,0.3,0.6,0.5,0.9,0.5
		c0.2,0,0.3,0,0.5,0C35.8,60.2,35.9,60.2,35.9,60.2L35.9,60.2z"
        />
        <path
          className="st4"
          d="M31,54.3c0.3-0.1,0.6-0.2,0.8-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.1,0-0.2,0h0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.3,0-0.4,0l0,0c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0l0,0C30.8,54.4,30.9,54.3,31,54.3z"
        />
        <path
          className="st3"
          d="M31.7,58.5c0.4-0.5,0.4-0.5,0.3-1.1C31.8,57.8,31.8,58.2,31.7,58.5z"
        />
        <path
          className="st6"
          d="M52.1,49.7c0.1,0.3,0.4,0,0.6,0c-0.1-0.2-0.1-0.4-0.2-0.6v0C52.2,49.2,52,49.3,52.1,49.7z"
        />
        <path
          className="st3"
          d="M48.4,48.6c-0.2,0.3-0.4,0.6-0.2,1C48.4,49.3,48.6,49,48.4,48.6z"
        />
        <path
          className="st5"
          d="M36.6,49.6c0.3-0.2,0.3-0.5,0.4-0.8C36.5,48.9,36.5,49.2,36.6,49.6z"
        />
        <polygon className="st5" points="37,48.8 37,48.8 37,48.8 	" />
        <path
          className="st4"
          d="M39.5,48.2c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0.1,0.1,0.1C39.3,48.3,39.3,48.2,39.5,48.2z"
        />
        <path
          className="st5"
          d="M34.2,50.3C34.2,50.3,34.2,50.3,34.2,50.3L34.2,50.3c0.1,0,0.3,0,0.4,0c0-0.3-0.2-0.4-0.4-0.4
		C34.2,50,34.2,50.1,34.2,50.3C34.2,50.3,34.2,50.3,34.2,50.3z"
        />
        <path className="st7" d="M43,50.1c0.1,0.1,0.3,0.1,0.4,0.2" />
        <line className="st7" x1="43" y1="50.1" x2="43" y2="50.1" />
        <polygon className="st5" points="36.4,50.6 36.3,50.6 36.4,50.7 	" />
        <path
          className="st5"
          d="M35.9,60.2C35.9,60.2,35.9,60.2,35.9,60.2C35.9,60.2,35.9,60.2,35.9,60.2C35.9,60.2,35.9,60.2,35.9,60.2
		L35.9,60.2z"
        />
        <path
          className="st8"
          d="M105.4,51.1c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.1c-0.4-0.1-0.2-0.5-0.2-0.7c-0.1,0-0.4,0-0.5,0.1
		c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.5s-0.1,0.2-0.2,0.2c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.5-0.1c0,0.2,0,0.4,0,0.6
		c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.5,0,0.8,0.1c-0.6,0.5-0.6,0.5-0.8-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.4,0-0.6-0.1
		c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0v0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0,0.5-0.2,0.6c-0.1,0.1-0.1,0.1-0.2,0l0,0
		c-0.3,0.2-0.2,0.7-0.7,0.8c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0l0,0c-0.4,0-0.7,0-1.1,0
		c-0.3,0-0.7,0-1,0c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0.1,0.2c0.4,0.3,0.7,0.5,0.5,1.2c0,0.1,0,0.2,0,0.3c0.1,0.3,0.4,0.6,0.7,0.7
		c0-0.2,0-0.4,0-0.6c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.2-0.3-0.3,0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.4,0.1,0.6,0.5
		c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0c0,0.1,0.1,0.2,0,0.3c0,0,0,0.1-0.1,0.1c0-0.1-0.1-0.3-0.1-0.4
		c-0.5,0-0.4,0.5-0.6,0.7c0,0.5-0.1,0.8-0.6,1c-0.2,0.1-0.3,0.1-0.5,0.2S99,57.8,99,58.1c0.2,0,0.3,0,0.4,0c0.1,0.1,0.2,0.2,0.2,0.4
		c0.1,0,0.2,0,0.3,0c0.1-0.2,0-0.4,0.1-0.6c0-0.1,0.1-0.1,0.2-0.2c0.3,0.3,0.4,0.6,0.4,1c0.4-0.1,0.6-0.4,0.8-0.8
		c0.2-0.4,0.4-0.8,1-0.5c0.1-0.5-0.3-0.9-0.4-1.3c0-0.2-0.1-0.4,0.2-0.5c0.2-0.1,0.3,0.1,0.4,0.3c0,0.2,0.2,0.4,0.4,0.5
		c0.4,0.2,0.3,0.6,0.3,0.9c-0.1,0.4-0.5,0.3-0.8,0.3c-0.1,0.4-0.2,0.6-0.2,0.8c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0.1
		c0.1,0.1,0.3,0.2,0.5,0.4c0.2,0.1,0.6-0.3,0.9-0.3c0.1,0,0.2,0.2,0.2,0.7c0,0.1,0,0.2,0,0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0-0.4,0.1-0.8,0.4-1.1c0.3,0.3,0.7,0.6,0.8,1.1c0.3,0,0.5,0,0.8,0c0.2-0.1,0.4-0.1,0.5-0.2c0,0-0.1,0-0.2,0
		c0.3,0,0.4-0.1,0.2-0.1c0-0.1-0.2-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.4-0.1-0.4-0.1-0.1-0.1c0,0,0,0-0.2,0
		c-0.1,0-0.1,0-0.1,0c0.4,0,0.4-0.1,0.3-0.1c-0.1,0-0.2,0-0.3,0c0.3-0.1,0.3-0.1,0.1-0.1h0c0.1-0.1,0.2-0.1,0.1-0.1h-0.1
		c-0.1-0.1-0.2-0.1-0.1-0.1c0.1,0,0.2,0,0.2,0c0.1-0.1,0.2-0.1,0-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
		c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.3-0.1-0.3-0.1-0.2-0.1h-0.1
		c0.4,0,0.5-0.1,0.3-0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0.3-0.1,0.3-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0
		c0.1,0,0.1,0,0.2,0s0.1,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3-0.1,0.4-0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.1-0.1,0.1-0.2
		c0.3-0.4,0.3-0.4-0.2-0.4s-1,0.1-1.5,0c-0.3,0-0.7,0-1,0c0.1-0.1,0.1-0.1,0.1-0.1h0.1c0-0.1,0-0.1,0-0.1c0.2-0.1,0.4-0.1,0.6-0.1
		c0-0.1-0.1-0.1-0.1-0.1h0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0.2,0.1,0.4,0,0.6,0c0.3,0,0.6,0,0.9-0.1h-0.1c0.2,0,0.5-0.1,0.7-0.1
		c0.1-0.1,0.2-0.1,0.1-0.1c0.1,0,0.1-0.1,0-0.1c-0.1-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.2,0c0.1-0.1,0.3,0,0.4-0.1c-0.2,0-0.4,0-0.5,0
		c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.5,0c0,0-0.1,0-0.2,0c0.2,0,0.4-0.1,0.7-0.1c-0.5,0-0.9,0.1-1.4,0.1c0.2-0.1,0.3-0.1,0.3-0.1
		c0.4,0,0.9-0.1,1.4-0.1c0.2,0,0.3-0.1,0.4-0.1c-0.3,0-0.6,0-0.9,0c0.1,0,0.3,0,0.4,0c-0.3,0-0.5,0-0.8,0c-0.1,0-0.3,0-0.4,0
		s-0.2,0-0.2,0s-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0.6,0,1.2-0.1,1.8-0.1c-0.3,0-0.6,0-0.9,0
		c-0.4,0-0.7,0-1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0.3-0.2,0.4-0.3,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1
		c-0.1-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.4,0c0.4-0.5,1-0.3,1.5-0.4c0-0.7-0.1-1.5,1-1.2c0.1-0.1,0.2-0.2,0.3-0.3h0.1h0.1
		c-0.2-0.1-0.5-0.2-0.7-0.3h0.1c-0.1-0.1-0.3,0-0.3-0.1c0.6,0,1.3,0.1,1.9,0.2c0.1-0.2,0-0.2-0.1-0.3c-0.2,0-0.4,0-0.5-0.1
		c-0.3,0-0.6-0.1-0.8-0.2c0.4,0,0.7,0,1.1,0c0.4,0,0.6-0.1,0.9-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3
		c-0.5-0.1-1-0.1-1.4,0c-0.1-0.1-0.4,0-0.5-0.1c-0.1,0,0.2-0.1,0-0.2c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.1-0.5-0.2-0.7
		c0.3,0.1,0.6,0.1,0.9,0.1c0,0,0,0,0.1,0c-0.1-0.2-0.1-0.5-0.2-0.7C105.9,50.9,105.6,50.9,105.4,51.1z M103.1,58.5L103.1,58.5
		L103.1,58.5L103.1,58.5z"
        />
        <path
          className="st8"
          d="M106.7,55.6c-0.5,0.1-1.1,0.1-1.6,0.2c0.2,0,0.3,0.1,0.4,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0c0.1-0.1,0.3,0,0.5,0
		c0.4,0,0.7-0.1,1.1-0.1c-0.2,0-0.5,0-0.7,0c0-0.1,0-0.1,0.1-0.1c0.1,0,0.2,0.1,0.3,0c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,0.9,0
		c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.3-0.1,0.4-0.1c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.3-0.1,0.5-0.1c0,0,0,0,0,0h-0.1c0,0,0.1,0,0.1,0
		c0,0,0-0.1,0-0.1c-0.4,0-0.8,0-1.2-0.1c-0.3,0-0.7,0-1,0C107.3,55.4,107,55.6,106.7,55.6z"
        />
        <rect x="102.4" y="53.1" className="st8" width="0" height="0" />
        <path
          className="st8"
          d="M107,54.7c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.1,1.1-0.1c0.2,0,0.3,0,0.5,0
		c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0h-0.1h-0.1h-0.1c-0.1,0-0.3,0-0.4,0h-0.1h-0.1h-0.1c0,0,0,0-0.1,0
		c0,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.7,0c0,0.1,0,0.1,0.1,0.2C106.8,54.7,106.9,54.7,107,54.7z"
        />
        <path
          className="st9"
          d="M78.9,47c0.2,0,0.5,0,0.8,0c0.2,0,0.5,0,0.7-0.1c0,0,0,0,0,0c-0.3,0-0.5,0-0.8,0c-0.2,0-0.3-0.1-0.5-0.1
		c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0c0,0,0,0,0,0C78.9,46.8,78.9,46.9,78.9,47z"
        />
        <path
          className="st9"
          d="M78.5,46.8C78.4,46.8,78.4,46.8,78.5,46.8c-0.2,0-0.2,0-0.3,0c0,0,0,0-0.1,0c0,0,0,0,0.1,0
		C78.3,46.8,78.4,46.8,78.5,46.8z"
        />
        <polygon className="st3" points="4.6,54.6 4.6,54.6 4.6,54.6 	" />
        <path
          className="st3"
          d="M3.2,55.9c0.2-0.3,0.4-0.5,0.4-0.8c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2C3,55.8,3,55.8,3.2,55.9z"
        />
        <path
          className="st3"
          d="M4.6,55.7c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.2-0.3-0.5-0.4-0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0
		c0,0.2,0.1,0.3,0.1,0.5C4.3,55.7,4.4,55.8,4.6,55.7z"
        />
        <polygon className="st3" points="5,55.4 5,55.4 5,55.4 	" />
        <path
          className="st3"
          d="M5.3,57.8C5.3,57.8,5.3,57.8,5.3,57.8c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c0.1,0,0.2,0,0.2-0.1
		C5.4,58.1,5.4,58,5.3,57.8z"
        />
        <polygon className="st10" points="109.6,55.3 109.6,55.3 109.6,55.3 	" />
        <path
          className="st10"
          d="M110.1,55.2C110.1,55.2,110,55.2,110.1,55.2c-0.2,0-0.3,0-0.4,0c0,0,0,0.1,0,0.1
		C109.8,55.3,109.9,55.2,110.1,55.2z"
        />
        <path
          className="st5"
          d="M19.8,59.3c-0.1,0-0.4,0-0.5-0.1c0,0,0-0.1,0-0.1c0.1-0.3,0.3-0.1,0.5,0c0.6-0.1,1.3-0.1,1.9-0.2
		c0-0.1,0-0.3,0-0.4c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.1,0.3,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.2-0.1
		c0.2-0.1,0.2-0.3,0.2-0.5c-0.1,0-0.3-0.1-0.3-0.2c0,0,0-0.1,0-0.1C22.5,57,22.8,57,23,57c0.1-0.1,0.1-0.1,0.2-0.2
		c0-0.1,0-0.3,0-0.4c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0-0.4-0.1-0.6c-0.1,0-0.3,0-0.4,0
		c-0.6,0.3-0.8-0.2-0.8-0.5c0.1-0.7-0.4-0.5-0.7-0.6c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.2,0.4l0,0
		c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0,0,0,0c0.1,0.1,0.3,0.1,0.5,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1-0.1,0.2
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.3,0.1,0.9-0.2,1,0.3
		c0.1,0.4-0.5,0.5-0.8,0.6c-0.2-0.1-0.4,0.3-0.6,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.2c-0.1,0-0.1,0-0.2,0
		c-0.2,0.1-0.4,0.1-0.8,0.2c0.3-0.4,0.3-0.8,0.7-0.9c0-0.2,0-0.4,0.1-0.5c0.6-0.5,0.1-0.7-0.3-0.8c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2-0.1c0-0.1,0.1-0.2,0.2-0.3c0-0.2-0.2-0.4-0.4-0.3c-1.2,0.6-1.8-0.2-2.4-1.1
		c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.2-0.5c-0.3,0-0.5,0-0.6,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.4,0.5
		c-0.1,0.2-0.3,0.4-0.4,0.4c-0.2,0.1-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.3,0,0.4c0,0.2-0.1,0.4-0.3,0.5c0,0,0,0.1,0,0.1C11,57,11,57,11,57c0,0,0,0,0,0.1c0,0,0,0-0.1,0.1
		c0.1,0.3,0.3,0.6,0.4,0.9c0,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.2,0.3,0.3c0.3,0,0.6,0,0.9,0c0.1-0.1,0.2-0.3,0.4-0.4c0-0.1,0-0.1,0-0.2
		c0-0.2,0-0.4,0-0.5c0-0.2,0.1-0.3,0.4-0.4c0.1,0,0.2-0.1,0.2-0.1c-0.1,0-0.1,0.1-0.2,0.1l0,0c0.1,0.3,0.1,0.6,0.2,0.8
		c0.1,0,0.2,0.1,0.3,0.2c0.2,0.2,0.4,0.5,0.8,0.5c0.1,0,0.2,0,0.2,0c0.3-0.1,0.8-0.5,0.8,0c0,0.1,0,0.2-0.1,0.3
		c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0c0,0.2-0.1,0.4-0.3,0.4c-0.1,0-0.1,0-0.2,0c-0.3,0-0.3-0.2-0.3-0.5
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.1,0.3-0.3,0.2c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.4-0.6,0.1-0.7-0.2c0,0,0-0.1,0-0.1
		c-0.2,0-0.4,0-0.6,0c0,0-0.1,0-0.1-0.1c0,0.2,0,0.3-0.1,0.6c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.2,0.2
		c0.1,0.1,0.1,0.3,0,0.6c0,0,0,0.1,0,0.1c0.4,0,0.9,0,1.3,0c1.2-0.1,2.3-0.1,3.5-0.1c0.1,0,0.1,0,0.1,0h0.1h0.1c0.4,0,0.7,0,1.1,0
		c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0.6,0,1.2,0,1.9,0c0,0,0,0,0,0
		c-0.3-0.1-0.6-0.1-0.9-0.3C20,59.6,19.9,59.5,19.8,59.3z M13.1,55.5C13.1,55.5,13.1,55.5,13.1,55.5c0,0.2-0.1,0.4-0.1,0.6
		c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.3-0.5,0.6-1.1,0.7C12,56.5,11.7,55.4,13.1,55.5C13.1,55.5,13.1,55.5,13.1,55.5L13.1,55.5
		C13.1,55.5,13.1,55.4,13.1,55.5C13.1,55.4,13.1,55.4,13.1,55.5C13.1,55.4,13.1,55.4,13.1,55.5z M14.6,56.4
		c-0.2,0.1-0.7,0.2-0.7-0.1c-0.1-0.7,0.5-1,1-1.6C15,55.5,15.2,56.1,14.6,56.4z M15,57.2c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.2,0-0.2
		c0.1-0.1,0.2,0,0.2,0.1C15.1,57.1,15,57.2,15,57.2z M14.5,59.9c0,0-0.1,0.1-0.1,0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0.1-0.1,0.1-0.1
		C14.4,59.9,14.4,59.9,14.5,59.9C14.5,59.9,14.5,59.9,14.5,59.9C14.5,59.9,14.5,59.9,14.5,59.9z M16.6,59c-0.1,0-0.2-0.1-0.2-0.1
		c0-0.5,0.5-0.5,0.9-0.8C17.4,58.8,17,58.9,16.6,59z M17.8,57c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0.1,0.1,0.2,0.1C18.1,57,18,57.1,18,57.1
		C17.9,57.1,17.8,57.1,17.8,57z M18.3,59c-0.3,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.2,0.3-0.2c0.2,0,0.4,0.1,0.3,0.3
		C18.5,58.9,18.4,59,18.3,59z"
        />
        <polygon className="st5" points="23.2,56.7 23.2,56.7 23.2,56.7 	" />
        <path
          className="st11"
          d="M30.2,47.3c0,0,0.1,0,0.2,0C30.3,47.3,30.3,47.3,30.2,47.3c-0.1-0.1-0.2,0-0.3-0.1
		C29.9,47.3,30,47.3,30.2,47.3z"
        />
        <path
          className="st11"
          d="M28.4,47.2c0.5,0,1,0,1.5,0C29.7,47.1,28.9,47.2,28.4,47.2z"
        />
        <path
          className="st11"
          d="M30.5,46.9C30.4,47,30.5,47,30.5,46.9L30.5,46.9z"
        />
        <path
          className="st11"
          d="M27.5,47.2c0.3,0,0.6,0,0.9,0C28.1,47.2,27.8,47.2,27.5,47.2C27.5,47.2,27.5,47.2,27.5,47.2z"
        />
        <path
          className="st11"
          d="M30.3,47.2c0.2,0.1,0.4,0.1,0.5,0c0,0-0.1,0,0,0C30.6,47.2,30.5,47.2,30.3,47.2z"
        />
        <path
          className="st12"
          d="M15.7,51.6c0.6,0.1,1.2,0.1,1.8,0.2c0.5,0.1,1.3,1.5,1.1,1.9c-0.1,0.2-0.5,0.3-0.5,0.2
		c-0.2-0.9-0.8-0.4-1.2-0.3c-0.3,0-0.6-0.1-0.7-0.4c-0.1,0-0.2,0-0.3,0c-0.1,0.3,0,0.4,0.2,0.5c0.1,0.1,0.2,0.1,0.3,0.2
		c0.6,0.9,1.2,1.6,2.4,1.1c0.3-0.1,0.4,0.1,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.1,0,0.2,0c0.2-0.4,0.6-0.4,0.9-0.6c0,0,0,0,0,0
		c-0.1-0.1-0.2-0.1-0.2-0.2c-0.8,0.2-0.6-0.4-0.7-0.7c-0.1-0.4,0.1-0.5,0.5-0.6c0.5-0.1,1-0.2,1.5-0.3c-0.5-0.9-1.2,0-2-0.3
		c0.9-0.2,1.5-1.1,2.6-0.2c-0.3-0.5-0.4-0.7-0.5-0.8c-0.4-0.3-0.9-0.1-1.4-0.4c0.2-0.3,0.9,0,0.8-0.7c-0.1-0.3-0.1-0.6-0.4-0.4
		c-0.6,0.5-1.2,0.1-1.7,0.2c-0.7,0.2-0.8,0.1-0.7-0.5c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.1,0-0.2,0
		c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0,0.3,0,0.4,0c0.3,0,0.5,0,0.8,0c-0.1,0.1-0.2,0.1-0.1,0.2
		c-0.1,0-0.1,0-0.2,0h-0.1h-0.1c-0.2,0-0.3,0-0.5,0h-0.1c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.1-0.2,0.3-0.3,0.4C15.7,51.4,15.7,51.5,15.7,51.6z M20.2,51.9c-0.4,0.3-0.4,0.3-1.7,0.2C19.2,51.6,19.2,51.6,20.2,51.9z"
        />
        <path
          className="st11"
          d="M23.9,59.4c-0.1-0.2-0.1-0.4,0-0.7c0-0.2,0-0.3,0-0.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0-0.1,0
		c0,0,0,0,0.1,0c0,0,0,0,0,0c0.3,0,0.6,0,0.9,0v0c0-0.1,0-0.1,0-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c-0.4-0.2-0.7-0.4-1.1-0.6
		l0,0c-0.2-0.1-0.4-0.1-0.6-0.2l0,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.2,0,0.5-0.2,0.7c0,0,0,0,0,0c0,0.2,0,0.4-0.2,0.5
		c-0.1,0-0.1,0.1-0.2,0.1c0,0.5,0.5,0.7,0.6,1.2c-0.6,0.1-0.8-0.6-1.2-0.5c0,0.3,0,0.6,0,0.8c0.1,0.2,0.3,0.2,0.6,0.3
		c0.6,0,1.3,0,1.9,0.1c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.2-0.2S23.9,59.5,23.9,59.4z"
        />
        <path
          className="st13"
          d="M19.3,47.3c-0.2,0-0.5,0-0.7,0c-0.1,0.1-0.1,0.1-0.2,0.2c0.4,0,0.7,0,1.1-0.1C19.5,47.5,19.5,47.5,19.3,47.3
		C19.4,47.4,19.4,47.4,19.3,47.3z"
        />
        <polygon className="st13" points="19.8,48.1 19.8,48.1 19.8,48.1 	" />
        <path
          className="st13"
          d="M17.2,49.1C17.2,49.1,17.2,49.1,17.2,49.1C17.3,49,17.3,49,17.2,49.1z"
        />
        <path
          className="st13"
          d="M19.5,48.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c-0.6,0-1.1,0.1-1.7,0.1
		h-0.1c-0.3,0-0.5,0-0.7-0.1c0.2,0,0.3,0,0.5,0c0.4,0,0.7,0,1.1,0c0.2,0,0.4,0,0.7,0c0.1,0,0.1,0,0.2,0c0-0.1-0.1-0.2-0.2-0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.4-0.1-0.5-0.1c-0.1,0-0.2,0-0.2,0.1c0,0,0,0-0.1,0
		c-0.3,0.1-0.9,0.1-1.3,0.1c0.1,0,0.2,0,0.2,0c0,0-0.2,0-0.2,0.1c0,0,0,0-0.1,0s-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
		c0-0.1,0.1,0,0.3-0.1c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.2,0-0.2,0h-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0
		c-0.1,0-0.1,0-0.2-0.1c0,0.1,0,0.2,0,0.3c0.1,0.1,0.1,0.1,0,0.1c0,0,0,0.1,0,0.1c0.2,0,0.4,0,0.6,0c0,0,0.1,0.1,0.2,0.1
		c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0.1,0.4,0c0.2-0.3,0.5-0.4,0.9-0.4C19,48,19.3,48.1,19.5,48.1C19.5,48.1,19.5,48.1,19.5,48.1z"
        />
        <path
          className="st13"
          d="M17.4,49.2L17.4,49.2c0.1,0,0.1-0.1,0.1-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0.1-0.1,0.1c0,0,0.1,0,0,0
		C17.3,49.2,17.3,49.2,17.4,49.2z"
        />
        <path
          className="st13"
          d="M16.3,47.5c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.3,0c0,0,0,0-0.1-0.1C16.8,47.5,16.6,47.5,16.3,47.5z"
        />
        <path
          className="st5"
          d="M23.8,57c0.2,0,0.5,0,0.6-0.2c0.1-0.1,0.4-0.3,0.8-0.5c0.7-0.4,1.6-0.8,1.8-0.7c0.3,0.1,0.3,0.2,0.1,0.4
		c-0.6,0.6,0,0.6,0.4,0.6c0.4,0,0.9-0.1,1.3-0.2c-1.1-1.3-1.1-1.3-2.7-1.7c-0.4-0.1-0.6,0.1-0.6,0.4c0,0.2,0.1,0.5-0.3,0.6
		c-0.3,0-0.4-0.2-0.5-0.5c-0.2-0.4-0.5-0.7-1-0.4C23.8,55.5,23.8,56.2,23.8,57C23.8,57,23.8,57,23.8,57z"
        />
        <path
          className="st11"
          d="M18.8,49.2C18.8,49.2,18.8,49.2,18.8,49.2c-0.1,0-0.1,0-0.1,0C18.8,49.2,18.8,49.2,18.8,49.2L18.8,49.2z"
        />
        <path
          className="st11"
          d="M19,50.6c0.6-0.1,1.2,0.3,1.7-0.2c0.2-0.2,0.3,0.1,0.4,0.4c0.1,0.7-0.6,0.4-0.8,0.7c0.5,0.2,1,0.1,1.4,0.4
		c0.3,0,0.6,0,0.9,0c-0.1-0.1-0.2-0.3-0.2-0.3c0.1-0.2,0.4-0.1,0.6-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c-0.7-0.1-1.1-0.5-1.5-1.1
		c0,0-0.1,0-0.1,0h-0.1c-0.1,0-0.2,0-0.3,0c0.2,0,0.3,0,0.5,0c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0-0.1,0c0,0-0.1,0-0.2,0
		c-0.2-0.1-0.4-0.1-0.6,0c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1-0.1,0.1
		c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0h-0.1h-0.1h-0.1h-0.1c-0.1,0-0.2,0-0.3,0s-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0
		C18.2,50.6,18.3,50.7,19,50.6z"
        />
        <path
          className="st11"
          d="M18.7,49.2c-0.3,0-0.4,0-0.7-0.1c0,0,0,0,0,0.1C18.2,49.2,18.4,49.2,18.7,49.2C18.6,49.2,18.7,49.2,18.7,49.2
		z"
        />
        <path
          className="st5"
          d="M26,60.6c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1c-0.1,0-0.1,0-0.2,0C26.1,60.5,26.1,60.5,26,60.6z"
        />
        <path
          className="st5"
          d="M27.6,60.2C27.6,60.2,27.7,60.2,27.6,60.2c0.3,0,0.4,0,0.6,0c0,0,0,0,0,0C28,60.1,27.8,60.1,27.6,60.2z"
        />
        <path
          className="st5"
          d="M28,60.3C28,60.3,28,60.3,28,60.3c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.4,0.1
		c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.4,0,0.5,0c0-0.1,0-0.2-0.1-0.3C28.1,60.3,28,60.3,28,60.3z"
        />
        <path
          className="st12"
          d="M18.9,49.1C18.9,49.1,18.9,49.1,18.9,49.1C18.8,49.2,18.8,49.2,18.9,49.1L18.9,49.1z"
        />
        <path
          className="st12"
          d="M20,50c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.2-0.2C20.3,49.9,20.2,49.9,20,50z"
        />
        <path
          className="st12"
          d="M19.5,48.1C19.5,48.1,19.5,48.1,19.5,48.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0.2,0,0.2,0,0.4,0c0,0,0,0,0,0
		c0,0,0-0.1-0.1-0.1v0C19.7,48.1,19.6,48.1,19.5,48.1z"
        />
        <path
          className="st13"
          d="M29.6,48.8c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.3,0,0.4,0c0,0,0-0.1,0.1-0.1C29.7,48.8,29.6,48.8,29.6,48.8z"
        />
        <path
          className="st13"
          d="M27.9,49.7c0.6-0.2,0.7-0.1,0.6,0.5c-0.2,0.8,0.1,0.8,0.7,0.5c0.2-0.1,0.4-0.1,0.5-0.4
		c-1.2-0.3-0.6-0.7-0.1-1.2c-0.2,0-0.4,0-0.7,0h-0.1c0,0,0,0,0,0c-0.2,0.1-0.4,0.1-0.6,0.1c0.1,0.1,0.3,0.1,0.3,0.1
		c-0.1,0-0.3,0-0.4,0c-0.3,0.1-0.4,0.1-0.6,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0.1
		c0.4,0,0.8,0,1.1,0c-0.3,0.1-0.7,0.1-1,0.2C27.1,50.1,27.5,49.8,27.9,49.7z"
        />
        <path
          className="st13"
          d="M23.2,56.3L23.2,56.3c0,0.1,0,0.3,0,0.4l0,0c0.2,0.1,0.4,0.1,0.6,0.2c0-0.7,0-1.4,0-2.1
		c-0.3-0.2-0.5-0.5-0.9-0.4c0-0.3,0-0.6-0.4-0.8c-0.2,0.5-0.3,1.1-0.2,1.6c0.1,0,0.3,0,0.4,0C23.5,55.2,23.6,55.3,23.2,56.3z
		 M23,54.4L23,54.4L23,54.4L23,54.4z"
        />
        <path
          className="st11"
          d="M27.1,48.8c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0h-0.1H26c-0.1,0-0.1,0.1-0.2,0.1c0.4,0,0.9,0,1.4,0
		c0.1,0,0.2,0,0.3,0c0,0,0-0.1,0-0.1C27.4,48.8,27.2,48.8,27.1,48.8z"
        />
        <path
          className="st11"
          d="M25.3,49.5C25.3,49.5,25.3,49.5,25.3,49.5c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0.1-0.1
		C25.7,49.4,25.5,49.4,25.3,49.5z"
        />
        <path
          className="st11"
          d="M26.2,49.4C26.2,49.4,26.2,49.4,26.2,49.4c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0
		C26.8,49.4,26.5,49.4,26.2,49.4z"
        />
        <polygon className="st8" points="14.8,47.9 14.8,47.9 14.8,47.9 	" />
        <path
          className="st5"
          d="M27.5,54.1c0.4-0.5,0.7-0.3,1,0.1c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.3,0.3,0.4,0.1c0.2-0.1,0.2-0.3,0-0.5
		c-0.3-0.3-0.6-0.7-1-1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0-1.2,0c-0.2,0-0.4,0.1-0.5,0.4c0,0.2,0.1,0.4,0.3,0.5
		C27.2,54.4,27.4,54.2,27.5,54.1z"
        />
        <path
          className="st10"
          d="M15.2,48.3c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1C15.5,48.2,15.3,48.2,15.2,48.3z"
        />
        <path
          className="st10"
          d="M16,48.2c-0.1,0-0.4,0.1-0.5,0.1c0.2,0,0.4,0,0.6,0C16.1,48.3,16.1,48.2,16,48.2C16.1,48.2,16,48.2,16,48.2z"
        />
        <path
          className="st10"
          d="M15.5,47.9c-0.1,0-0.2,0-0.3,0c0,0-0.1,0.1-0.2,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.6,0,0.7,0
		c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3C15.9,47.8,15.6,47.8,15.5,47.9z"
        />
        <path
          className="st2"
          d="M26.7,60c0-0.1,0-0.2,0-0.3c-0.2,0-0.5,0-0.7-0.2c-0.1,0-0.3,0-0.4,0c-0.3,0.6-0.6,0.3-1,0.2
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0l0,0h0c0,0.1-0.1,0.2-0.1,0.3c0.5,0,1,0,1.5,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1
		c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.1,0.2-0.1C26.4,60.1,26.6,60.1,26.7,60z"
        />
        <path
          className="st13"
          d="M23.8,58c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0,0.5,0,0.7c0,0.1,0.1,0.1,0.1,0.2s0.1,0.1,0.2,0.2l0,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.4,0.1,0.7,0.4,1-0.2c-0.6,0-0.8-0.2-0.9-0.5c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0.1-0.6
		C24.4,58,24.1,58,23.8,58z"
        />
        <polygon className="st13" points="23.8,58 23.8,58 23.8,58 	" />
        <path
          className="st2"
          d="M33.3,50.4c0.3-0.1,0.5-0.2,0.9-0.1l0,0c0-0.1,0-0.3,0-0.4c0-0.4-0.2-0.7-0.6-0.6c-0.1,0.3-0.2,0.6-0.4,0.9
		c-0.3,0-0.6,0-0.8,0.2c-0.2,0.1-0.3,0.4-0.3,0.7l0,0C32.5,50.9,32.9,50.6,33.3,50.4z"
        />
        <path
          className="st13"
          d="M23.8,57c0.4,0.2,0.7,0.4,1.1,0.6h0c0.5-0.4,0.9-0.7,1.6-1.3c-0.6,0-1.1,0.1-1.6,0.2c-0.1,0-0.3,0.1-0.4,0.2
		l0,0C24.3,57,24.1,57,23.8,57z"
        />
        <path
          className="st12"
          d="M26.7,59.7c0.2-0.1,0.4-0.3,0.7-0.4c-0.2-0.4-0.1-0.7,0-1.2c0-0.3,0.1-0.6,0-0.9c-0.3,1-0.7,1.7-1.3,2.3
		C26.2,59.7,26.4,59.7,26.7,59.7z"
        />
        <path
          className="st13"
          d="M22.1,50C22,49.9,22,49.9,22,49.8c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.5,0c0.1,0.1,0.1,0.2,0.2,0.3
		C21.7,50,21.9,50,22.1,50z"
        />
        <path
          className="st13"
          d="M22.8,50.5c-0.2-0.1-0.4-0.3-0.6-0.5h-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0.3,0.5,0.7,1,1.5,1.1
		C23.3,50.8,23,50.7,22.8,50.5z"
        />
        <path
          className="st5"
          d="M26.7,59.7c0,0.1,0,0.2,0,0.3c0.5,0,1.1-0.4,1.5,0.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0-0.2-0.2-0.3
		c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.2-0.5-0.3-0.9-0.3C27.1,59.4,26.9,59.6,26.7,59.7z"
        />
        <path
          className="st2"
          d="M33.9,50.8c-0.7,0.2-1.2,0.7-1.8,0.6c0.1,0.1,0.1,0.3,0.2,0.4C32.6,51.3,33.8,52,33.9,50.8z"
        />
        <path
          className="st2"
          d="M30.7,53.5L30.7,53.5L30.7,53.5C30.7,53.5,30.7,53.5,30.7,53.5c-0.2,0-0.3,0-0.4-0.2c0,0,0-0.1,0-0.2
		c0,0,0.3-0.1,0.4-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
		c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2s0.2,0,0.2,0c0.2,0,0.4,0,0.5,0h0C30.7,53.8,30.7,53.7,30.7,53.5z"
        />
        <path
          className="st13"
          d="M24.3,49.6c0.1,0,0.2,0,0.3,0C24.5,49.6,24.5,49.6,24.3,49.6C24.4,49.6,24.3,49.6,24.3,49.6
		C24.3,49.6,24.3,49.6,24.3,49.6z"
        />
        <path
          className="st13"
          d="M25.1,49.5c-0.3,0-0.5,0-0.8,0c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0
		C25.2,49.5,25.2,49.5,25.1,49.5z"
        />
        <path
          className="st13"
          d="M24.8,50c0.2,0.1,0.4,0,0.5-0.3c-0.3,0-0.6,0-0.9,0c0,0,0,0,0,0C24.6,49.9,24.7,50,24.8,50z"
        />
        <path
          className="st13"
          d="M28.6,53.3c0.7-0.2,0.1-0.7,0.4-1.1c-0.5,0.3-0.7,0.4-0.8,0.5c-0.1,0.1,0,0.3,0.2,0.6
		C28.4,53.3,28.5,53.3,28.6,53.3z"
        />
        <path
          className="st2"
          d="M32.9,52.6c0-0.3-0.4-0.3-0.6-0.4c-0.3,0.4-0.3,0.7,0.2,0.9C32.6,52.9,32.9,52.7,32.9,52.6z"
        />
        <path
          className="st11"
          d="M24.2,47.2c-0.1,0-0.1,0-0.2,0C24.1,47.2,24.2,47.2,24.2,47.2C24.2,47.2,24.2,47.2,24.2,47.2z"
        />
        <path
          className="st5"
          d="M30.2,55.7c0,0.2,0.1,0.4,0.3,0.3c0.4-0.1,0.2-0.5,0.2-0.7c0,0,0,0,0,0C30.5,55.3,30.1,55.2,30.2,55.7z"
        />
        <path
          className="st13"
          d="M32.6,48.9c0,0,0.1,0,0.1,0C32.7,48.9,32.6,48.9,32.6,48.9C32.6,48.9,32.6,48.9,32.6,48.9z"
        />
        <path
          className="st13"
          d="M32.9,49.4c0.1,0,0.1,0,0.2,0l0,0c0-0.2-0.1-0.4-0.2-0.5c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0
		c-0.1,0.1-0.1,0.2-0.1,0.2C32.5,49.5,32.7,49.4,32.9,49.4z"
        />
        <polygon
          className="st13"
          points="33.1,49.4 33.1,49.4 33.1,49.4 33.1,49.4 33.1,49.4 	"
        />
        <path
          className="st13"
          d="M20.2,53.9c-0.3,0.2,0.1,0.4,0.2,0.5c0.1-0.1,0.3-0.1,0.4-0.2C20.7,53.8,20.5,53.6,20.2,53.9z"
        />
        <path
          className="st13"
          d="M25,54.2c0.1,0,0.1-0.2,0.1-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.1,0.2-0.1,0.2C24.7,54.1,24.8,54.3,25,54.2z"
        />
        <path
          className="st11"
          d="M20.2,47.9C20.1,48,20,48.1,20,48.1c0,0,0,0.1,0,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1C20.3,48.1,20.3,48,20.2,47.9z"
        />
        <path
          className="st13"
          d="M23,51.4c-0.2,0.1-0.5-0.1-0.6,0.1c0,0,0.1,0.2,0.2,0.3c0.1,0,0.2-0.1,0.3-0.1C22.9,51.6,23,51.5,23,51.4z"
        />
        <path
          className="st13"
          d="M25.2,53.2c0-0.2,0-0.2-0.2-0.2c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.2,0.1,0.2C25,53.3,25.1,53.2,25.2,53.2z"
        />
        <path
          className="st13"
          d="M23.6,53.7c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.2
		C23.8,53.5,23.7,53.6,23.6,53.7z"
        />
        <path
          className="st5"
          d="M29,55.4c0,0,0,0.2,0.1,0.2c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2-0.1-0.2C29.2,55.4,29.1,55.4,29,55.4z"
        />
        <path
          className="st13"
          d="M33.6,49.2c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0C33.4,49.8,33.5,49.5,33.6,49.2z"
        />
        <polygon
          className="st13"
          points="33.1,49.4 33.1,49.4 33.1,49.4 33.1,49.4 	"
        />
        <path
          className="st2"
          d="M30.7,53.1c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0l0,0c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.2-0.1-0.3S30.9,53.1,30.7,53.1
		C30.7,53.1,30.7,53.1,30.7,53.1z"
        />
        <path
          className="st2"
          d="M31.6,53.5c0.1-0.1,0.2-0.2,0-0.3c-0.2-0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0,0.1C31.4,53.5,31.5,53.5,31.6,53.5z"
        />
        <path
          className="st13"
          d="M25.5,50.8c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0C25.5,50.7,25.5,50.7,25.5,50.8
		C25.5,50.8,25.5,50.8,25.5,50.8z"
        />
        <path
          className="st5"
          d="M29.7,56.2C29.7,56.2,29.8,56.1,29.7,56.2c0-0.1,0-0.2,0-0.2c0,0-0.1,0.1-0.1,0.1
		C29.6,56.1,29.6,56.2,29.7,56.2z"
        />
        <path
          className="st13"
          d="M25.9,49.4C25.9,49.4,25.9,49.4,25.9,49.4c0,0-0.1,0.1-0.1,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1-0.1
		c0,0,0,0-0.1,0C26.1,49.4,26,49.4,25.9,49.4z"
        />
        <path
          className="st13"
          d="M30.5,52.9c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1-0.1,0.2-0.1,0.3C30.2,52.9,30.4,52.9,30.5,52.9z"
        />
        <polygon className="st13" points="28.2,51.4 28.1,51.5 28.2,51.5 	" />
        <path
          className="st4"
          d="M32.5,53.6C32.5,53.6,32.5,53.6,32.5,53.6c0.1,0,0.1,0,0.2,0l0,0C32.6,53.5,32.5,53.5,32.5,53.6z"
        />
        <path className="st14" d="M24.9,57.6c-0.1,0.1-0.1,0.1-0.2,0.2" />
        <path
          className="st5"
          d="M31.6,50.9C31.6,50.9,31.6,50.9,31.6,50.9C31.6,50.9,31.6,50.9,31.6,50.9C31.6,50.9,31.6,50.9,31.6,50.9
		L31.6,50.9z"
        />
        <path
          className="st2"
          d="M61.2,48.2c-0.1,0.1-0.3,0.1-0.4,0.1c0.1,0,0.3,0,0.4,0C61.2,48.2,61.3,48.2,61.2,48.2z"
        />
        <path
          className="st2"
          d="M58.1,46.9C58.1,46.9,58.1,46.9,58.1,46.9C58,46.9,58,46.9,58.1,46.9C58,46.9,58.1,46.9,58.1,46.9z"
        />
        <path
          className="st2"
          d="M61.1,46.7C61.1,46.6,61,46.6,61.1,46.7L61.1,46.7z"
        />
        <path
          className="st2"
          d="M60.9,46.7c-0.1-0.1-0.1-0.1-0.1-0.1C60.8,46.7,60.8,46.7,60.9,46.7z"
        />
        <path
          className="st2"
          d="M63.2,46.9C63.2,46.9,63.2,46.9,63.2,46.9C63.2,46.9,63.2,46.9,63.2,46.9c0-0.1-0.1-0.1-0.2-0.1c0,0,0,0,0,0
		C63,46.9,63,46.9,63.2,46.9z"
        />
        <path className="st2" d="M61.7,46.9V47C61.7,47,61.8,46.9,61.7,46.9z" />
        <path
          className="st2"
          d="M56.6,48.4c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.4,0.2c0.1,0.1,0.3,0.3,0.4,0.4
		c0.3-0.1,0.6-0.4,0.8,0.1c0.1,0.2,0.1,0.5-0.1,0.7c-0.3,0.2-0.5-0.1-0.7-0.3c-0.4,0-0.9,0-1.3,0c0.3,0.7,0.8,1.3,0.6,2.3
		c-0.1,0.4,0.4,0.3,0.5-0.1c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.2-0.3-0.1-0.4s0.1-0.2,0.3-0.3c0.2-0.1,0.3,0.1,0.3,0.3c0.1,0,0.3,0,0.4,0
		c0-0.2-0.1-0.5,0.2-0.5c0,0,0.1,0,0.1,0c0.1,0,0.1,0.2,0.1,0.3c0.1,0,0.2,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3,0,0.2,0.3
		c0,0.2-0.2,0.4-0.2,0.6c-0.1,0.8-0.3,1.2-1,0.3c-0.1-0.1-0.3-0.1-0.4-0.1l0,0c0,1-0.6,1.6-1.6,1.6c0.2,0.2,0.3,0.2,0.5,0.1
		c0.8-0.3,1.5,0.2,1.9,0.8c0.4,0.6-0.8,0.3-0.7,0.9c0,0.1,0,0.1,0,0.2c1.1,0,1.1,0,1.1,1.1c0,0.4,0.1,0.7,0.5,0.9
		c0.4,0.2,0.6,0.5,0.5,0.9c-0.5,0-0.5-0.7-1-0.6c-0.1,0.3,0.1,0.9-0.5,0.9c-0.5,0-1,0.1-1.2-0.6c-0.1-0.3-0.5-0.5-0.8-0.4
		c-0.3,0.1-0.1,0.3,0,0.5c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.3,0.4,0.9,0.1,0.9c-0.4,0.1-1,0.5-1.3-0.2c-0.1-0.4-0.3-0.4-0.6-0.4
		c-0.1,0.5-0.6,0.6-0.8,0.3c-0.2-0.3-0.2-0.8,0.3-1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.4-0.5,0.7-0.7
		c-0.2-0.8,0.7-0.4,0.9-0.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.1-0.9,0.4
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.2,0.1-0.2,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
		c0,0-0.1-0.1-0.1-0.1c-0.2,0-0.5,0-0.6-0.1c0,0-0.1-0.1-0.1-0.2c0-0.2,0.4-0.1,0.6-0.1l0,0c0-0.6,0.7-0.6,0.9-1.1h0
		c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.1-0.7-0.4c-0.5-0.9-1.4-0.9-2.2-1c-0.7-0.1-1.2,0.6-1.9,0.6c-0.2,0.1-0.2,0.3,0,0.4
		c0.1,0,0.4,0,0.3,0.3c-0.1,0.2-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.2-0.5-0.3s-0.4-0.2-0.6-0.1
		c-0.1,0.1-0.2,0.1-0.2,0.2c-0.2,0.3-0.1,0.6,0,1c-0.7-0.4-0.8-0.9-0.6-1.4c-0.6,0-1.2,0.1-0.9-0.9c-0.3,0-0.5,0.1-0.7,0.2
		c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0
		c-0.5-0.3-0.8-0.3-0.9,0.3c-0.9-0.4-0.9,0.1-1,0.8c0,0.2-0.5,0.3-0.8,0.4c0,0-0.2-0.2-0.2-0.3c-0.1-0.4,0.3-0.3,0.4-0.4
		c0.2-0.2,0.2-0.3,0-0.3c0,0-0.1,0-0.2-0.1c-0.7,0.4-1.4,0.7-2.2,0.9c0.6,0.4,0.6,1.2,1.2,1.6c0,0,0.1,0,0.1,0
		c0.1-1.3,1.1-0.7,1.7-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0-0.3,0.1-0.6,0.4-0.7c0.3-0.2,0.5-0.1,0.8,0.1c0.1,0.1,0.3,0.2,0.2,0.4
		c-0.1,0.3-0.4,0.2-0.6,0.2c-0.2,0-0.5,0-0.7,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6c0.5,0,0.7,0.2,0.4,0.6
		c-0.1,0.2-0.2,0.6,0,0.5c1.2-0.4,0.2,0.8,0.7,0.9c0.8-0.2,1.4-0.7,1.8-1.8c0,0.6,0.1,0.9,0.1,1.2l0,0c0.2,0,0.5,0,0.5,0.2
		c0,0.3-0.3,0.4-0.5,0.4c-0.2,0-0.4,0-0.5,0c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0,0.2,0.2,0.5v0c0.5,0,0.8-0.5,1.3-0.4
		c-0.1,0.6,0.3,1.1,0.4,1.7c0.2,0,0.5,0,0.7,0c0,0,0.1,0,0.1,0c0.3,0,0.5,0,0.7,0c0.9,0,1.8,0,2.7,0c0,0-0.1-0.1-0.1-0.2
		c0-0.4,0.4-0.4,0.7-0.4c0.3,0,0.8-0.1,0.8,0.3c0,0.1,0,0.2-0.1,0.2c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0,0,0-0.1,0-0.1
		c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0
		c0,0,0.1,0,0.1,0c0.2-0.6,0.7-0.7,1.3-0.3c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0c0-0.1,0.1-0.2,0.2-0.2
		c0.1,0,0.2,0.1,0.2,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0.3,0,0.7,0,1,0c0,0,0,0,0.1,0c0-0.1,0-0.2,0-0.2c0.6-0.2,1,0,1.4,0.2
		c0.2,0,0.4,0,0.7,0c-0.1-0.3-0.1-0.5-0.2-0.8h0c-0.5,0-1,0-1.7,0c0.6-0.4,0.9-0.6,1.1-0.6c0.1,0,0.2,0.1,0.3,0.1
		c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0,0.4,0,0.6,0l0,0c0.1-0.4-0.1-1.4,0.9-0.5c0.1,0,0.2,0,0.3,0c0.1,0,0.7,0.1,1.3,0.2
		c0.6,0.1,1.1,0.3,1.2,0.4c0,0,0,0,0,0c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.3-0.1-0.5c0-0.4-0.3-0.7-0.7-0.9
		c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.2-0.6,0.2-0.9-0.2c-0.4-0.5-1.1-0.1-1.6-0.5c-0.2-0.2-0.4-0.3-0.1-0.5c0.2-0.2,2.2-0.1,2.4,0.1
		c0.1,0.1,0.1,0.1,0,0.2c-0.1,0.5-0.1,0.5,0.6,0.4c0.3-0.3,0.5-0.6,0.5-0.9c0.1-0.3,0.1-0.7,0.1-1c0-0.1,0-0.3,0-0.4
		c0-0.1,0-0.2,0-0.3c0,0,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.4,0.1-0.4c0.5,0,0.9-0.4,1.5-0.3c0.6-0.2,1.1,0.7,1.7,0.2c0-0.1,0-0.3,0-0.4
		c-0.2,0-0.4,0-0.5,0s-0.3-0.1-0.3-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c-1.3,0.5-2.5-0.1-3.6-0.7c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.2,0.2-0.5,0.5-0.4c0.2,0.1,0.2,0.3,0.2,0.5c0,0.3,0,0.4,0.3,0.4c0.2,0,0.4,0,0.3-0.3c0.8-0.2,1-1.6,2.2-1.2
		c-0.1-0.2-0.3-0.5-0.4-0.7c-0.2-0.4-0.2-0.8,0.5-1.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0
		c0,0-0.1,0-0.2,0c-0.1,0,0-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0c0,0,0.1,0,0.1,0h-0.1c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0
		c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.9,0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0
		c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.5,0v-0.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.3,0.1-0.4,0.1v-0.1
		c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0-0.5-0.1-0.8-0.2
		c0.3-0.1,0.7-0.2,1-0.3c0,0-0.1-0.1-0.1-0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.3,0-0.5,0c0,0-0.1,0-0.2,0
		c-0.1,0.1-0.2,0.1-0.3,0.2C57,48.5,56.8,48.4,56.6,48.4z M43.9,58.5C43.9,58.5,43.9,58.5,43.9,58.5L43.9,58.5
		C43.9,58.5,43.9,58.5,43.9,58.5c0.3-1.1,0.6-1.2,1.5-0.4C44.8,58,44.3,58.3,43.9,58.5z M44.3,56.4c-0.4-0.3-0.9-0.5-1.4-0.3
		c-0.2,0.1-0.5,0-0.5-0.3c0-0.3,0.2-0.3,0.4-0.3c0.7-0.2,1.4-0.6,1.7,0.5c0.1,0.3,0.2,0.2,1.2,0.3C45.3,57.1,45.3,57.1,44.3,56.4z
		 M48.6,53.9c0.4,0.4,0.7,0.8,0.7,1.3c0,0,0,0,0.1,0.1c0,0-0.1,0-0.1,0l0,0c-0.4,0-0.6-0.2-0.6-0.4c0-0.1,0-0.1,0-0.2
		c0-0.1,0-0.3,0-0.4C48.6,54.1,48.6,54,48.6,53.9z M51.1,58.2c-0.2,0.3-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.1-0.5,0.1c-0.5,0-1,0-1.4,0c0,0.5-0.2,0.7-0.4,0.7c-0.2,0.1-0.5,0-0.8-0.2
		c0.2-0.2,0.4-0.4,0.6-0.6c-0.1-0.4-0.5-0.7,0.2-1c0.2-0.1,0.3-0.3,0.3-0.5c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.1c0.3-0.5-0.4-1.1,0.2-1.6c0.4-0.4,0.6,0,0.8,0.1
		c0.5,0.1,1,0.3,1.5,0c0.1-0.1,0.4-0.2,0.4,0.1c0,0.1-0.1,0.2-0.2,0.4c0,0.1-0.1,0.1-0.2,0.1c-0.6,0.1-1.1,0.3-1.1,1
		c0.4,0.5,0.9,0.9,1.5,1.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.3-0.6,0.1-1,0.2-1.5c-0.1-0.3,0.2-0.3,0.4-0.3c0,0,0.1,0.1,0.1,0.1
		c0,0.2-0.2,0.2-0.3,0.2c0,0.2,0,0.4,0.1,0.5C50.8,57.7,51.3,57.8,51.1,58.2z M49.8,57.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.1-0.1
		c0,0,0.1-0.1,0.1-0.1C49.7,57.1,49.7,57.1,49.8,57.2C49.8,57.2,49.8,57.2,49.8,57.2C49.8,57.2,49.8,57.2,49.8,57.2z M60.5,55.5
		c-0.3-0.5-0.6-0.9-0.2-1.3c0-0.1,0.3-0.1,0.3,0C60.7,54.5,60.7,54.9,60.5,55.5z M61.1,53.1c-0.5,0.2-0.9,0.3-1.1,0.4
		c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.2-0.6-0.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.1-0.2
		c0-0.1,0.1-0.3,0.2-0.3c0.3-0.1,0.6-0.4,0.7,0.1C60,52.5,60.4,53.1,61.1,53.1z M58.2,49.6c-0.2-0.2,0-0.5,0.2-0.6
		c0.3-0.1,0.6-0.1,0.6,0.4c0,0.3,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.3-0.3,0.2-0.5,0c-0.4-0.3-0.6,0.3-1.1,0.1
		C58.3,50.3,58.6,50.1,58.2,49.6z"
        />
        <path
          className="st11"
          d="M64.9,58.6c-0.8-0.5-1.3-0.1-1.9,0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0.1,0.5,0.3,1,0.5,1.5c0.3,0,0.7,0,1,0
		C64.3,59.6,63.8,58.9,64.9,58.6z M63.7,59.1c0,0-0.1-0.1-0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1
		C63.9,59,63.9,59.1,63.7,59.1z"
        />
        <path
          className="st11"
          d="M64.5,60.2c0.2,0,0.5,0,0.7,0c0-0.1,0-0.3-0.1-0.4C64.8,59.7,64.7,59.9,64.5,60.2z"
        />
        <path
          className="st12"
          d="M62.1,59c0-0.1,0-0.1,0-0.2c0.1-0.3,0.4-0.2,0.7-0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0-0.3,0-0.6-0.4-0.6
		c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.3,0-0.4,0c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.4,0.1,0.6C62.1,59.6,62.1,59.3,62.1,59z"
        />
        <path
          className="st2"
          d="M58.1,59.3L58.1,59.3c0,0.3,0.1,0.6-0.1,0.8c0.3,0,0.5,0,0.8,0c-0.1-0.2-0.3-0.4-0.4-0.6
		C58.4,59.4,58.2,59.4,58.1,59.3z"
        />
        <path
          className="st4"
          d="M53.8,59.9c-0.1,0-0.2,0.1-0.2,0.2c0.1,0,0.3,0,0.4,0C53.9,60,53.9,59.9,53.8,59.9z"
        />
        <path
          className="st5"
          d="M55.9,48.3c0.2,0,0.4,0,0.6,0c0-0.1,0-0.1,0.1-0.2c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4,0
		C55.9,48.2,55.9,48.3,55.9,48.3C55.9,48.3,55.9,48.3,55.9,48.3z"
        />
        <path
          className="st4"
          d="M53.1,59.1c0.3,0.7,1,0.3,1.3,0.2c0.3-0.1,0-0.6-0.1-0.9c0-0.1-0.1-0.2-0.2-0.4C54.4,58.9,53.6,58.9,53.1,59.1
		z"
        />
        <path
          className="st5"
          d="M51.9,53.5c0.1,0.5,0.5,0.1,0.8,0.1c0.2,0,0.5-0.1,0.5-0.5c-0.2-0.2-0.4-0.1-0.6,0
		C52.4,53.1,51.8,53,51.9,53.5z"
        />
        <path
          className="st5"
          d="M54.5,55.8C54.5,55.8,54.4,55.7,54.5,55.8c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0.5-1.1,0-0.9,0.8c0.4-0.1,0.8-0.2,1.1-0.3C54.4,56.2,54.6,56,54.5,55.8z"
        />
        <path
          className="st5"
          d="M52,58c-0.6,0.2-0.6,0.7-0.3,1c0.2,0.3,0.7,0.1,0.8-0.3C52.2,58.5,52.1,58.3,52,58z"
        />
        <path
          className="st2"
          d="M55.5,56c0,0.7-0.1,1.2,0.2,1.8C56,57.1,56,56.6,55.5,56z"
        />
        <path
          className="st5"
          d="M52.7,54.9c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0.5-0.9,0.5-0.9,1.1c0.1,0,0.3,0,0.4,0c0.1-0.1,0.1-0.2,0.2-0.3
		c0.2-0.2,0.5-0.4,0.9-0.4C52.9,55,52.8,54.9,52.7,54.9z"
        />
        <path
          className="st6"
          d="M55.7,51.6c0-0.6,0.9-0.3,0.9-0.9c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.3,0.3-0.4,0.4
		c0,0.1,0,0.3,0,0.4C55.4,51.6,55.6,51.6,55.7,51.6L55.7,51.6z"
        />
        <path
          className="st6"
          d="M40.4,52L40.4,52c0.6,0.1,0.9-0.4,1.6-0.7C41.1,51.1,40.6,51.3,40.4,52z"
        />
        <path
          className="st2"
          d="M41.8,48.6c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1C41.7,48.6,41.8,48.6,41.8,48.6z"
        />
        <path
          className="st6"
          d="M40.4,52.1C40.4,52.1,40.4,52,40.4,52.1L40.4,52.1C40.4,52,40.4,52.1,40.4,52.1z"
        />
        <path
          className="st3"
          d="M39.1,56c0.1,0,0.1,0.1,0.2,0.1c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0c0-0.2,0-0.4,0-0.6c-0.6,0.2-1.7-0.4-1.7,0.9
		c0.1,0,0.3,0,0.4,0C38.8,56.1,38.9,55.9,39.1,56z"
        />
        <polygon className="st3" points="40,55.4 40,55.5 40,55.5 	" />
        <path
          className="st2"
          d="M37,54.8c-0.1-1.1-0.8,0-1.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.3,0.1,0.7,0.4,0.9
		C36.7,55.4,36.5,54.8,37,54.8z"
        />
        <path
          className="st6"
          d="M39.1,56c-0.2,0-0.3,0.2-0.4,0.4c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.1,0.2,0.4,0.4,0.2c0.1-0.1,0.1-0.3,0.1-0.5
		c-0.2-0.2-0.2-0.4-0.2-0.6C39.3,56,39.2,56,39.1,56z"
        />
        <path
          className="st2"
          d="M39.4,56.1c0,0.2,0,0.5,0.2,0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.4
		C39.8,56.1,39.6,56.1,39.4,56.1z"
        />
        <path
          className="st2"
          d="M42.9,57.7c0,0,0.1-0.1,0.1-0.1l0,0C42.9,57.6,42.9,57.6,42.9,57.7C42.8,57.7,42.8,57.7,42.9,57.7z"
        />
        <path
          className="st2"
          d="M43,59.5c-0.2,0.2-0.2,0.4-0.2,0.7c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6,0c0-0.2,0-0.4-0.2-0.6
		C43.3,59.5,43.1,59.5,43,59.5z"
        />
        <path
          className="st3"
          d="M45,52.6c-0.8-1-1.1-1.1-2.3-0.1C43.5,52.5,44.2,52.8,45,52.6z"
        />
        <path
          className="st3"
          d="M45.2,53.5c0.1,0,0.2,0,0.2,0c0-0.1,0-0.3,0-0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.2-0.1-0.4-0.1c-0.2-0.1-0.5-0.1-0.7,0.1c-0.4,0.9,0.3,0.9,0.9,0.9C44.8,53.7,44.9,53.5,45.2,53.5z"
        />
        <path
          className="st13"
          d="M45.4,53.1c0,0.1,0,0.3,0,0.4c0.5,0.1,0.9,0.1,1-0.1s0.2-0.6,0-1.2h0c-0.1,0-0.2,0.1-0.3,0.2S46,52.6,46,52.7
		c0,0.1-0.1,0.1-0.1,0.2C45.8,53,45.6,53.1,45.4,53.1z"
        />
        <polygon className="st2" points="36.5,53.8 36.5,53.9 36.6,53.8 	" />
        <path
          className="st5"
          d="M45.2,53.5c-0.3,0-0.4,0.2-0.4,0.4c-0.2,0.6-0.2,1.1,0.6,1.4c-0.1-0.4-0.1-0.7,0-1c0.1-0.1,0.1-0.2,0.2-0.2
		C45.2,54.1,45.2,53.8,45.2,53.5z"
        />
        <path
          className="st3"
          d="M47.4,54.6c0.1-0.2-0.1-0.2-0.3-0.3c0,0.1,0,0.1,0,0.2C47.2,54.7,47.3,54.8,47.4,54.6z"
        />
        <path
          className="st3"
          d="M56.2,49.7c0.3-0.1,0.2-0.4,0.1-0.7c-0.2-0.5-0.5-0.2-0.8-0.1c0,0.1,0,0.3,0,0.4
		C55.6,49.7,55.8,49.9,56.2,49.7z"
        />
        <path
          className="st8"
          d="M80.6,46.9c0,0-0.1,0-0.1,0c0,0,0,0,0,0C80.5,46.9,80.5,46.9,80.6,46.9z"
        />
        <path
          className="st8"
          d="M77.9,47C77.9,47,77.9,47,77.9,47c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0h0.1h0.1c0.1,0,0.2,0,0.3,0
		c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0C77.5,46.9,77,47,76.6,47
		c0,0,0,0,0,0c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.7,0C77.8,47,77.8,47,77.9,47z"
        />
        <path
          className="st8"
          d="M78.1,49.2c0.3,0.1,0.5,0.1,0.8,0.1c0.7-0.1,1.3-0.7,2.1-0.9c0-0.1,0-0.2,0-0.4c-0.1,0-0.2,0-0.3,0.1
		c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.3,0-0.5,0c0,0.2-0.1,0.4-0.4,0.3c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
		c-0.5,0-0.9,0-1.4,0.1c0,0.1,0,0.2,0,0.3C77.9,48.7,78.1,48.9,78.1,49.2z"
        />
        <path
          className="st15"
          d="M85.1,47.3C85.1,47.3,85.1,47.3,85.1,47.3C85,47.3,85,47.3,85.1,47.3C85.1,47.3,85.1,47.3,85.1,47.3z"
        />
        <polygon
          className="st15"
          points="84.5,47.3 84.5,47.3 84.5,47.3 84.5,47.3 	"
        />
        <path
          className="st15"
          d="M90.5,47.4c0.3,0,0.6-0.1,0.9-0.1c-0.5,0-1,0-1.4,0c-0.5,0-0.9,0-1.3,0.1h0.1c0.5,0,0.9,0,1.4,0
		C90.3,47.4,90.4,47.4,90.5,47.4z"
        />
        <path
          className="st15"
          d="M88.1,46.7c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0c-0.2,0.1-0.3,0.2-0.3,0.3c0,0,0.1,0,0.1,0
		c-0.1,0.1,0,0.1,0,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.3-0.1h0.1h0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0.1
		C88.3,46.8,88.2,46.8,88.1,46.7z M87.6,47c0-0.2,0.3-0.2,0.4-0.2C87.9,46.9,87.8,46.9,87.6,47z"
        />
        <path
          className="st15"
          d="M86.5,46.6C86.6,46.6,86.6,46.6,86.5,46.6c-0.1,0-0.1,0-0.2,0h-0.1H86c-0.2,0.1-0.4,0.1-0.6,0.1
		c-0.2,0-0.4,0-0.7,0.1c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0.1c0.4,0,0.7,0,1,0C86.3,46.8,86.4,46.7,86.5,46.6z"
        />
        <path
          className="st15"
          d="M84.4,47.2c-0.1,0-0.1,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.2,0
		c0,0-0.1,0-0.1,0C83.6,47.3,84.1,47.3,84.4,47.2L84.4,47.2C84.5,47.3,84.4,47.2,84.4,47.2z"
        />
        <path
          className="st15"
          d="M85.6,47.3c0.1,0,0.2,0,0.2,0c0.1,0,0-0.1,0-0.1C85.7,47.3,85.6,47.3,85.6,47.3z"
        />
        <path
          className="st15"
          d="M88.9,48.6c0.4,0.1,0.8-0.2,1,0.3c0.1,0.2,0.1,0.3,0,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.2,0.1-0.4,0.2-0.4,0.4
		c0.4,0.1,1-0.2,1.4,0c0.1,0.1,0.2,0.2,0.3,0.5c0.1,0.2,0.3,0.6-0.3,0.4c-0.2,0-0.6,0.2-0.7,0.4c-0.2,0.6-0.6,0.7-1,0.3
		c-0.5-0.5-0.6-0.2-0.6,0.2c-0.1,0.7-0.4,0.5-0.8,0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.4,0-0.6,0c0,0.3,0,0.7,0,1
		c0.3,0.2,0.6,0.4,0.8-0.1c0-0.1,0.1-0.2,0.2-0.2c0.2-0.1,0.4-0.1,0.6-0.1l0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3l0,0c0,0,0,0,0,0
		c0.2,0,0.3,0,0.4,0.1s0.2,0.2,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.2c0.4,0,0.7,0,1.1,0c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.3-0.4-0.6-0.7,0-1.1c0.6-0.4,1-1.1,1.8-0.9c0.3,0.1,0.8-0.1,0.7,0.5c-0.1,0.6-0.4-0.2-0.7,0c-0.1,0.1-0.3,0.1-0.4,0.1
		c0.3,0.4,1.1,0.6,0.6,1.4c0.1,0.1,0.3,0,0.4,0c0.2-0.1,0.5-0.2,0.7,0.1c0.5-0.2,0.7-0.2,0.8,0c0,0,0,0.1,0,0.1
		c0.1,0.2,0.1,0.4,0.1,0.5c0,0.2,0,0.3,0.1,0.4c0.4,0.7-0.4,1.1-0.4,1.7c0.1,0.2,0.1,0.3,0.1,0.4c0,0.3-0.3,0.4-0.8,0.4
		c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0v0c0.3,0.2,0.2,0.6,0.6,0.6c0,0,0,0,0,0v0c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3
		c0.3-0.6,0.8-0.7,1.4-0.3c0.4-0.1,0.7-0.2,1.2-0.3c-0.2-0.3-0.7,0-0.8-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.3-0.2
		c0-0.2,0.2-0.2,0.3-0.3c0.1,0,0.3,0,0.4,0c0.3,0,0.7-0.1,1-0.1c0.3,0.4-0.4,0.9,0.3,1.3c0.2-1,0.4-2,0.7-2.9c0-0.1,0-0.2-0.1-0.2
		c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.2-0.1,0.4-0.4,0.3c-0.3-0.1-0.5-0.2-0.5-0.5c0-0.2-0.2-0.6,0.1-0.6c0.6,0,0.8-0.8,1.4-0.7
		c0.3,0,0.7,0,1,0c-0.2-0.2-0.4-0.4-0.5-0.7c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.4c-0.2,0.1-0.3,0.1-0.4,0.1
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.1s-0.2-0.1-0.2-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1
		c0,0.1,0.1,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.2,0.3-0.2,0.4
		c0,0.2,0.1,0.5,0.1,0.7c-0.3,0.2-0.6-0.1-0.9-0.1c0.7-0.6-0.6-1.2,0-1.9c-0.4-0.3-0.5-0.6-0.2-1.1c-0.3,0-0.6,0.1-0.9,0.2
		c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.1,0.2-0.2,0.2
		c-0.3,0.4-0.6,0.2-0.8-0.2c-0.1-0.2-0.2-0.2-0.4-0.2c-0.3,0-0.6,0-0.6-0.5c0,0,0,0,0,0v0c0,0,0.1,0,0.1-0.1
		c0.2-0.1,0.4-0.2,0.5-0.3c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2-0.1-0.6-0.1-0.8-0.2c-0.1-0.1-0.2-0.1-0.3-0.3c0-0.1,0-0.3,0-0.6
		c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.6,0c0.4-0.2-0.4-0.6,0.2-0.8c0-0.1,0-0.2,0-0.4c-0.3,0-0.5,0.1-0.8,0
		c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.3,0-0.5,0-0.8,0h-0.2
		C89.1,48.5,88.9,48.5,88.9,48.6C88.8,48.5,88.9,48.5,88.9,48.6z M95.4,53.7c0.4,0.3,1.1,0,1.3,0.4c0.1,0.5-0.8,0.4-0.8,1.2
		C95.4,54.7,95.3,54.3,95.4,53.7z M95.2,55.3c0.3,0.2,0.6,0.3,0.4,0.6c0,0.1-0.2,0.1-0.3,0.1C94.9,55.8,95.2,55.5,95.2,55.3z"
        />
        <path
          className="st15"
          d="M88.6,48.6C88.7,48.5,88.7,48.5,88.6,48.6c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0c0,0,0.1,0.1,0.1,0.1
		C88.5,48.6,88.6,48.6,88.6,48.6L88.6,48.6z"
        />
        <path
          className="st15"
          d="M82.7,48.8c0.2,0.2,0.4,0,0.6-0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.4,0.3,0.5,0.7
		c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.2,0.3,0.4,0.3,0.7c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.3,0.4
		c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0.1,0.7,0,1-0.6c-0.1,0-0.2,0-0.3,0.1s-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1-0.1
		c-0.1-0.1-0.1-0.4-0.2-0.9c-0.2,0-0.4,0-0.6,0c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.3-0.1-0.1-0.5-0.2-0.7
		c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c-0.4,0-0.8,0-1.2,0c-0.2,0-0.3,0-0.5,0
		c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0C82.4,48.5,82.5,48.7,82.7,48.8z"
        />
        <path
          className="st15"
          d="M89.2,47c0,0,0.1,0,0.2,0c0.2,0.1,0.4,0,0.6,0c0.3-0.1,0.5-0.1,0.7-0.2c-0.1,0-0.2,0-0.3,0h-0.1
		c0.1,0,0.2-0.1,0.2-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.9,0-1.3,0c0.1,0.1,0.2,0.2,0.1,0.4c0,0,0,0,0,0C88.9,47,89.1,47,89.2,47
		z M89.6,46.9C89.6,46.9,89.5,46.9,89.6,46.9c-0.1,0-0.2,0-0.3,0C89.4,46.9,89.6,46.8,89.6,46.9z"
        />
        <path
          className="st15"
          d="M92.2,48.6c0,0-0.1,0-0.2,0C92.1,48.6,92.2,48.6,92.2,48.6C92.2,48.6,92.2,48.6,92.2,48.6z"
        />
        <path
          className="st10"
          d="M95.4,59.5c0.5-0.5,0.4-0.7,0.1-0.9c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.2,0.1-0.3,0.1
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0l0,0c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4-0.1,0.4c-0.4,0.2-0.4,0.5-0.3,0.7c0.3,0,0.7,0,1,0
		C95.1,60,95.1,59.8,95.4,59.5z"
        />
        <path
          className="st10"
          d="M101.5,60.3c0.1,0,0.2,0,0.2,0c0.1,0,0,0,0.1-0.1C101.7,60.3,101.4,60.2,101.5,60.3z"
        />
        <path
          className="st10"
          d="M98.8,58.5c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.5-0.2,0.6-0.5,0.6-1c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.7-0.4-0.7-0.7
		c0-0.1,0-0.2,0-0.3c0.2-0.6-0.1-0.9-0.5-1.2c-0.2,1-0.4,1.9-0.7,2.9c-0.7-0.4-0.1-0.9-0.3-1.3c-0.3,0-0.7,0.1-1,0.1
		c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.3,0.6,0,0.8,0.3c-0.5,0.1-0.8,0.2-1.2,0.3c0,0.1,0,0.3,0,0.4
		c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.5,0.3,0.7,0.5c0.3,0.3,0.5,0.6,0.2,1.2c0,0.1,0,0.3-0.1,0.4c0.6-0.2,0.9-0.1,1,0.2
		c0,0,0,0,0.1,0c0.3,0,0.6-0.1,0.9-0.1c0-0.5,0-0.9,0-1.3C98.9,58.6,98.8,58.6,98.8,58.5z M98.3,59.3c-0.1,0-0.3,0-0.3-0.2
		c0,0,0-0.1,0.1-0.1C98.3,59,98.3,59.2,98.3,59.3C98.3,59.3,98.4,59.3,98.3,59.3C98.4,59.3,98.3,59.3,98.3,59.3z"
        />
        <path
          className="st10"
          d="M103.9,59.8c0-0.1,0-0.2,0-0.2c-0.4,0-1,0.6-1.1-0.4c-0.3-0.2-0.4-0.3-0.5-0.4c0,0,0,0,0-0.1
		c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0.1-0.4,0.2-0.8c0-0.1,0-0.1,0-0.2c-0.6-0.3-0.9,0.1-1,0.5c-0.1,0.4-0.4,0.7-0.8,0.8
		c-0.3,0-0.5,0-0.7-0.2c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0.1,0.3
		c0.5,0.4,0.4,0.5,0.2,0.7c0.2,0,0.5,0,0.7,0c0.6,0,1.2-0.1,1.8-0.1s1.1-0.1,1.5-0.1c0.2,0,0.4,0,0.4,0L103.9,59.8
		C103.9,59.8,103.9,59.8,103.9,59.8z M100.1,59.3L100.1,59.3L100.1,59.3L100.1,59.3z"
        />
        <path
          className="st10"
          d="M109.3,50.3C109.3,50.3,109.3,50.2,109.3,50.3L109.3,50.3C109.3,50.2,109.3,50.3,109.3,50.3L109.3,50.3z"
        />
        <path
          className="st10"
          d="M105.8,49.4c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0c0.1-0.3-0.2-0.3-0.4-0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0
		C105.8,49.3,105.8,49.3,105.8,49.4z"
        />
        <path
          className="st10"
          d="M106.3,49.1c0.2,0,0.3,0,0.5,0c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1
		C106,49.1,106.2,49.1,106.3,49.1z"
        />
        <path
          className="st10"
          d="M105.4,50.1C105.4,50.1,105.4,50.1,105.4,50.1c0.3,0.1,0.8-0.3,1,0c0.2,0.3-0.1,0.7-0.3,1
		c0.1,0.2,0.1,0.5,0.2,0.7c0.1-0.1,0.2-0.1,0.3-0.1v-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.1,0,0.2-0.1c0.2-0.2,0.4-0.4,0.8-0.4
		c0,0,0,0,0,0h0l0,0c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.3-0.3c0.3-0.2,0.9-0.1,1-0.6c0,0,0,0-0.1-0.1c-0.3,0-0.6,0-0.8,0
		c-0.5,0-1,0-1.5,0c0-0.1,0-0.1,0-0.1c0.3,0,0.7-0.1,1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c-0.6,0-1,0-1.6,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0-0.2,0c0.1-0.1,0.3-0.1,0.5-0.1h-0.1c0.2,0,0.4-0.1,0.6-0.1c0,0-0.1,0-0.1,0c-0.3,0-0.8,0-1.2-0.1
		C105.6,49.8,105.5,49.9,105.4,50.1C105.4,50.1,105.4,50.1,105.4,50.1z"
        />
        <polygon
          className="st10"
          points="108,51.1 108,51.1 108,51.1 108,51.1 	"
        />
        <path
          className="st10"
          d="M102,51.5c0.5,1.1-0.5,0.5-0.8,0.5c0,0.2,0,0.3,0,0.5s0.1,0.3,0.2,0.4c0.3,0.2,0.7,0.2,1.1,0.2c0,0,0,0,0,0
		l0,0c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0,0,0,0c0-0.2,0-0.3,0-0.5c0,0,0,0,0,0c0-0.3-0.1-0.6,0-0.9c0-0.1,0.1-0.2,0.2-0.3
		c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0c-0.3-0.1-0.8,0.2-0.8-0.2c0-0.3-0.6-0.8,0-0.8
		c0.5-0.1,0.8,0.5,0.8,1.1c0,0,0,0,0,0c0.5-0.5,0.8-1,0.9-1.7c-0.4,0-0.8-0.1-1.1-0.1c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.3,0
		c0.1,0,0.2,0,0.2,0c0-0.1,0-0.1,0-0.2c-0.9-0.1-1.8-0.1-2.7-0.1c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.2
		C101.3,50.3,101.7,50.8,102,51.5z"
        />
        <path
          className="st9"
          d="M108,51.2c-0.4,0-0.6,0.2-0.8,0.4c0.3-0.1,0.6-0.1,0.9-0.2C108.1,51.3,108,51.2,108,51.2z"
        />
        <polygon className="st9" points="103.3,50.9 103.3,50.9 103.3,50.9 	" />
        <path
          className="st9"
          d="M104.7,49.2c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0,0.4,0,0.7,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0
		c-0.2,0.1-0.5,0.1-0.7,0c0,0,0,0,0.1,0c0.2,0,0.4,0,0.5,0c-0.4-0.2-0.9,0.2-1.3-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
		c0,0.1,0,0.1,0,0.2C104.3,49.2,104.5,49.2,104.7,49.2z"
        />
        <path
          className="st9"
          d="M103.3,50.9c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.3,0.2,0.5,0.5,0.6c0.2,0,0.4,0,0.5,0.1c0,0,0,0,0,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.2-0.2s0.1-0.3,0-0.5c0-0.1,0-0.1,0-0.1c0.1-0.1,0.3-0.1,0.5-0.1c0-0.3,0-0.6,0.2-0.7
		c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.2-0.8-0.3
		c-0.1,0-0.2,0-0.3,0C104,49.9,103.8,50.5,103.3,50.9C103.3,50.9,103.3,50.9,103.3,50.9z"
        />
        <path
          className="st10"
          d="M104.8,55.7c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.5-0.1,1.1,0,1.6-0.2
		c0.3-0.1,0.6-0.2,0.8-0.6c0,0,0,0,0,0c-0.1,0-0.3,0-0.3,0c-0.6-0.1-1.3-0.1-1.9-0.1c0,0,0.1,0,0.1-0.1c-0.2,0-0.3,0-0.3-0.1
		c0,0,0,0,0,0s0,0,0.1,0c0.5-0.1,1-0.1,1.5-0.1c0,0,0,0,0.1,0c0-0.1,0-0.1-0.1-0.2c-0.3,0-0.6,0-0.9,0c0,0,0,0,0,0
		C104.6,54.2,104.8,54.9,104.8,55.7z"
        />
        <path
          className="st12"
          d="M100.9,53.5L100.9,53.5c0-0.3,0.2-0.5,0.4-0.6c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.3,0-0.5l0,0
		c-0.1-0.3-0.2-0.3-0.3-0.3c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0c-0.2,0-0.3,0-0.4,0
		c-0.1,0-0.3-0.1-0.4-0.2c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0.1,0.3,0.4,0.5,0.5,0.7
		C100.2,53.5,100.5,53.5,100.9,53.5z"
        />
        <path
          className="st15"
          d="M103.2,57.3c0.1-0.3,0.1-0.7-0.3-0.9c-0.2-0.1-0.3-0.3-0.4-0.5c0-0.2-0.1-0.4-0.4-0.3
		c-0.3,0.1-0.3,0.3-0.2,0.5c0.1,0.4,0.5,0.8,0.4,1.3c0,0.1,0,0.1,0,0.2C102.7,57.5,103.1,57.7,103.2,57.3z M102.9,57
		c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1C102.8,57.1,102.9,57.1,102.9,57z"
        />
        <path
          className="st9"
          d="M97.3,54.2c-0.2,0-0.1,0.4-0.1,0.6c0,0.3,0.2,0.4,0.5,0.5c0.3,0.1,0.3-0.2,0.4-0.3c0.2-0.3,0.4-0.7,0.6-1
		c0-0.1,0-0.3,0-0.4C98.1,53.5,97.9,54.2,97.3,54.2z"
        />
        <path
          className="st9"
          d="M104.6,58.6c-0.3,0.3-0.4,0.7-0.4,1.1c0.1,0,0.3,0,0.4,0c0.3,0,0.5-0.1,0.7-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
		C105.3,59.2,104.9,59,104.6,58.6z"
        />
        <path
          className="st9"
          d="M105.4,51.1c0.2-0.2,0.4-0.2,0.7,0c0.2-0.3,0.5-0.7,0.3-1c-0.2-0.4-0.6,0.1-1,0
		C105.4,50.4,105,50.8,105.4,51.1z"
        />
        <path
          className="st16"
          d="M100.3,56.3c0.3-0.2,0.2-0.7,0.6-0.7c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.2
		c-0.2-0.4-0.4-0.6-0.6-0.5c-0.1,0-0.2,0.1-0.3,0.2c0.3,0.2,0.3,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0
		C100.1,56.3,100.2,56.3,100.3,56.3z"
        />
        <path
          className="st12"
          d="M107.9,53.3c0.1,0,0.2-0.1,0.4-0.1c0-0.2-0.2-0.3-0.4-0.4c-0.1,0-0.1,0-0.2,0c0,0.1,0.1,0.2,0.1,0.3
		C107.9,53.2,107.9,53.2,107.9,53.3z"
        />
        <path
          className="st10"
          d="M101.3,53.5L101.3,53.5c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0.5-0.1,0.4-0.6,0.7-0.8
		C101.9,53.5,101.9,53.1,101.3,53.5z"
        />
        <path
          className="st9"
          d="M103.9,59.5c0-0.5-0.1-0.7-0.2-0.7c-0.2,0-0.7,0.4-0.9,0.3C102.9,60.1,103.5,59.5,103.9,59.5z"
        />
        <path
          className="st9"
          d="M103.3,56c0.1-0.1,0.2-0.1,0.4,0s0.3,0.1,0.4,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2-0.1,0.3-0.4c-0.1,0-0.1,0-0.2,0v-0.1c0,0,0.1,0,0.2,0c0,0,0-0.1,0-0.1C104.3,55.7,103.7,55.5,103.3,56z"
        />
        <path
          className="st9"
          d="M100.9,53.5c0.1,0,0.3,0,0.4,0c0.6-0.4,0.6,0,0.6,0.4l0,0c0.1,0,0.1,0,0.2,0c0.2-0.2,0.2-0.4,0.2-0.6
		c0-0.1,0-0.2,0-0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0-0.7,0-1.1-0.2l0,0C101.1,53,100.9,53.2,100.9,53.5z"
        />
        <path
          className="st10"
          d="M105.2,51.6c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.2,0-0.3c-0.4-0.4,0-0.7,0-1.1c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.2-0.1,0.5-0.2,0.7C105.1,51.2,104.9,51.5,105.2,51.6z"
        />
        <path
          className="st15"
          d="M103.5,51.8c-0.3-0.1-0.4-0.3-0.5-0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.3,0,0.6,0,0.9c0,0,0,0,0,0
		c0.2,0,0.4,0,0.6,0.1C103.5,52.2,103.5,52,103.5,51.8z"
        />
        <polygon
          className="st15"
          points="103.5,52.4 103.5,52.4 103.5,52.4 103.5,52.4 	"
        />
        <path
          className="st15"
          d="M100.6,58.7c0-0.4-0.1-0.7-0.4-1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2,0,0.4-0.1,0.6
		C100.2,58.6,100.4,58.7,100.6,58.7z"
        />
        <path
          className="st15"
          d="M99,58c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.2,0,0.2,0
		c0.1-0.1,0.3-0.1,0.4-0.2c0-0.2-0.1-0.3-0.2-0.4C99.3,58,99.1,58,99,58C99,58.1,99,58.1,99,58C99,58.1,99,58,99,58z"
        />
        <path
          className="st15"
          d="M104.3,52.5c-0.3,0-0.5,0-0.8-0.1C103.7,53,103.7,53,104.3,52.5z"
        />
        <polygon className="st15" points="103.5,52.4 103.5,52.4 103.5,52.4 	" />
        <path
          className="st9"
          d="M104.1,59.8c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0h0C104,59.8,104,59.8,104.1,59.8C104.1,59.8,104.1,59.8,104.1,59.8
		z"
        />
        <path
          className="st10"
          d="M100,55c-0.5,0.2-0.4,0.3-0.2,0.5c0.1,0.1,0.1,0.1,0.2,0.2C100.4,55.4,100.3,55.2,100,55z"
        />
        <path
          className="st10"
          d="M100.9,55.7c0,0.1,0.1,0.3,0.1,0.4c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3C101,55.6,101,55.7,100.9,55.7z"
        />
        <polygon className="st9" points="103.1,58.5 103.2,58.5 103.2,58.4 	" />
        <path className="st17" d="M102.8,52.9c-0.1,0.1-0.3,0.1-0.4,0.2" />
        <line className="st17" x1="102.4" y1="53.1" x2="102.4" y2="53.1" />
        <line className="st17" x1="102.4" y1="53.1" x2="102.4" y2="53.1" />
        <line className="st17" x1="102.8" y1="52.9" x2="102.8" y2="52.9" />
        <path
          className="st10"
          d="M102.2,53.9c-0.1,0-0.1,0-0.2,0C102.1,54,102.1,54,102.2,53.9z"
        />
        <path
          className="st10"
          d="M83.1,47.2c0,0,0,0-0.1,0c0,0-0.1,0-0.2,0C82.9,47.2,83,47.2,83.1,47.2c0.1,0,0.1,0,0.2,0
		C83.2,47.2,83.1,47.2,83.1,47.2z"
        />
        <path
          className="st10"
          d="M84.5,47.2C84.5,47.2,84.5,47.2,84.5,47.2C84.4,47.2,84.5,47.3,84.5,47.2L84.5,47.2z"
        />
        <path
          className="st10"
          d="M85,47.3c-0.1,0-0.2-0.1-0.2,0C84.9,47.3,85,47.3,85,47.3C85,47.3,85,47.3,85,47.3z"
        />
        <path
          className="st10"
          d="M84.9,47.3C84.9,47.3,84.9,47.3,84.9,47.3c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0C84.6,47.3,84.7,47.3,84.9,47.3z"
        />
        <path
          className="st10"
          d="M84.5,46.8c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0C84.7,46.8,84.6,46.8,84.5,46.8z"
        />
        <path
          className="st10"
          d="M6.5,53.4c-0.1,0.1-0.1,0.2,0,0.2c0.1,0,0.2,0,0.3,0.1c0.1,0,0.3,0,0.5,0c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1
		C7,53.4,6.8,53.4,6.5,53.4z"
        />
        <path
          className="st5"
          d="M3.2,55.9C3.3,55.9,3.3,55.9,3.2,55.9c0.6,0,1.1-0.1,1.6,0c0.2,0,0.4,0,0.6,0c-0.1-0.2-0.2-0.3-0.4-0.4
		c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0-0.3-0.4c0-0.2-0.1-0.3-0.1-0.5C4,54.9,4,54.9,3.9,55
		c-0.1,0.1-0.2,0.1-0.3,0.1C3.6,55.4,3.4,55.6,3.2,55.9z"
        />
        <path
          className="st5"
          d="M5.1,56.1c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1C5.3,56.1,5.2,56.1,5.1,56.1z"
        />
        <path
          className="st13"
          d="M6.5,57.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.1-0.4,0.2c0,0,0,0,0,0
		c0.1,0.2,0,0.3-0.1,0.4c-0.1,0-0.1,0-0.2,0.1c-0.1,0.2,0,0.2,0.4,0.2c0.4,0,0.8-0.1,1.2,0c0-0.3,0-0.6,0-1
		C6.6,57.4,6.5,57.4,6.5,57.4z"
        />
        <path
          className="st11"
          d="M6.5,53.6c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0C6.7,53.7,6.6,53.6,6.5,53.6z"
        />
        <path
          className="st11"
          d="M6,55.2c-0.1,0.2-0.1,0.4-0.2,0.7c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0.2-0.8,0.7-1.2,1.3-1.7
		c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.8,0.2-1.2,0.2C6,54.6,6.1,54.9,6,55.2z"
        />
        <path
          className="st11"
          d="M5.8,56C5.8,56.1,5.8,56.1,5.8,56c0,0,0.1,0,0.2,0C5.9,56,5.9,56,5.8,56z"
        />
        <path
          className="st9"
          d="M5.5,56.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0-0.1C5.7,56,5.6,56.1,5.5,56.1z"
        />
        <path
          className="st9"
          d="M5,55.4C5,55.4,5,55.4,5,55.4c0.2,0.1,0.3,0.3,0.4,0.4c0.2,0,0.3,0,0.5,0c0.1-0.2,0.1-0.4,0.2-0.7
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.3c-0.2,0-0.4,0.1-0.5,0.1c0,0,0,0,0,0
		l0.1,0.1c0,0-0.1,0-0.1,0C4.7,55,5.1,55.2,5,55.4z"
        />
        <path
          className="st2"
          d="M5.3,57.8C5.3,57.8,5.3,57.8,5.3,57.8C5.3,57.8,5.3,57.8,5.3,57.8C5.3,57.8,5.3,57.8,5.3,57.8
		C5.3,57.8,5.3,57.8,5.3,57.8z"
        />
        <path
          className="st10"
          d="M5.6,54.8c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1-0.3,0-0.6-0.1-0.9c-0.2,0-0.4,0.1-0.6,0.1
		C5.3,54.6,5.4,54.7,5.6,54.8L5.6,54.8z"
        />
        <path
          className="st16"
          d="M110.2,50.2C110.2,50.2,110.2,50.3,110.2,50.2C110.2,50.3,110.3,50.3,110.2,50.2
		C110.3,50.2,110.3,50.2,110.2,50.2z"
        />
        <path
          className="st13"
          d="M11.4,58c-0.1,0.2-0.3,0.3-0.4,0.4c0.3,0,0.5,0,0.8,0c-0.1-0.1-0.2-0.2-0.3-0.3C11.4,58.1,11.4,58,11.4,58
		L11.4,58L11.4,58z"
        />
        <path
          className="st13"
          d="M10.1,58c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.2,0,0.4,0,0.7,0
		C10.4,58.3,10.2,58.2,10.1,58z"
        />
        <path
          className="st13"
          d="M11.8,59.1c0.1-0.2,0.1-0.4,0.1-0.6c-0.1,0-0.2,0-0.3,0h-0.1h-0.1h-0.1c-0.1,0.1-0.1,0.1-0.2,0.1
		c0,0-0.1,0-0.2-0.1c-0.2,0.1-0.4,0.1-0.7,0.1h-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0
		c0.2,0,0.5,0,0.7,0C11,58.8,11,58.9,11,59.1c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.1c-0.2,0.2,0.1,0.2,0.3,0.2c0.1,0,0.2,0,0.3,0
		c-0.3,0-0.5,0-0.7,0.1s-0.1,0.2,0.1,0.3c0.3,0,0.5,0,0.8,0C11.8,59.8,12,59.4,11.8,59.1z"
        />
        <path
          className="st13"
          d="M21,60C21.1,60,21.1,60,21,60c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.7,0c-0.2-0.1-0.4-0.1-0.6-0.3
		C21.5,59.8,21.2,59.7,21,60z"
        />
        <path
          className="st8"
          d="M11.8,51.9C11.8,51.9,11.8,51.9,11.8,51.9c0.1,0,0.2,0,0.4,0C12.1,51.9,11.9,51.8,11.8,51.9z"
        />
        <path
          className="st8"
          d="M11.8,52.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.3,0.4-0.4,0.7c0,0.1-0.1,0.1-0.1,0.2
		c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.4,0,0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.4-0.3
		c0.1,0,0.2-0.1,0.4,0c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c-0.3-0.4-0.3-0.9,0-1.3c0-0.2,0-0.4-0.1-0.7c-0.2,0.1-0.4,0-0.6-0.1
		c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.1,0.1-0.2,0.3c0.1,0.1,0.2,0.3,0.2,0.4C11.8,52.7,11.8,52.8,11.8,52.8z"
        />
        <path
          className="st10"
          d="M8.5,53.8c0.2,0,0.4,0,0.6,0c0-0.1,0-0.2,0-0.3C8.7,53.6,8.6,53.7,8.5,53.8z"
        />
        <path
          className="st10"
          d="M8.8,54.6c0.3,0.2,0.6,0.4,0.6,0.8h0c0,0,0,0,0,0c0.4,0,0.9-0.2,1.1,0c0.1,0.1,0.1,0.4-0.2,0.9
		c-0.2,0.3,0.3,0.4,0.6,0.5c0.3-0.1,0.3-0.3,0.3-0.5c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.3-0.3c0-0.2,0-0.4,0-0.7
		c-0.3,0.1-0.6,0.2-0.9,0.1c-0.2-0.1-0.4-0.3-0.6-0.7c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
		c-0.2,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1C8.8,54,8.6,54,8.3,54
		C8.3,54.2,8.4,54.4,8.8,54.6z"
        />
        <path
          className="st11"
          d="M19.8,56.5c-0.1,0.1-0.1,0.3-0.1,0.5c0.2,0.2,0.2,0.4,0.2,0.6c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1
		c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.5,0.6-0.4c0-0.2-0.1-0.4-0.4-0.3c-0.7,0.1-0.3-0.3-0.2-0.5c0.1-0.1,0.3-0.3,0.4-0.4
		c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.5-0.2
		c-0.3,0.3-0.7,0.2-0.9,0.6C20,55.8,20.4,55.9,19.8,56.5z"
        />
        <path
          className="st11"
          d="M9.9,58C9.9,58,9.9,58,9.9,58c0.1,0,0.2,0,0.2,0c0-0.5,0.3-0.8,0.8-0.9c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0
		c0,0,0-0.1,0-0.1c0,0,0,0,0,0c-0.3-0.1-0.7-0.1-0.6-0.5c0.3-0.5,0.3-0.8,0.2-0.9c-0.2-0.2-0.7,0-1.1,0c0,0,0,0,0,0
		c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0.1,0,0.1,0,0.2,0c0,0-0.1,0.1-0.2,0.1c0,0.1,0,0.1,0,0.2
		c0.2,0.1,0.4,0.2,0.7,0.3c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0.1c0,0,0,0,0,0c0.4,0,0.7,0,1.1,0v0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0
		c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1
		C9.5,57.4,9.6,57.7,9.9,58z"
        />
        <path
          className="st12"
          d="M13.2,54.2c0.1,0.2,0.5,0.2,0.6,0.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.4c-0.1-0.3-0.1-0.6-0.2-0.8
		c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c-0.1,0-0.3,0-0.4,0c-0.1,0.5-0.7,0.8-0.9,1.3C13.1,54.1,13.1,54.1,13.2,54.2z"
        />
        <path
          className="st10"
          d="M11.6,57.3c0.6-0.1,0.9-0.3,1.1-0.7c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6
		C11.7,55.4,12,56.5,11.6,57.3z"
        />
        <polygon
          className="st10"
          points="13.1,55.4 13.1,55.5 13.1,55.5 13.1,55.5 	"
        />
        <path
          className="st12"
          d="M14.2,58.9c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.2-0.1
		c0.1-0.1,0.1-0.1,0.1-0.3c-0.1-0.5-0.5-0.1-0.8,0c-0.1,0.1-0.2,0.1-0.2,0c-0.4,0-0.6-0.3-0.8-0.5c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.2,0-0.4,0-0.7,0c0,0.1,0,0.1,0,0.2c0.3,0.3,0.6,0.6,0.9,0.9C14,58.9,14.1,58.9,14.2,58.9z"
        />
        <path
          className="st12"
          d="M21.7,58.9C21.7,58.9,21.7,58.9,21.7,58.9C21.7,58.9,21.7,58.9,21.7,58.9L21.7,58.9C21,59,20.4,59,19.8,59.1
		c0,0.1,0,0.1,0,0.2c0.1,0.2,0.2,0.3,0.4,0.3c0.3,0.2,0.6,0.2,0.9,0.3c0.2-0.2,0.4-0.2,0.7-0.2C21.7,59.5,21.7,59.2,21.7,58.9z"
        />
        <path
          className="st8"
          d="M14.9,54.8c-0.5,0.6-1.1,0.9-1,1.6c0,0.3,0.5,0.2,0.7,0.1C15.2,56.1,15,55.5,14.9,54.8z"
        />
        <path
          className="st9"
          d="M15.3,53.6c0-0.1,0-0.2,0-0.3c0.1-0.2,0.3-0.3,0.6-0.2c0.1,0,0.2,0,0.3,0c0.2-0.1,0.5,0,0.5-0.3
		c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.4,0-0.6-0.1c-0.4-0.1-0.6,0.1-0.9,0.3c-0.1,0.1-0.3,0.3-0.4,0.3c0.1,0.3,0.1,0.6,0.2,0.8
		C15.2,54.1,15.3,53.9,15.3,53.6z"
        />
        <path
          className="st10"
          d="M10.5,58.4c0.2,0,0.3,0,0.5,0c0.1-0.1,0.3-0.2,0.4-0.4v0c-0.1-0.3-0.3-0.6-0.4-0.9c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.5,0.1-0.8,0.3-0.8,0.9C10.2,58.2,10.4,58.3,10.5,58.4z"
        />
        <path
          className="st12"
          d="M20.7,57.1c0.3,0,0.3,0.1,0.4,0.3c0.4-0.1,0.9-0.2,0.8-0.6c-0.1-0.5-0.6-0.2-1-0.3c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0-0.3,0h0C20.3,56.8,20,57.2,20.7,57.1z"
        />
        <path
          className="st11"
          d="M12.7,59.9c0.1-0.3,0.1-0.4,0-0.6c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4,0
		c0.2,0.3,0.1,0.7,0.1,1c0.2,0,0.4,0,0.6,0C12.5,60,12.6,60,12.7,59.9z"
        />
        <path
          className="st12"
          d="M21.7,58.5c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0.5-0.1,0.7,0.6,1.2,0.5c-0.1-0.5-0.6-0.7-0.6-1.2
		c-0.1,0-0.3,0-0.4,0C21.8,58.3,21.8,58.4,21.7,58.5z"
        />
        <path
          className="st10"
          d="M13,58.5c0.1,0.1,0.1,0.1,0.2,0.1c-0.2,0-0.4,0-0.6,0c0,0,0,0.1,0,0.1c0.1,0.3,0.5-0.1,0.7,0.2
		c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c-0.3-0.3-0.6-0.6-0.9-0.9c-0.2,0.1-0.3,0.2-0.4,0.4c0.1,0,0.1,0,0.2,0L13,58.5z"
        />
        <path
          className="st13"
          d="M16.4,58.9c0,0,0.2,0.2,0.2,0.1c0.4-0.1,0.8-0.2,0.6-1C16.9,58.4,16.4,58.4,16.4,58.9z"
        />
        <path
          className="st3"
          d="M22.8,55.2c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.3,0.2,0.4c0,0,0.1,0.1,0.2,0.1C23.6,55.3,23.5,55.2,22.8,55.2z"
        />
        <path
          className="st10"
          d="M12.9,54.8c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.5-0.2c-0.1-0.2-0.4-0.2-0.6-0.4c0-0.1-0.1-0.1-0.1-0.2
		c-0.1,0-0.1,0-0.2,0c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.3,0,0.4C12.7,54.8,12.8,54.8,12.9,54.8z"
        />
        <path
          className="st12"
          d="M19.8,57c-0.5,0.1-0.5,0.5-0.7,0.9c0.3-0.1,0.6-0.1,0.8-0.2c0.1,0,0.1,0,0.2,0h0C20,57.4,20,57.1,19.8,57z"
        />
        <path
          className="st10"
          d="M13.8,57.8c-0.1-0.3-0.1-0.6-0.2-0.8l0,0l0,0c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.4,0.4c0,0.2,0,0.3,0,0.5
		C13.3,57.8,13.5,57.8,13.8,57.8z"
        />
        <path
          className="st13"
          d="M18.2,58.5c-0.1,0-0.3,0-0.3,0.2c0,0.2,0.1,0.3,0.4,0.3c0.1-0.1,0.2-0.1,0.2-0.3
		C18.6,58.5,18.4,58.5,18.2,58.5z"
        />
        <path
          className="st12"
          d="M22.5,57.3c0,0.1,0,0.1,0,0.1c0.1,0.1,0.2,0.1,0.3,0.2C23,57.5,23,57.2,23,57C22.8,57,22.5,57,22.5,57.3z"
        />
        <path
          className="st15"
          d="M14.2,58.9c0,0.2,0.1,0.4,0.3,0.5c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.2,0.3-0.4v0C14.7,58.9,14.4,58.9,14.2,58.9z
		"
        />
        <path
          className="st12"
          d="M12.5,60.1c0.1,0,0.1,0,0.2,0c0,0,0-0.1,0-0.1C12.6,60,12.5,60,12.5,60.1z"
        />
        <path
          className="st13"
          d="M19.1,59.8c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0-0.1
		C19.2,59.9,19.1,59.8,19.1,59.8z"
        />
        <path
          className="st13"
          d="M19.3,59.1c0,0.1,0,0.1,0,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0-0.1,0-0.1,0-0.2C19.6,59,19.3,58.8,19.3,59.1z"
        />
        <path
          className="st13"
          d="M19.1,55.7c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.3,0.2-0.2,0.3
		C18.9,55.6,19,55.7,19.1,55.7z"
        />
        <path
          className="st13"
          d="M18.2,56.9c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0,0.1,0.1,0.2,0.1
		C18,57.1,18.1,57,18.2,56.9z"
        />
        <path
          className="st8"
          d="M14.9,56.9c0,0-0.1,0.1,0,0.2c0,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.1,0.1-0.2C15.1,56.9,15,56.9,14.9,56.9z"
        />
        <path
          className="st12"
          d="M14.3,59.9c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0C14.4,59.9,14.4,59.9,14.3,59.9
		C14.3,59.8,14.3,59.9,14.3,59.9z"
        />
        <path
          className="st11"
          d="M21.9,58.2c-0.1-0.1-0.2-0.2-0.3,0c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1C21.8,58.4,21.8,58.3,21.9,58.2z"
        />
        <path
          className="st12"
          d="M13.2,59.1c0.2,0.1,0.3-0.1,0.3-0.2c-0.1,0-0.1,0-0.2,0C13.3,59,13.2,59,13.2,59.1z"
        />
        <path
          className="st10"
          d="M13.5,57L13.5,57c0.1,0,0.1-0.1,0.2-0.1C13.7,56.9,13.6,56.9,13.5,57C13.5,56.9,13.5,57,13.5,57z"
        />
        <path
          className="st11"
          d="M15,58.5c-0.1,0-0.2,0-0.2,0C14.9,58.6,15,58.6,15,58.5z"
        />
        <path
          className="st10"
          d="M10.9,56.9c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0-0.1
		C11,57,11,57,10.9,56.9z"
        />
        <path className="st14" d="M20.8,56.1c0.1-0.1,0.1-0.1,0.2-0.2" />
        <path
          className="st10"
          d="M13.1,55.5C13.1,55.4,13.1,55.4,13.1,55.5C13.1,55.4,13.1,55.4,13.1,55.5C13.1,55.4,13.1,55.5,13.1,55.5z"
        />
        <path
          className="st11"
          d="M16.5,52.7c0,0,0.1,0.1,0.1,0.2c0.1,0.3-0.3,0.2-0.5,0.3c0.1,0.3,0.4,0.4,0.7,0.4c0.4,0,1-0.6,1.2,0.3
		c0,0.1,0.4,0.1,0.5-0.2c0.2-0.4-0.6-1.8-1.1-1.9c-0.6-0.1-1.2-0.2-1.8-0.2c0.1,0.3,0.2,0.7,0.2,1C16.1,52.7,16.3,52.6,16.5,52.7z"
        />
        <path
          className="st13"
          d="M14.4,52.2C14.4,52.2,14.4,52.2,14.4,52.2L14.4,52.2L14.4,52.2c0,0.2,0,0.4,0,0.5c0,0.1,0,0.1,0,0.2
		c0,0.2,0.1,0.3,0.2,0.5c0.2-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.5-0.4,0.9-0.3c-0.1-0.3-0.2-0.7-0.2-1c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.3,0-0.7,0.3-0.8,0.1c0-0.1-0.1-0.2,0-0.5c0-0.1,0-0.2,0-0.3h-0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0c0,0,0,0,0,0
		c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.1,0.2,0,0.5-0.1,0.7c0,0.1-0.1,0.1-0.3,0.2c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0
		c0,0,0,0,0,0.1C13.8,52.1,14.1,52.2,14.4,52.2C14.4,52.2,14.4,52.2,14.4,52.2z"
        />
        <path
          className="st10"
          d="M15.2,50.7c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0C15,50.6,15.1,50.7,15.2,50.7
		C15.2,50.7,15.2,50.7,15.2,50.7z"
        />
        <path
          className="st10"
          d="M17.9,49.1c-0.1,0-0.2,0-0.4,0c0,0,0,0.1-0.1,0.1h0c0.1,0,0.1,0,0.2,0h0.1h0.1c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1
		C18,49.1,17.9,49.1,17.9,49.1z"
        />
        <path
          className="st10"
          d="M16.2,50.1c-0.5,0-1,0-1.5,0c0,0,0,0-0.1,0h-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0
		c0,0.1,0,0.1-0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.4,0,0.8,0,1.3,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0.1,0,0.2,0c0.3,0,0.6,0,0.8,0
		c0.2-0.1,0.4-0.2,0.6-0.3C16.7,50.1,16.5,50.1,16.2,50.1z"
        />
        <path
          className="st11"
          d="M20.2,51.9c-1-0.3-1-0.3-1.7,0.2C19.9,52.2,19.9,52.2,20.2,51.9z"
        />
        <path
          className="st9"
          d="M15.7,51.3C15.7,51.3,15.7,51.3,15.7,51.3C15.7,51.3,15.7,51.3,15.7,51.3c0.1-0.1,0.2-0.2,0.3-0.4
		c0.1-0.1,0.1-0.2,0.1-0.4c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0
		c-0.1,0-0.1,0-0.1,0h0c0,0.1,0,0.2,0,0.3C15.1,51,15.4,51.1,15.7,51.3z"
        />
        <path
          className="st13"
          d="M23.8,58C23.8,58,23.8,58,23.8,58L23.8,58C23.8,58,23.8,58,23.8,58z"
        />
        <path
          className="st3"
          d="M19.5,48.1L19.5,48.1C19.3,48.1,19,48,18.8,48c-0.4,0-0.7,0.1-0.9,0.4c0,0,0,0,0,0c0,0,0,0,0.1,0
		c0.1,0,0.4-0.1,0.5-0.1h0.1c0.2,0.1,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0C19.5,48.2,19.5,48.2,19.5,48.1z"
        />
        <path
          className="st3"
          d="M19.8,48.1L19.8,48.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1C19.9,48.1,19.8,48.1,19.8,48.1z"
        />
        <path
          className="st3"
          d="M27.6,60.2c0.2,0,0.4-0.1,0.5,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.4-0.6-1-0.2-1.5-0.2c-0.1,0.2-0.2,0.1-0.4,0.2
		c-0.1,0-0.2,0-0.2,0.1c0,0,0.1,0,0.1,0c0.3,0,0.5,0,0.7,0c0.1,0.1,0.1,0.1-0.1,0.1c-0.1,0-0.4,0.1-0.5,0.1c0.3,0,0.5,0,0.8,0
		c0.1,0,0.2-0.1,0.4-0.1C27.4,60.2,27.5,60.2,27.6,60.2z"
        />
        <path
          className="st3"
          d="M26.5,60.5C26.5,60.5,26.5,60.5,26.5,60.5c-0.1,0-0.1,0-0.2,0C26.3,60.5,26.4,60.5,26.5,60.5z"
        />
        <polygon className="st3" points="23,54.4 22.9,54.4 23,54.4 	" />
        <path
          className="st13"
          d="M13.7,48.9C13.7,48.9,13.8,48.9,13.7,48.9C13.8,48.9,13.8,48.9,13.7,48.9C13.7,48.9,13.7,48.9,13.7,48.9z"
        />
        <path
          className="st3"
          d="M30.7,53.1c-0.2,0-0.4,0.1-0.4,0.1c0,0.1,0,0.1,0,0.2c0.1,0.1,0.2,0.1,0.4,0.1C30.7,53.4,30.7,53.2,30.7,53.1z
		"
        />
        <path
          className="st3"
          d="M64,49c-0.6,0.4-0.6,0.8-0.5,1.2c0.1,0.2,0.3,0.5,0.4,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.1,0.6,0.1,0.9,0
		c0.4-0.1,0.7-0.3,1-0.5c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.7-0.5,1-0.7c0.2-0.3,0.4-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0.1
		c0.1,0.1,0.2,0.2,0.4,0.4c-0.9-0.4-0.6,1.1-1.5,0.9c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0.1-0.4,0.4c0.8-0.1,1.4,0,2,0.4
		c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.2,0.3,0.5c-0.2,0.4-0.6,0.4-1,0.1c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0,0.2
		c0,0.1,0,0.3,0,0.4c0.1,0.1,0.1,0.3,0.2,0.4l0,0c0.1,0.2,0.3,0.1,0.4,0c0.1-0.4,0.3-0.4,0.6-0.2c0.5,0.4-0.3,0.5-0.2,0.9
		c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0.1-0.1
		c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.4,0.8-0.5c0,0,0,0,0,0s0,0,0,0c0-0.1,0-0.3,0-0.4c0-0.2,0-0.3,0-0.5c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.4,0-0.4-0.3-0.4-0.6c-0.1,0-0.3,0-0.3-0.2c0,0,0-0.1,0.1-0.1c0.2,0,0.2,0.2,0.2,0.3l0,0c0.1,0,0.2,0,0.2,0.1
		C71,51.6,71,51.8,71,52c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.3-0.1,0.2-0.5,0.3-0.8c0.1-0.2,0.2-0.3,0.3-0.3
		c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1c-0.2-0.1-0.2-0.3,0-0.4c-0.3-0.5-0.6-0.5-0.9,0c-0.1,0.2-0.4,0.2-0.6,0.1
		c-0.2-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1,0.1-0.1c-0.5,0-0.7-0.1-0.8-0.3c-0.2-0.3,0-0.8-0.1-1.2c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.3-0.6,0.3-0.8,0c-0.2-0.4,0.2-0.3,0.4-0.4
		c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0-0.3,0h-0.1c-0.2,0-0.3,0-0.4,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7-0.1-1.1,0
		c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.3,0-0.5,0c0,0.1,0.2,0.1,0.4,0.1c0.3,0,0.5,0,0.8,0c-0.6,0.1-1.2,0.1-1.8,0.1c-0.1,0-0.1,0-0.2,0
		h-0.1c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2C64.2,48.9,64.1,49,64,49z M69.9,52.7L69.9,52.7L69.9,52.7
		L69.9,52.7z"
        />
        <path
          className="st3"
          d="M63.2,46.9c0.2,0,0.4,0,0.4-0.1C63.6,46.8,63.4,46.8,63.2,46.9C63.2,46.8,63.2,46.8,63.2,46.9
		C63.2,46.9,63.2,46.9,63.2,46.9z"
        />
        <polygon
          className="st3"
          points="66.2,50.9 66.3,50.9 66.3,50.9 66.2,50.9 	"
        />
        <path
          className="st3"
          d="M65.2,59.8c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0.1,0.2,0.5,0.5,0.4c0.1-0.2,0.2-0.5,0.2-0.7c0-0.1,0-0.2,0-0.3
		c0-0.2,0.1-0.3,0.3-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3
		c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.7,0.3-1,0.5c-0.1-0.3,0-0.6,0.1-0.9c0.1-0.3,0.2-0.5,0.2-0.8c0,0,0,0,0,0c-0.3,0-0.6,0-0.6-0.4
		c-0.3,0-0.6,0-0.9,0c-0.4,0.1-0.5-0.1-0.9-0.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0.2-0.1,0.4-0.2,0.6l0,0c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.2,0.3,0.4c0,0.4-0.5,0.4-0.9,0.5c-0.3,0.1-0.6,0.2-0.9,0.3
		c0.1-0.7,1.1-0.6,1-1.3c-0.8-0.2-0.1-0.9-0.4-1.3c-0.2-0.1-0.4-0.1-0.6-0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.3,0,0.7-0.1,1
		c-0.1,0.3-0.2,0.6-0.5,0.9c0,0.1,0,0.1,0,0.2h0c0.4,0.2,0.6,0.5,0.7,0.9c0.1,0,0.3,0,0.4,0c0.1-0.4,0.3-0.5,0.6-0.2
		c0.4,0,0.4,0.3,0.4,0.6c0.6-0.1,1.1-0.6,1.9-0.1c-1.1,0.3-0.6,1-0.6,1.6c0.1,0,0.1,0,0.2,0C64.7,59.9,64.8,59.7,65.2,59.8z"
        />
        <path
          className="st4"
          d="M64.1,51.6c0-0.1,0-0.3,0-0.4C64,51.1,64,51,63.9,50.9c0,0,0,0,0,0c0,0,0,0,0,0c-1.2-0.5-1.4,1-2.2,1.2
		c0.2,0.3,0,0.3-0.3,0.3c-0.3,0-0.4-0.2-0.3-0.4c0-0.2,0.1-0.5-0.2-0.5c-0.4-0.1-0.4,0.2-0.5,0.4C60.2,52,60.1,52,60,52.1
		c1.2,0.6,2.3,1.2,3.6,0.7c0.1-0.1,0.1-0.1,0.2-0.2C63.5,52.2,64.2,52,64.1,51.6z"
        />
        <path
          className="st4"
          d="M48.6,57.4c-0.3,0-0.6,0-0.9,0c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.4-0.3,0.5c-0.6,0.3-0.3,0.6-0.2,1
		c0.2,0,0.4,0,0.6,0c0.5,0,1,0,1.4,0c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.3,0.2-0.5C49.5,58.3,49,57.9,48.6,57.4z"
        />
        <polygon className="st4" points="50.1,58.5 50.1,58.5 50.1,58.5 	" />
        <path
          className="st5"
          d="M48.6,57.4c0-0.7,0.5-0.9,1.1-1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.3,0.2-0.4c-0.1-0.3-0.3-0.2-0.4-0.1
		c-0.5,0.4-1,0.2-1.5,0c-0.2-0.1-0.4-0.5-0.8-0.1c-0.5,0.5,0.1,1.1-0.2,1.6c0,0,0,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0
		c0.1,0,0.2,0,0.2,0.2C48,57.4,48.3,57.4,48.6,57.4z M47.8,56.4L47.8,56.4L47.8,56.4L47.8,56.4z"
        />
        <path
          className="st13"
          d="M44.6,55.9c-0.3-1.1-1-0.7-1.7-0.5c-0.2,0.1-0.5,0.1-0.4,0.3c0,0.3,0.3,0.3,0.5,0.3c0.5-0.2,1,0.1,1.4,0.3
		c0.9,0.7,0.9,0.7,1.5-0.2C44.8,56.2,44.7,56.2,44.6,55.9z"
        />
        <path
          className="st5"
          d="M62.2,54.8c0.4,0,0.5,0.4,0.7,0.6h0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0
		c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.3-0.4l0,0c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1,0-0.2,0-0.2,0
		c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.2C63,54.5,63,54.3,63,54.2c0-0.1,0-0.3,0-0.4c-0.5-0.1-1,0.3-1.5,0.3
		c-0.2,0-0.1,0.2-0.1,0.4c0,0.1,0,0.1,0.1,0.2C61.7,54.7,61.9,54.7,62.2,54.8z"
        />
        <path
          className="st4"
          d="M60.9,57.2c-0.6,0.1-0.7,0.1-0.6-0.4c0-0.1,0.1-0.1,0-0.2c-0.2-0.2-2.2-0.3-2.4-0.1c-0.3,0.2-0.1,0.3,0.1,0.5
		c0.5,0.4,1.2,0,1.6,0.5c0.3,0.4,0.6,0.4,0.9,0.2c0.1-0.1,0.2-0.1,0.3-0.2C60.9,57.3,60.9,57.2,60.9,57.2z"
        />
        <path
          className="st13"
          d="M63.2,46.8c-0.1,0-0.1,0-0.2,0C63.1,46.8,63.2,46.8,63.2,46.8C63.2,46.8,63.2,46.8,63.2,46.8z"
        />
        <path
          className="st4"
          d="M59.2,50.5c0.2,0.1,0.4,0.3,0.5,0c0.1-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.4-0.5c0.1-0.5-0.3-0.4-0.6-0.4
		c-0.3,0.1-0.5,0.3-0.2,0.6c0.4,0.4,0.1,0.7-0.2,1C58.6,50.9,58.8,50.2,59.2,50.5z"
        />
        <path
          className="st5"
          d="M62.7,48.5c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0
		c-0.1,0-0.1,0-0.2,0C63.1,48.4,63,48.3,62.7,48.5c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
		c0,0.1-0.1,0.1-0.1,0.2C62.1,48.5,62.4,48.5,62.7,48.5z"
        />
        <path
          className="st5"
          d="M66,53.7c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c-0.7-0.4-1.3-0.9-2.1-0.7c-0.1,0.1-0.1,0.1-0.2,0.2
		c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.1,0.9-0.4,0.9,0.4c0,0.1,0,0.3,0,0.4c0.2,0,0.4,0,0.6,0C65.6,53.9,65.8,53.8,66,53.7z"
        />
        <path
          className="st4"
          d="M56.6,50.7L56.6,50.7c0,0.6-0.9,0.3-0.9,0.9c0.1,0,0.3,0,0.4,0.1c0.7,0.9,0.9,0.5,1-0.3c0-0.2,0.2-0.4,0.2-0.6
		c0.1-0.4-0.1-0.4-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.1C56.7,50.6,56.6,50.7,56.6,50.7z"
        />
        <path
          className="st13"
          d="M59.2,52.9c0.2,0.4,0.3,0.6,0.6,0.6c0.1,0,0.1,0,0.2,0c0.2,0,0.6-0.2,1.1-0.4c-0.7-0.1-1.1-0.7-1.7-0.7
		C59.3,52.6,59.2,52.7,59.2,52.9z"
        />
        <path
          className="st5"
          d="M50.7,57.5c-0.1-0.1-0.1-0.3-0.1-0.5c-0.1,0-0.1,0-0.2,0c-0.2,0.5-0.5,0.9-0.2,1.5c0.1,0,0.2,0,0.3,0
		c0.3,0,0.6,0.1,0.7-0.2C51.3,57.8,50.8,57.7,50.7,57.5z"
        />
        <polygon
          className="st5"
          points="50.1,58.5 50.1,58.5 50.1,58.5 50.1,58.5 50.1,58.5 	"
        />
        <path
          className="st3"
          d="M63.5,54.4c0.1,0,0.2,0,0.2,0c0.2,0.1,0.4,0.3,0.6,0.4c0.1-0.3,0.3-0.4,0.4-0.4c0,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0-0.1,0-0.3,0-0.4c-0.2,0-0.4,0-0.6,0c-0.6,0.5-1.1-0.4-1.7-0.2c0,0.1,0,0.3,0,0.4
		C63.2,54.2,63.3,54.3,63.5,54.4z"
        />
        <path
          className="st4"
          d="M41.4,55.2c0.1-0.2-0.1-0.3-0.2-0.4c-0.2-0.2-0.5-0.3-0.8-0.1c-0.2,0.2-0.3,0.4-0.4,0.7c0.2,0,0.5,0,0.7,0
		C41,55.4,41.3,55.5,41.4,55.2z"
        />
        <polygon className="st4" points="40,55.4 40,55.4 40,55.5 	" />
        <path
          className="st5"
          d="M43.9,58.5c0.5-0.1,0.9-0.4,1.5-0.4C44.5,57.2,44.2,57.3,43.9,58.5z"
        />
        <polygon className="st5" points="43.9,58.5 43.9,58.5 43.9,58.5 	" />
        <path
          className="st3"
          d="M59.3,48.4c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.1,0,0.2-0.1v0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0.1-0.2,0.1
		c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.4,0c0,0,0.1,0.1,0.1,0.1C59.1,48.4,59.2,48.4,59.3,48.4z"
        />
        <path
          className="st3"
          d="M57.1,58.9c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.6,0.2-1.1,0.6c0.7,0,1.2,0,1.7,0c0,0,0,0,0,0
		C57.3,59.1,57.2,59,57.1,58.9z"
        />
        <path
          className="st4"
          d="M60.2,54.2c-0.4,0.4,0,0.8,0.2,1.3c0.2-0.6,0.3-1,0-1.4C60.5,54.1,60.3,54.1,60.2,54.2z"
        />
        <path
          className="st13"
          d="M47.3,59.1c-0.2,0.2-0.4,0.4-0.6,0.6c0.3,0.1,0.6,0.2,0.8,0.2c0.2-0.1,0.4-0.3,0.4-0.7l0,0
		C47.7,59.1,47.5,59.1,47.3,59.1z"
        />
        <path
          className="st4"
          d="M59.4,52.5c-0.1-0.6-0.5-0.2-0.7-0.1c-0.1,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.1,0.1,0.2s0.1,0,0.2,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0C59.2,52.7,59.3,52.6,59.4,52.5z"
        />
        <path
          className="st5"
          d="M48.6,54.8c0,0.2,0.2,0.4,0.6,0.4l0,0h0c0,0,0,0,0,0c0-0.5-0.2-0.9-0.7-1.3c0,0.1,0,0.2,0,0.2
		c0,0.2,0,0.3,0,0.4C48.6,54.7,48.6,54.7,48.6,54.8z"
        />
        <path
          className="st3"
          d="M57.2,48.5c0.1,0,0.2-0.1,0.3-0.2c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1
		c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0.1,0,0.1-0.1,0.2
		C56.8,48.4,57,48.5,57.2,48.5z"
        />
        <path
          className="st3"
          d="M58.1,59.3c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0,0.3,0,0.4,0
		C58.1,59.9,58,59.6,58.1,59.3z"
        />
        <path
          className="st13"
          d="M49,59.5c0,0.2,0,0.4,0,0.6c0.2,0,0.5,0,0.7,0c0,0,0.1-0.1,0.1-0.2C49.8,59.4,49.3,59.6,49,59.5z"
        />
        <path
          className="st4"
          d="M58.9,48.4c-0.3,0.1-0.7,0.2-1,0.3c0.3,0,0.5,0.1,0.8,0.2c0.1,0,0.2,0,0.4-0.1s0.2-0.2,0.3-0.4
		C59.2,48.4,59.1,48.4,58.9,48.4z"
        />
        <path
          className="st4"
          d="M48.3,59.9c0,0.1,0,0.1,0.1,0.2c0.2,0,0.4,0,0.6,0c0-0.2,0-0.4,0-0.6C48.7,59.6,48.3,59.5,48.3,59.9z"
        />
        <path
          className="st3"
          d="M63.9,53.1c0.1,0.3,0.2,0.4,0.3,0.4s0.3,0,0.5,0C64.8,52.7,64.2,53.2,63.9,53.1z"
        />
        <path
          className="st3"
          d="M51.4,55.9L51.4,55.9c0,0.1,0,0.3,0,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
		c0.1,0,0.2,0,0.2,0c0.2-0.1,0.1-0.3,0-0.4c0,0,0-0.1-0.1-0.1C51.7,55.9,51.5,55.9,51.4,55.9z"
        />
        <path
          className="st3"
          d="M55.7,50.7c0-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3s0,0.3,0.1,0.4C55.4,51,55.6,50.9,55.7,50.7z"
        />
        <path
          className="st5"
          d="M51.4,55.9c-0.2,0-0.6-0.1-0.6,0.1c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.4,0.1,0.6,0.1C51.4,56.2,51.4,56,51.4,55.9z
		"
        />
        <path
          className="st3"
          d="M56.6,50.7c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c0,0-0.1,0-0.1,0c-0.3,0-0.2,0.3-0.2,0.5
		C56.3,50.7,56.4,50.7,56.6,50.7L56.6,50.7z"
        />
        <path
          className="st3"
          d="M50.8,56.7c0,0-0.1-0.1-0.1-0.1c-0.2,0.1-0.5,0-0.4,0.3c0.1,0,0.1,0,0.2,0C50.7,56.9,50.8,56.9,50.8,56.7z"
        />
        <path
          className="st4"
          d="M49.6,57.1c0,0.1,0,0.1,0.1,0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0C49.7,57.1,49.7,57.1,49.6,57.1
		C49.6,57.1,49.6,57.1,49.6,57.1z"
        />
        <path
          className="st13"
          d="M50.7,59.9C50.6,59.9,50.6,59.9,50.7,59.9L50.7,59.9c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0
		C50.6,60.1,50.6,60,50.7,59.9C50.7,60,50.7,60,50.7,59.9z"
        />
        <path
          className="st6"
          d="M56.6,50.7c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0-0.2,0-0.2,0h0C56.6,50.6,56.6,50.7,56.6,50.7z"
        />
        <path
          className="st5"
          d="M49.2,55.3L49.2,55.3C49.3,55.3,49.3,55.3,49.2,55.3C49.3,55.3,49.3,55.2,49.2,55.3
		C49.3,55.2,49.2,55.3,49.2,55.3z"
        />
        <path
          className="st12"
          d="M69.1,58.9c-0.4-0.2-0.6,0.3-0.9,0.6c0-0.3,0-0.5,0-0.7s0-0.5,0-0.7c-0.1-0.1-0.3-0.2-0.4-0.2
		c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.9,0.8,0.2,0.9
		c0,0,0,0,0-0.1c0,0,0,0.1,0,0.1l0,0c0,0.2,0,0.3-0.1,0.4c0.4,0,0.8,0,1.3,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0c0.3,0,0.5,0.1,0.8,0
		c0,0,0,0,0,0c0,0,0-0.1,0-0.1C69.8,59.6,69.5,59.1,69.1,58.9z"
        />
        <path
          className="st5"
          d="M68.7,60.2c0,0,0.1,0,0.1,0C68.8,60.2,68.8,60.2,68.7,60.2z"
        />
        <path
          className="st12"
          d="M66.5,60.2C66.5,60.2,66.5,60.2,66.5,60.2C66.5,60.2,66.5,60.2,66.5,60.2C66.5,60.2,66.5,60.2,66.5,60.2z"
        />
        <path
          className="st12"
          d="M66,60c-0.2,0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.2-0.1-0.4,0.2c0,0.2,0,0.3,0.1,0.4c0.1,0,0.3,0,0.4,0
		c0.1,0,0.3,0,0.4,0C66.1,60.1,66,60.1,66,60z"
        />
        <path
          className="st3"
          d="M67.6,59.8L67.6,59.8C67.6,59.8,67.5,59.7,67.6,59.8c-1.1,0-0.3-0.5-0.2-0.9c-0.1,0-0.3,0-0.4,0
		c-0.1,0.1-0.3,0.3-0.4,0.4c0,0.3,0,0.6,0,0.9c0,0,0,0,0,0c0.3,0,0.7,0,1,0C67.5,60.1,67.5,59.9,67.6,59.8L67.6,59.8z"
        />
        <path
          className="st3"
          d="M63.7,58.8c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0.2,0,0.1-0.1,0.1-0.2C63.8,58.9,63.7,58.8,63.7,58.8
		z"
        />
        <path
          className="st2"
          d="M62,58.2c0.2-0.1,0.4-0.1,0.6-0.2C62.3,57.7,62.1,57.8,62,58.2z"
        />
        <path
          className="st10"
          d="M86.3,58.9C86.3,58.9,86.3,58.9,86.3,58.9L86.3,58.9c-0.4,0-0.4,0.4-0.7,0.6c0.1-0.7-0.3-1.3,0.3-1.9
		c-0.4-0.1-0.7-0.3-1.1-0.4c-0.1,0.1-0.1,0.3-0.2,0.4l0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.3,0.3
		c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0-0.1,0-0.1,0
		c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0-0.2,0-0.2,0c-0.4-0.8-0.6-0.8-1.1-0.1c-0.2,0.2-0.5,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.2,0.3
		c0,0.1,0,0.1,0,0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.1,0.3l0,0c0.2,0.1,0.5-0.1,0.8-0.1s0.5,0.1,0.4,0.8
		c-0.2-0.1-0.5-0.1-0.7-0.2s-0.4-0.2-0.6-0.5c-0.2,0.1-0.3,0.2-0.5,0.3c0.1,0.2,0.1,0.4,0.2,0.5c0.7,0,1.4,0,2.1,0
		c0.3,0,0.5,0,0.7,0c0.1,0.1,0.2,0.1,0.3,0.1v-0.1c0.1,0,0.3,0,0.4,0c0,0,0.1,0.1,0.2,0.1v-0.1c0.2,0,0.3-0.1,0.5-0.1
		c0.1,0.1,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0c0.3,0,0.6,0,0.9,0c0-0.2-0.1-0.3-0.2-0.4C86.5,59.5,86.3,59.2,86.3,58.9z M85,58.6
		c-0.1-0.1-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2c0.1,0.1,0.2,0.1,0.2,0.2C85.1,58.4,85,58.5,85,58.6z"
        />
        <path
          className="st12"
          d="M80.9,59.8c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.6-0.2,0.7-0.5
		c0.5-0.7,0.7-0.6,1.1,0.1c0,0,0.1,0,0.2,0c0.1-0.2,0.3-0.5,0.4-0.7c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4-0.1-0.5
		c-0.1-0.2-0.2-0.3-0.4-0.4c-0.3,0-0.5,0-0.8,0.2c-1.1,0.7-2.3,1-3.5,0.5c-0.2-0.1-0.6,0-0.4-0.4c0.1-0.3,0.3-0.1,0.5,0
		c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3-0.1,0.4-0.2c0-0.2,0-0.4,0-0.6h0l0,0c-0.5,0-1.1,0.1-1.6-0.1c-0.2,0-0.3-0.1-0.5-0.2
		c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0.3,0.3,0.6-0.1,0.8c-0.4,0.2-0.3-0.2-0.4-0.4
		c-0.2-0.5,0-0.7,0.5-0.7c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.3-0.5-0.2-0.9c-0.1-0.2-0.2-0.3-0.2-0.5
		c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.6-0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.4-0.2,0.7-0.3,0.5-1
		c-0.1-0.4,0.4-0.7,0.8-0.5c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0
		c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.3-0.3-0.4-0.4c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.3-0.1-0.7-0.1-1-0.2c-0.3,0-0.6,0-0.7,0.4c-0.1,0.8-0.8,1-1.2,1.5c0.2,0.5,0.6-0.1,0.8,0.2c0,0.3-1.3,0.7-2,0.7
		c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.2-0.4,0.6-0.6,0.9c0,0.1,0,0.3,0,0.4c0.1,0.2,0.2,0.3,0.2,0.4
		c0,0.4-0.5,0.7-0.4,1.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0,0,0,0l0,0
		c0.1,0.1,0.2,0.1,0.3,0.2c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.7,0.1,1.4-0.6,1.9c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0
		c0.2,0,0.4,0,0.6,0c0.4,0,0.6,0.2,0.7,0.5c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0.5,0,1.1,0,1.6,0C77.6,60.1,77.3,60,77,60
		c0.7-0.6,1.2-0.6,1.5,0.3c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0.1,0s0.2,0,0.3,0c0.3,0,0.6,0,0.9,0
		C80.5,60.1,80.6,59.9,80.9,59.8z M75.8,53.9c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1
		c0.1-0.2,0.2-0.5,0.5-0.5c0.2,0,0.3,0.2,0.3,0.3C76.3,53.9,76.1,53.9,75.8,53.9z M77,57.7C77,57.7,76.9,57.6,77,57.7
		c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1C77.1,57.6,77,57.7,77,57.7z M80,59c-0.1-0.4-1-0.1-0.9-0.7
		c0-0.4,0.6-0.4,1-0.4c0.4,0,0.6,0.2,0.5,0.6C80.5,58.7,80.5,59.1,80,59z"
        />
        <path
          className="st9"
          d="M75.7,52.4c0.1,0,0.1,0,0.2,0c0.7,0,2-0.4,2-0.7c-0.2-0.3-0.6,0.2-0.8-0.2c0.4-0.5,1.1-0.7,1.2-1.5
		c0-0.4,0.4-0.4,0.7-0.4c0.3,0,0.7,0.1,1,0.2c0-0.1,0-0.3,0.2-0.3c0.2,0,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.2,0.4
		c0.2,0.2,0.2,0.3,0,0.4c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0.1,0,0.1,0,0.2,0
		c0,0,0,0,0.1,0c0,0,0,0,0,0h0c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0
		c0-0.3,0.3-0.6,0.4-0.9c0-0.2-0.1-0.4-0.4-0.6c-0.8,0.1-0.4-0.5-0.5-0.9c-0.1,0-0.1-0.1-0.2-0.1c0,0,0.1,0,0.1,0
		c0,0-0.1-0.1-0.1-0.1c-0.7,0.2-1.3,0.8-2.1,0.9c-0.2,0-0.5,0-0.8-0.1c0-0.3-0.2-0.5-0.4-0.7c0,0,0,0,0,0c-0.3,0-0.3,0.3-0.5,0.5
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.1c0,0.2-0.1,0.4-0.1,0.5c-0.2,0.5-0.4,1-0.2,1.6c0.1,0.3-0.3,0.4-0.6,0.4
		C75.9,52,75.8,52.2,75.7,52.4L75.7,52.4z M76.9,50.2c0.2-0.6,0.4-1,1.1-1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1,0.4-0.8,0.6-0.7,1.2c0,0.2-0.2,0.3-0.4,0.2C76.8,50.6,76.9,50.4,76.9,50.2z"
        />
        <path
          className="st5"
          d="M71,49.9c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3,0.1,0.4c0.2,0.1,0.4,0.1,0.6-0.1c0.3-0.6,0.6-0.5,0.9,0
		c0.3-0.6,0.2-1.2,0.4-1.8c0-0.1,0-0.1,0-0.2c-0.1,0-0.2,0-0.3,0h-0.1c-0.1,0-0.2,0-0.3,0C72.6,49.4,71.3,49.2,71,49.9z"
        />
        <path
          className="st10"
          d="M81.8,48.2c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.1-0.1
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0C81.7,48,81.7,48.1,81.8,48.2C81.7,48.2,81.8,48.2,81.8,48.2z"
        />
        <path
          className="st10"
          d="M81.7,48.5C81.7,48.5,81.7,48.5,81.7,48.5c-0.1,0.4-0.1,0.6-0.2,0.9c0.3,0.2,0.4,0.4,0.4,0.6
		c0,0.3-0.4,0.6-0.4,0.9c0.4,0.1,0.7-0.1,0.8-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.2,0.3,0.2c0.4,0,0.4-0.4,0.5-0.7
		c0.1-0.4,0.3-0.7,0.7-0.6c0-0.4-0.1-0.7-0.5-0.7c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.3-0.6,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0h0C81.7,48.4,81.7,48.4,81.7,48.5
		C81.7,48.5,81.7,48.5,81.7,48.5z"
        />
        <path
          className="st9"
          d="M76.6,47C76.6,47,76.6,47,76.6,47c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0C76.5,47,76.5,47,76.6,47z
		"
        />
        <path
          className="st9"
          d="M76.2,48.7c0.1,0.2,0.1,0.4,0.2,0.5c0.1-0.1,0.3-0.2,0.4-0.2c0,0,0,0,0,0c0-0.2,0-0.5,0-0.7
		c-0.3,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1C76,48.4,76.1,48.5,76.2,48.7z"
        />
        <path
          className="st12"
          d="M76,47C76,47,76,47,76,47C76.1,47,76.1,47,76,47C76,47,76,47,76,47z"
        />
        <path
          className="st13"
          d="M76.8,49C76.8,49,76.8,49,76.8,49c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0,0.3-0.1c0,0,0.1-0.1,0.1-0.1
		c0.1-0.2,0.2-0.4,0.5-0.5c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0
		C76.8,48.5,76.8,48.8,76.8,49C76.8,49,76.8,49,76.8,49z"
        />
        <path
          className="st15"
          d="M81.1,48.6C81.1,48.6,81.1,48.6,81.1,48.6c0,0.3-0.4,0.9,0.4,0.8c0-0.3,0.1-0.6,0.1-0.9c-0.1,0-0.2,0-0.3,0
		C81.4,48.6,81.2,48.6,81.1,48.6z"
        />
        <path
          className="st15"
          d="M81,48.5c0.2,0,0.4,0,0.6,0c0-0.1,0-0.1,0-0.2h-0.1h-0.1c0-0.1,0.1-0.1,0-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
		c0-0.1,0-0.2,0-0.3c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0c0,0.1,0,0.2,0,0.4C81,48.4,81,48.4,81,48.5z"
        />
        <path
          className="st9"
          d="M78.9,48.4c0,0,0.1,0.1,0.1,0.1c0.3,0.1,0.3-0.1,0.4-0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
		C78.9,48.2,78.9,48.3,78.9,48.4z"
        />
        <path
          className="st13"
          d="M71.8,46.5C71.7,46.5,71.7,46.6,71.8,46.5L71.8,46.5z"
        />
        <path
          className="st9"
          d="M94.9,47.1c0,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0,0C95,47,94.9,47,94.9,47.1z"
        />
        <path
          className="st9"
          d="M86.9,47.1C86.9,47.1,86.9,47.1,86.9,47.1C86.9,47.1,87,47.1,86.9,47.1z"
        />
        <path
          className="st9"
          d="M87.1,47.3C87.2,47.2,87.1,47.2,87.1,47.3L87.1,47.3z"
        />
        <path
          className="st9"
          d="M87,47.2C87,47.2,87,47.2,87,47.2C87,47.2,87,47.2,87,47.2z"
        />
        <path
          className="st9"
          d="M86.5,48.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.2,0.2c0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.1-0.4,0.2
		c0,0.5,0,0.8,0.2,0.9c0,0,0.1,0,0.1,0.1s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0s0.2,0,0.3-0.1c-0.2,0.6-0.6,0.7-1,0.6
		c-0.2,0-0.5-0.1-0.7-0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.3,0.2
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0.2,0.2,0.3,0,0.5,0c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0
		c0.2-0.3,0.4-0.6,0.5-0.9c0-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.3-0.5-0.8-0.1-1
		c0.5-0.2,0.7,0.4,0.7,0.8c0.5,0.3,0.8,0.3,0.9-0.4c0-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0-0.3
		c-0.2-0.5-0.7-0.3-1-0.3c-0.1,0-0.2,0-0.2,0l0,0c-0.1,0.1-0.1,0.1-0.2,0c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0
		c-0.1,0.1-0.3,0.1-0.4,0.1h-0.1c-0.2,0.1-0.1,0.1,0.1,0.1c0.1,0,0.4-0.1,0.5,0c-0.2,0-0.3,0-0.5,0c-0.6,0-1.2,0-1.8,0
		c0.1,0.1,0.2,0.1,0.3,0.1C86.4,48.8,86.4,48.8,86.5,48.8z"
        />
        <path
          className="st9"
          d="M87.4,46.7c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.1-0.6-0.1c0,0,0,0,0,0c-0.2,0.1-0.2,0.2-0.3,0.3
		c0.2,0,0.4,0,0.7,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1C87.1,46.9,87.2,46.8,87.4,46.7z"
        />
        <path
          className="st9"
          d="M87.1,47.3C87.1,47.3,87.1,47.3,87.1,47.3C87.1,47.3,87.1,47.2,87.1,47.3z"
        />
        <path
          className="st9"
          d="M86.7,47.1C86.7,47.1,86.7,47.1,86.7,47.1c0-0.1-0.1-0.1-0.2-0.1C86.5,47.1,86.6,47.1,86.7,47.1z"
        />
        <path
          className="st9"
          d="M90.6,54.4c0.6,0.2,0.6,0.2,1.1,0.8c0.2-0.8,0.8-1.2,1.6-1.1c0.6,0.1,0.3-0.4,0.5-0.6c0-0.1,0-0.3,0-0.4
		c-0.2-0.3-0.4-0.2-0.7-0.1c-0.1,0-0.3,0.1-0.4,0c0.5-0.7-0.3-0.9-0.6-1.4c0.1,0,0.3-0.1,0.4-0.1c0.2-0.2,0.5,0.6,0.7,0
		c0.1-0.6-0.3-0.4-0.7-0.5c-0.9-0.2-1.3,0.6-1.8,0.9c-0.6,0.4-0.3,0.7,0,1.1c0.2-0.2,0.3-0.1,0.4,0c0.2,0.2-0.1,0.2-0.2,0.2
		c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.1,0.3-0.2,0.5C90.6,54.3,90.6,54.3,90.6,54.4z"
        />
        <path
          className="st16"
          d="M91.7,48.6c0,0.1,0,0.2,0,0.4c0.4,0.1,0.9,0.2,1.4,0.2c0.1,0,0.4-0.1,0.3,0.2c0,0.1-0.2,0.2-0.3,0.2
		c0,0,0,0,0,0c-0.1,0.3-0.1,0.4,0,0.6s0.1,0.2,0.3,0.3c0.2,0.1,0.6,0.1,0.8,0.2c0.1-0.1,0.1-0.3,0.2-0.4c-0.1-0.1-0.2-0.1-0.3-0.2
		c0,0,0.1-0.1,0.1-0.1c0.1,0.1,0.2,0.2,0.2,0.3l0,0c0.3,0.1,0.4,0.2,0.4,0.4c0,0.1,0,0.1,0,0.2l0,0c0.3-0.1,0.5,0.5,0.8,0.2v0
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.6-0.4-0.8-1-0.9c0.1-0.1,0.2-0.2,0.3-0.3
		c0.3-0.2,0.6-0.2,1-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2,0-0.5,0-0.7,0c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.2,0-0.3,0
		l-0.1-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0.3-0.4,0.4-0.9,0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0h-0.1
		c0,0,0,0,0,0C91.8,48.6,91.7,48.6,91.7,48.6C91.7,48.6,91.7,48.6,91.7,48.6z"
        />
        <path
          className="st8"
          d="M86.3,53.8c0,0.4,0.2,0.7,0.4,1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1,0-0.2
		c0-0.2-0.8-0.2-0.2-0.6c0.2-0.1,0.6-0.3,0.8,0.2c0.2,0.4,0.1,0.7-0.3,0.8c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
		c0.1,0.1,0.3,0.2,0.3,0.4c0.5-0.3,0.9-0.2,1.3,0c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2,0c-0.1-0.1-0.1-0.3-0.3-0.4
		c-0.8-0.7-0.7-1.3,0.1-1.9c0-0.2,0-0.3,0-0.5l0,0c0,0,0,0,0,0l0,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0,0,0c-0.2,0-0.5,0-0.6,0.1
		c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.5-0.5,0.3-0.8,0.1c-0.2,0-0.3,0-0.5,0C86.4,53.3,86.3,53.6,86.3,53.8z"
        />
        <path
          className="st10"
          d="M88.3,51.8c0.1-0.5,0.2-0.7,0.6-0.2c0.4,0.4,0.8,0.4,1-0.3c0.1-0.2,0.5-0.5,0.7-0.4c0.6,0.2,0.4-0.2,0.3-0.4
		c-0.1-0.3-0.2-0.4-0.3-0.5c-0.4-0.3-0.9,0.1-1.4,0l0,0c0,0.7-0.4,0.7-0.9,0.4c-0.1,0.2-0.1,0.4-0.2,0.7l0,0c0,0.1-0.1,0.1-0.1,0.2
		c-0.2,0.3-0.4,0.6-0.5,0.9C87.9,52.2,88.3,52.4,88.3,51.8z"
        />
        <path
          className="st10"
          d="M93.1,48.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.4,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0,0,0,0,0
		C92.8,48.9,93,48.8,93.1,48.5z"
        />
        <path
          className="st10"
          d="M94.8,47.1c0.1,0,0.1,0,0.2,0C95,47.1,94.9,47.1,94.8,47.1C94.8,47.1,94.8,47.1,94.8,47.1z"
        />
        <path
          className="st10"
          d="M93.3,57.3C93,57.1,93,56.7,93,56.3c0-0.3,0-0.6,0-0.8c-0.4,0-0.4,0.3-0.5,0.6c0,0.1-0.1,0.2-0.1,0.2
		c-0.2,0.2-0.3,0.4-0.6,0.3c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.3,0-0.4
		c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1,0,0.1,0,0.2c-0.2,1-0.1,1.5,1.2,1.4c0.3,0,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.6,0.1,0.8
		c0,0,0.1,0.1,0.1,0.1c0.2,0.1,0.5-0.1,0.7-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.3,0.1-0.5,0.3-0.6
		c0-0.1,0.1-0.1,0.1-0.2c-0.4,0-0.4-0.5-0.6-0.6v0c-0.1,0.1-0.1,0.2-0.2,0.3C93.5,57.3,93.5,57.3,93.3,57.3z"
        />
        <path
          className="st8"
          d="M82.8,51.4c0.3,0,0.4,0.3,0.5,0.6c0,0.1,0.1,0.3,0.2,0.3c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0
		c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.2l0,0h0c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1
		s-0.1-0.2-0.1-0.3c0.1-0.6,0.6-0.5,1-0.5c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.3-0.4-0.4-0.6c-0.4,0-0.6,0.2-0.7,0.6
		c-0.1,0.3-0.1,0.6-0.5,0.7l0,0C82.8,50.8,82.8,51.1,82.8,51.4z"
        />
        <path
          className="st10"
          d="M98.3,50.7c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0l-0.1,0l-0.1,0l0,0
		c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.2-0.4-0.1-0.7,0c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.4-0.2,0.8,0.2,1.1c0.8-0.3,1.1,0.1,1,0.8
		c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1,0,0.2,0c0.2-0.1,0.1-0.2,0-0.4c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.2,0
		c0-0.2,0-0.4,0-0.6C98.3,51.2,98.3,50.9,98.3,50.7z"
        />
        <path
          className="st9"
          d="M94.5,54.5c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.4-0.1c-1-0.4-1.2-0.1-1,0.9c0,0.3,0,0.6,0,0.8
		c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.6,0.7,0,1.1-0.2c0-0.6,0.8-1,0.4-1.7c-0.1,0-0.3,0-0.4,0C94.5,54.3,94.5,54.4,94.5,54.5z"
        />
        <path
          className="st9"
          d="M96.6,50.1c0-0.3-0.1-0.5-0.3-0.7c0,0,0,0,0,0l0,0l-0.1,0c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2
		c0,0.2,0.1,0.4-0.2,0.5c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.4,0-0.5,0.4-0.6c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0-0.1,0-0.2,0
		c-0.3,0-0.7,0-1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.6,0.1,1,0.3,1,0.9c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
		c0.3-0.2,0.6-0.3,0.9-0.2c0.1-0.1,0.2-0.1,0.2-0.2C96.6,50.2,96.6,50.2,96.6,50.1z"
        />
        <path
          className="st9"
          d="M96.6,51.6c-0.6,0.6,0.7,1.2,0,1.9c0.3,0,0.6,0.3,0.9,0.1c0-0.2-0.1-0.5-0.1-0.7c0-0.1,0.1-0.3,0.2-0.4
		C97.7,51.7,97.5,51.3,96.6,51.6z"
        />
        <path
          className="st10"
          d="M84.8,50.5c-0.4,0-0.9-0.1-1,0.5c0,0.2,0,0.3,0.1,0.3s0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1
		c0.1,0,0.2,0.1,0.2,0.2h0c0.1,0,0.3,0,0.4,0c0.1-0.2,0.2-0.4,0.5-0.4c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2l0,0
		c0-0.3-0.2-0.4-0.3-0.4c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0C84.9,50.5,84.8,50.5,84.8,50.5z"
        />
        <path
          className="st10"
          d="M85.2,49.2C85.2,49.2,85.2,49.2,85.2,49.2c0.3,0,0.4,0.2,0.4,0.4c0.2,0,0.4,0,0.6,0c0.1-0.1,0.3-0.1,0.4-0.2
		c-0.2-0.2-0.2-0.4-0.2-0.7c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3-0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.2,0v-0.1
		c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0C85,48.8,85.1,49,85.2,49.2z"
        />
        <polygon className="st10" points="85,48.6 85,48.6 85,48.6 	" />
        <path
          className="st9"
          d="M95.5,51.1c-0.4,0.3-0.5-0.3-0.8-0.2c-0.2,0.6-0.7,0.4-1.1,0.4c0,0.4,0.3,0.4,0.6,0.5c0.2,0,0.3,0.1,0.4,0.2
		c0.2,0.4,0.5,0.6,0.8,0.2c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.2,0.2-0.4,0.2-0.6C95.8,51.3,95.7,51.2,95.5,51.1z M95.2,51.9
		C95.2,51.8,95.2,51.8,95.2,51.9c0-0.1,0-0.1,0-0.1C95.2,51.8,95.2,51.8,95.2,51.9C95.2,51.8,95.2,51.8,95.2,51.9z"
        />
        <path
          className="st9"
          d="M96.6,54.2c-0.2-0.5-0.9-0.1-1.3-0.4c-0.1,0.6,0.1,1,0.5,1.7C95.9,54.6,96.8,54.6,96.6,54.2z"
        />
        <path
          className="st10"
          d="M93.3,49.4c0.1-0.3-0.2-0.2-0.3-0.2c-0.5,0-0.9-0.1-1.4-0.2c-0.6,0.1,0.2,0.6-0.2,0.8c0.2,0.2,0.4,0.1,0.6,0
		c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0C93.1,49.6,93.2,49.5,93.3,49.4z"
        />
        <path
          className="st9"
          d="M94.6,57.6c0.1,0,0.3,0,0.4,0c0.3,0,0.7,0,0.9-0.2c0-0.1,0-0.3,0-0.4c-0.7-0.3-1.2-0.3-1.4,0.3
		c0,0.1-0.1,0.2-0.1,0.3C94.5,57.6,94.6,57.6,94.6,57.6z"
        />
        <polygon
          className="st9"
          points="94.4,57.6 94.4,57.6 94.4,57.6 94.4,57.6 94.4,57.6 94.4,57.6 	"
        />
        <path
          className="st10"
          d="M93.4,56.1c0.1,0.2,0.3,0.4,0.4,0.6c0,0,0,0,0,0c0.4,0,0.8-0.1,0.8-0.4c0-0.1,0-0.3-0.1-0.4
		C94.1,56.1,93.6,55.5,93.4,56.1z"
        />
        <path
          className="st8"
          d="M99.4,52.2c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.1-0.1-0.3-0.1c-0.2,0-0.4,0-0.6,0
		c0,0.2,0,0.4,0,0.6c0.1,0.1,0.2,0.1,0.2,0.2s0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0C99.1,52.4,99.2,52.4,99.4,52.2z"
        />
        <path
          className="st10"
          d="M91.7,48.6c0.1,0,0.1,0,0.2,0c-0.1-0.1-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.3-0.1-0.4-0.1
		c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.1,0.3,0.2C91.2,48.7,91.4,48.6,91.7,48.6z"
        />
        <path
          className="st10"
          d="M86.1,51.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.2,0.5,0.4,0.4,0.8
		c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0-0.3,0-0.7,0-1c-0.2,0-0.3,0.2-0.5,0C86.2,52,86.1,51.8,86.1,51.6z"
        />
        <path
          className="st8"
          d="M93.8,53.5c0.3,0.1,0.6,0.3,0.7,0.6c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.1-0.2-0.1-0.4
		c0-0.2,0-0.4-0.1-0.5c0,0,0-0.1,0-0.1c-0.1-0.2-0.3-0.2-0.8,0C93.8,53.2,93.8,53.4,93.8,53.5z"
        />
        <path
          className="st16"
          d="M93.4,56.1c-0.1,0.1-0.3,0.2-0.4,0.2c0,0,0,0,0,0c0,0.4,0,0.7,0.4,0.9c0.1,0.1,0.2,0,0.3,0
		c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.2C93.7,56.5,93.5,56.3,93.4,56.1z"
        />
        <path
          className="st10"
          d="M88.6,46.6C88.6,46.6,88.6,46.6,88.6,46.6c-0.2,0.1-0.4,0.1-0.5,0.1c0.1,0.1,0.2,0.1,0.3,0.3
		c0.1,0,0.2,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0,0C88.7,46.8,88.7,46.7,88.6,46.6z"
        />
        <path
          className="st12"
          d="M84.5,49c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		C85.1,49,85,48.8,85,48.6l0,0c0,0,0,0,0,0c-0.2,0-0.4,0-0.6-0.1C84.3,48.7,84.4,48.9,84.5,49z"
        />
        <path
          className="st9"
          d="M95.3,56c0.1,0,0.2-0.1,0.3-0.1c0.2-0.3-0.1-0.4-0.4-0.6C95.2,55.5,94.9,55.8,95.3,56z"
        />
        <path
          className="st16"
          d="M90.3,54.2c0.1,0,0.2,0,0.3,0c0.1-0.2,0.1-0.3,0.2-0.5c-0.4,0-0.7,0-1.1,0l0,0C89.8,54.1,90,54.2,90.3,54.2z"
        />
        <path
          className="st12"
          d="M85.2,49.2C85.2,49.2,85.2,49.2,85.2,49.2C85.2,49.2,85.2,49.2,85.2,49.2C85.2,49.2,85.2,49.2,85.2,49.2
		c0,0.2-0.1,0.6,0.2,0.7c0.1,0.1,0.2,0,0.2-0.1c0-0.1,0.1-0.2,0.1-0.2C85.6,49.4,85.5,49.2,85.2,49.2z"
        />
        <path
          className="st16"
          d="M94.7,50.9L94.7,50.9c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0-0.1,0.1
		c0,0,0,0,0,0C94,51.3,94.5,51.6,94.7,50.9z"
        />
        <path
          className="st9"
          d="M94.7,50.9c0-0.1,0-0.2,0-0.2c0-0.2-0.1-0.4-0.4-0.4l0,0l0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.3-0.2,0.4
		c0.1,0.1,0.2,0.2,0.2,0.2C94.4,51,94.5,50.9,94.7,50.9z"
        />
        <path
          className="st16"
          d="M88.8,53.5C88.8,53.5,88.8,53.5,88.8,53.5C88.9,53.5,88.9,53.5,88.8,53.5c0.2,0,0.4,0.1,0.6,0.1
		c0,0,0.1-0.1,0.1-0.1c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.4-0.1C88.8,53.2,88.8,53.4,88.8,53.5z"
        />
        <path
          className="st16"
          d="M91,53.1c-0.1-0.1-0.3-0.2-0.4,0c0.1,0.1,0.2,0.2,0.3,0.3C90.9,53.3,91.1,53.3,91,53.1z"
        />
        <path
          className="st12"
          d="M96.1,56.1c0,0.1,0.2,0.1,0.3,0.2c0-0.1,0-0.3,0-0.4C96.2,55.9,96,55.9,96.1,56.1z"
        />
        <path
          className="st16"
          d="M96.8,55.9c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.2
		C96.7,56.1,96.8,56,96.8,55.9z"
        />
        <path
          className="st9"
          d="M97.9,52.7c0,0.1,0.1,0.2,0.1,0.2c0-0.1,0.1-0.2,0.1-0.3C98,52.7,98,52.7,97.9,52.7z"
        />
        <path
          className="st8"
          d="M88.9,48.6C88.9,48.5,88.8,48.5,88.9,48.6c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0.1
		C88.7,48.6,88.8,48.6,88.9,48.6z"
        />
        <path
          className="st8"
          d="M88.6,48.6c-0.1,0-0.1,0-0.2,0h0C88.5,48.7,88.6,48.7,88.6,48.6z"
        />
        <path className="st18" d="M88.8,53c-0.1-0.1-0.2-0.2-0.3-0.3" />
        <path
          className="st16"
          d="M89.7,53.8C89.7,53.8,89.7,53.7,89.7,53.8c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0.1-0.1,0.1
		C89.5,53.7,89.6,53.7,89.7,53.8z"
        />
        <path
          className="st9"
          d="M94.6,59.5c0.1,0,0.1-0.2,0.1-0.4c0-0.1,0-0.1,0-0.2l0,0c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.2,0-0.3,0
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,1.1-0.7,0.4-1.1,0.2c-0.5,0.2-0.9,0.1-1.1-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3
		c0.5,0.4,0.6,1,0.6,1.5c0.4,0,0.7,0,1.1,0c0.4,0,0.8,0,1.2,0C94.2,60,94.2,59.7,94.6,59.5z M93.4,60.1L93.4,60.1L93.4,60.1
		L93.4,60.1z"
        />
        <path
          className="st9"
          d="M97,59.9c-0.2,0-0.6,0.1-0.5-0.3c0.1-0.3,0.3-0.4,0.5-0.1c0.3-0.6,0.1-1-0.2-1.2c-0.2-0.2-0.5-0.3-0.7-0.5
		c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.1,0,0.2c0.1,0.1,0.3,0.1,0.4,0.2
		c0.3,0.2,0.4,0.4-0.1,0.9c-0.3,0.2-0.3,0.5-0.2,0.7c0,0,0,0,0.1,0c0.2,0,0.3,0,0.5,0c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1
		c0.4,0,1.1,0,1.3-0.1c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0C97.9,59.8,97.7,59.7,97,59.9
		z"
        />
        <path
          className="st9"
          d="M97.9,60.5c-0.1,0-0.1,0-0.1,0C97.8,60.6,98,60.5,97.9,60.5L97.9,60.5z"
        />
        <path
          className="st12"
          d="M104.1,59.7C104.1,59.7,104.1,59.7,104.1,59.7C104.1,59.7,104.1,59.7,104.1,59.7
		C104.1,59.8,104.1,59.8,104.1,59.7c0.1,0,0.3,0,0.4,0C104.4,59.7,104.3,59.7,104.1,59.7z"
        />
        <path
          className="st15"
          d="M94.3,57.8c-0.1,0.2-0.3,0.4-0.3,0.6c0.1,0,0.2,0,0.3,0c0.1-0.2,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1
		c0.2-0.1,0.3-0.1,0.3-0.1c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.3-0.3,0.4-0.4c-0.1-0.1-0.1-0.3-0.2-0.4
		c-0.3,0.3-0.6,0.3-0.9,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0C94.4,57.7,94.4,57.7,94.3,57.8z"
        />
        <path
          className="st9"
          d="M99.3,59.3c-0.1,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0-0.4c-0.1,0-0.2,0-0.2,0c0,0.4,0,0.8,0,1.3c0.2,0,0.3,0,0.5,0
		C99.6,59.8,99.7,59.7,99.3,59.3z"
        />
        <path
          className="st12"
          d="M94.7,58.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.2,0.2,0.3,0.3,0.5c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C94.9,58,94.8,58,94.7,58.1z"
        />
        <path
          className="st13"
          d="M96.5,59.6c-0.1,0.4,0.3,0.3,0.5,0.3c0-0.1,0-0.3,0.1-0.4C96.8,59.3,96.6,59.4,96.5,59.6z"
        />
        <path
          className="st9"
          d="M98.1,59C98.1,59,98,59.1,98.1,59c-0.1,0.3,0.1,0.3,0.2,0.3l0,0C98.3,59.2,98.3,59,98.1,59z"
        />
        <path
          className="st13"
          d="M96,60.1c-0.1,0-0.2,0-0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0C96.1,60.1,96,60.1,96,60.1z"
        />
        <polygon className="st9" points="100.1,59.3 100.1,59.2 100,59.2 	" />
        <path
          className="st8"
          d="M99,58c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1C98.9,58.2,98.9,58.1,99,58z"
        />
        <path
          className="st9"
          d="M98.3,59.3C98.3,59.3,98.4,59.3,98.3,59.3C98.4,59.3,98.3,59.3,98.3,59.3L98.3,59.3z"
        />
        <path
          className="st8"
          d="M109.3,50.3c0.3,0,0.6,0.3,0.9,0c0,0,0,0,0-0.1c-0.1,0-0.2,0-0.3,0C109.7,50.2,109.5,50.2,109.3,50.3
		C109.3,50.2,109.3,50.3,109.3,50.3C109.3,50.3,109.3,50.3,109.3,50.3z"
        />
        <path
          className="st15"
          d="M109.3,50.3C109.3,50.3,109.3,50.3,109.3,50.3L109.3,50.3C109.3,50.3,109.3,50.3,109.3,50.3
		C109.3,50.3,109.3,50.3,109.3,50.3c-0.2,0.5-0.7,0.4-1,0.6c-0.1,0.1-0.2,0.2-0.3,0.3h0c0.4,0,0.7,0,1.1,0c0.1-0.1,0.3-0.2,0.4-0.2
		c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.4-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.3c0,0,0,0-0.1,0
		C109.9,50.6,109.6,50.3,109.3,50.3z"
        />
        <polygon
          className="st15"
          points="108,51.1 108,51.1 108,51.1 108,51.1 	"
        />
        <path
          className="st8"
          d="M105.7,49.1c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0h-0.1
		h-0.1h-0.1c-0.1,0-0.3,0-0.4,0h-0.1h-0.1h-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0C104.8,49.4,105.4,48.9,105.7,49.1z"
        />
        <path
          className="st8"
          d="M105.9,49.2C105.9,49.2,105.9,49.2,105.9,49.2C105.8,49.2,105.9,49.2,105.9,49.2
		C105.9,49.2,105.9,49.2,105.9,49.2z"
        />
        <path
          className="st15"
          d="M108.1,50c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0.1,0,0.1C108,50,108,50,108.1,50z"
        />
        <path
          className="st19"
          d="M109.2,50.2C109.3,50.2,109.3,50.3,109.2,50.2"
        />
        <line className="st19" x1="109.3" y1="50.3" x2="109.3" y2="50.3" />
        <path
          className="st15"
          d="M101.1,49.6c0.1-0.1,0.3-0.1,0.4-0.2c-0.1-0.2-0.2-0.3-0.2-0.5c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0H101h-0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.4C100.7,49.5,100.9,49.6,101.1,49.6z"
        />
        <path
          className="st15"
          d="M97.5,50.1C97.5,50.1,97.6,50.1,97.5,50.1l0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.1,0.3,0.1,0.5,0.2
		c0.7,0.1,1.4-0.3,2.1-0.6c-0.1-0.3-0.6-0.5-0.5-0.8c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.3-0.7,0.5C97.5,49.8,97.5,49.9,97.5,50.1z"
        />
        <path
          className="st9"
          d="M100.3,52c0.1,0.2,0.2,0.1,0.3,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2,0,0.3,0.3l0,0
		c0.4,0,1.3,0.6,0.8-0.5c-0.3-0.7-0.6-1.2-0.1-1.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.1-0.3,0.1-0.4,0.2
		C100.3,50.2,100.5,51.2,100.3,52z"
        />
        <path
          className="st15"
          d="M102.5,49.8c-0.5,0.1,0.1,0.5,0,0.8c0,0.5,0.5,0.1,0.8,0.2C103.2,50.4,103,49.8,102.5,49.8z"
        />
        <polygon
          className="st15"
          points="103.3,50.9 103.3,50.9 103.3,50.9 103.3,50.9 103.3,50.9 103.3,50.9 	"
        />
        <path
          className="st10"
          d="M108,51.1C108,51.1,108,51.1,108,51.1C108,51.1,108,51.1,108,51.1L108,51.1C108,51.1,108,51.2,108,51.1
		c0,0.1,0,0.1,0.1,0.2c0.3-0.1,0.7-0.1,1-0.2c0,0,0,0,0-0.1C108.7,51.1,108.4,51.1,108,51.1z"
        />
        <path
          className="st8"
          d="M109.1,51.1C109.1,51.2,109.1,51.2,109.1,51.1C109.1,51.2,109.1,51.2,109.1,51.1c0.2,0.1,0.3,0,0.4-0.1
		c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1,0C109.3,51,109.2,51,109.1,51.1z"
        />
        <path
          className="st10"
          d="M100.2,49.7c-0.7,0.3-1.3,0.7-2.1,0.6c0.1,0.1,0.1,0.3,0.2,0.4c0.4,0,0.7,0.2,0.6,0.6c0.1,0,0.2,0.1,0.3,0.1
		c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0c0.2-0.8,0-1.8,0.9-2.4c-0.3,0-0.4-0.2-0.5-0.3
		c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0,0,0,0c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0C99.6,49.2,100,49.4,100.2,49.7z M99.5,50.6
		C99.5,50.6,99.5,50.5,99.5,50.6c0-0.1,0-0.1,0-0.1C99.5,50.5,99.5,50.5,99.5,50.6C99.5,50.5,99.5,50.6,99.5,50.6z"
        />
        <path
          className="st8"
          d="M103,57.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1C102.8,57.2,102.9,57.2,103,57.2
		C102.9,57.2,103,57.2,103,57.2z"
        />
        <path
          className="st9"
          d="M11.2,51.3C11.2,51.2,11.2,51.2,11.2,51.3c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
		c-0.1,0.1,0,0.1,0.1,0.1c0.1,0,0.3,0,0.5,0c-0.1,0.2-0.2,0.3-0.3,0.4C11.1,51.6,11.3,51.6,11.2,51.3z"
        />
        <path
          className="st9"
          d="M12.2,50.9c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.4,0.1c0,0,0,0-0.1,0
		C11.7,50.9,11.9,51,12.2,50.9z"
        />
        <path
          className="st9"
          d="M11.2,51.2C11.2,51.2,11.1,51.2,11.2,51.2C11.1,51.2,11.1,51.2,11.2,51.2z"
        />
        <path
          className="st8"
          d="M7.3,53.4c0,0,0,0.1,0,0.1c0.1,0,0.2-0.1,0.2-0.1C7.4,53.4,7.3,53.4,7.3,53.4z"
        />
        <path
          className="st8"
          d="M9.9,54.2C9.9,54.2,9.9,54.2,9.9,54.2L9.9,54.2c0-0.1,0.1-0.2,0.2-0.3c0,0,0.1-0.1,0.2-0.1
		c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.3,0.2-0.6c-0.2,0-0.3,0-0.4-0.1c-0.4,0.1-0.7,0.2-1,0.4c0,0,0.1,0.1,0.1,0.1
		c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4-0.1,0.4,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0
		c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.1,0.6C9.7,54.2,9.8,54.2,9.9,54.2z"
        />
        <path
          className="st5"
          d="M9,53.4c-0.5,0-1,0-1.5,0c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.1,0,0.2c0.2,0,0.3,0,0.4,0.1c0.3,0,0.5,0,0.8,0
		c0.1-0.1,0.3-0.2,0.5-0.3C9,53.5,9,53.4,9,53.4z"
        />
        <path
          className="st5"
          d="M9.4,55.9c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.4-0.3-0.6-0.6-0.8c-0.4-0.2-0.5-0.4-0.5-0.6
		c-0.3,0-0.6,0-0.9,0.1c0,0,0.1,0.1,0.1,0.1C8,54.9,8.7,55.4,9.4,55.9z"
        />
        <path
          className="st5"
          d="M8.9,53.2c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0C9,53.3,8.9,53.2,8.9,53.2z"
        />
        <path
          className="st5"
          d="M8.7,53.2L8.7,53.2c0,0,0.1,0,0.2,0C8.8,53.2,8.8,53.2,8.7,53.2L8.7,53.2z"
        />
        <path
          className="st5"
          d="M7.3,58.7c0,0,0.1,0,0.1,0c0.3,0,0.5,0,0.8,0c0,0,0,0,0,0C7.8,58.7,7.6,58.7,7.3,58.7z"
        />
        <path
          className="st5"
          d="M9.2,58.7L9.2,58.7C9.2,58.7,9.2,58.7,9.2,58.7c-0.2,0-0.4,0-0.7,0c0,0,0,0,0,0C8.8,58.7,9,58.7,9.2,58.7
		C9.2,58.7,9.2,58.7,9.2,58.7C9.2,58.7,9.2,58.7,9.2,58.7C9.2,58.7,9.2,58.7,9.2,58.7z"
        />
        <rect x="9.2" y="58.7" className="st5" width="0" height="0" />
        <path
          className="st8"
          d="M7.1,57.3c0,0,0.1-0.1,0.1-0.1c-0.3,0-0.5,0.1-0.8,0.1c0.1,0,0.1,0,0.2,0C6.8,57.4,7,57.4,7.1,57.3z"
        />
        <path
          className="st8"
          d="M9.4,55.9c-0.7-0.5-1.4-1-1.9-1.7c-0.5,0.5-1.1,1-1.3,1.7c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0,0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0.1h0c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0c0,0,0,0.1,0,0.1c1,0,1.9,0,2.9,0c0.4,0,0.8,0,1.1,0
		C9.4,56.1,9.4,56,9.4,55.9z M7.1,55.9c-0.2,0-0.4,0-0.3,0C6.9,55.9,7,55.9,7.1,55.9c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.2,0-0.4,0.2-0.5c0.6-0.1,0.6,0.5,1.2,0.7C7.7,56.1,7.3,56.2,7.1,55.9z"
        />
        <path
          className="st8"
          d="M9.2,56.3c0.1,0.1,0.1,0.1,0.2,0.1c0-0.1,0-0.1,0-0.2C9.3,56.3,9.3,56.3,9.2,56.3z"
        />
        <path
          className="st10"
          d="M8.6,58.7c0.2,0,0.4,0,0.7,0l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.3,0,0.4,0c0,0,0-0.1,0-0.1
		c-0.2,0-0.5,0-0.7,0c-0.2,0.1-0.5,0.1-0.4-0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.7,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
		c-0.3-0.3-0.4-0.7-0.2-1.1c-0.3,0-0.7,0.3-1,0.1c-0.2-0.1-0.4,0-0.3,0.2c0,0.3,0.2,0.6-0.3,0.6c-0.4,0-0.5-0.4-0.3-0.7c0,0,0,0,0,0
		c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.1-0.5,0.1c0,0.3,0,0.6,0,1c0.1,0,0.2,0,0.3,0c0.5,0,1,0,1.5,0
		c0,0.1,0,0.1,0,0.1c-0.4,0-0.8,0.1-1.2,0.1c0,0,0.1,0.1,0.1,0.1c0.3,0,0.6,0,0.9,0C8.3,58.5,8.4,58.5,8.6,58.7z"
        />
        <polygon
          className="st10"
          points="9.2,58.7 9.2,58.7 9.2,58.7 9.2,58.7 	"
        />
        <path
          className="st3"
          d="M5.4,54.8c0.1,0,0.1,0,0.2,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1,0-0.1,0C5.1,54.6,5.2,54.7,5.4,54.8z"
        />
        <path
          className="st8"
          d="M9.4,58.7c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0C9.7,58.7,9.6,58.7,9.4,58.7z"
        />
        <path
          className="st11"
          d="M12.9,51.2c0,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.1c-0.3,0-0.2-0.3-0.2-0.5c-0.1,0-0.1,0-0.2,0
		c-0.3,0-0.5,0-0.8-0.1c0,0,0,0.1,0,0.1s0,0.1,0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0.1,0.3-0.2,0.3-0.4,0.4c0,0,0,0,0,0c1-0.1,1.8,0,2.7,0.1
		c0-0.1,0-0.2,0-0.3c0,0-0.1-0.1-0.1-0.1C13.2,51.2,13.1,51.2,12.9,51.2z"
        />
        <path
          className="st11"
          d="M13.5,51.9C13.5,51.8,13.5,51.8,13.5,51.9c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2,0c0,0,0,0-0.1,0
		c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0s0,0,0,0c0,0-0.1,0-0.1,0
		c0,0-0.1,0-0.1,0h0h-0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.1,0.1,0.1c0.2,0,0.3,0,0.4,0c0,0,0,0,0,0
		c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0.3,0,0.5,0,0.8,0C13.1,51.8,13.3,51.8,13.5,51.9z"
        />
        <path
          className="st11"
          d="M11.3,52.3c-0.2,0-0.2,0.1-0.2,0.2C11.2,52.4,11.2,52.3,11.3,52.3z"
        />
        <path
          className="st11"
          d="M11.6,52.2c0.1-0.2,0.1-0.2,0.2-0.3c-0.3,0-0.6,0-0.9,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0,0.5,0,0.7,0.1
		C11.4,52.2,11.5,52.2,11.6,52.2z"
        />
        <path
          className="st11"
          d="M11.1,52.5c-0.1,0-0.3,0-0.4,0c0,0,0,0.1,0,0.1C10.9,52.7,11,52.6,11.1,52.5z"
        />
        <path
          className="st11"
          d="M12.8,52c0,0,0.1-0.1,0.1-0.1c-0.3,0-0.5,0-0.8,0C12.4,52,12.6,52.1,12.8,52z"
        />
        <path
          className="st15"
          d="M10.1,53.8C10,53.9,9.9,54,9.9,54.2l0,0c0.1,0,0.2,0,0.3,0.1c0.3,0,0.5-0.1,0.8-0.1c0,0,0,0,0,0
		c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.5,0.4-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1
		c0-0.1-0.1-0.3-0.2-0.4l0,0c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1,0.1-0.2,0.2-0.4,0.2c0,0.1,0,0.2,0,0.2c0.1,0.3,0,0.4-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2C10.2,53.8,10.1,53.8,10.1,53.8z"
        />
        <path
          className="st9"
          d="M12.9,52.6c0.3,0.7,0.7-0.2,1.1,0c0.1,0,0.3,0,0.4,0h0c0-0.1,0-0.3,0-0.4l0,0l0,0c-0.3,0-0.6-0.2-0.9-0.4
		c0,0,0,0,0,0c-0.2,0-0.4,0-0.5,0c0,0,0.1,0,0.1,0h0.1c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0.1C12.8,52.2,12.9,52.4,12.9,52.6z"
        />
        <path
          className="st11"
          d="M11.4,54.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.8,0.1c0.1,0.4,0.3,0.6,0.6,0.7c0.2,0.1,0.5,0,0.9-0.1
		h0C11.6,54.6,11.5,54.4,11.4,54.3z"
        />
        <path
          className="st13"
          d="M13.9,52.7c-0.3-0.3-0.7,0.7-1.1,0c-0.3,0.4-0.3,0.9,0,1.3c0.1,0,0.1,0,0.2,0C13.2,53.4,13.8,53.2,13.9,52.7z
		"
        />
        <path
          className="st11"
          d="M9.1,53.3C9.1,53.3,9.1,53.3,9.1,53.3C9.1,53.3,9.1,53.3,9.1,53.3C9.1,53.3,9.1,53.3,9.1,53.3z"
        />
        <path
          className="st11"
          d="M9.1,54.2c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4-0.1c0-0.2-0.1-0.4-0.1-0.6c0-0.1-0.1-0.1-0.1-0.2
		c-0.1,0-0.3,0-0.4,0c0,0,0,0.1-0.1,0.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.4,0c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0.1
		C9.1,54.1,9.1,54.1,9.1,54.2z"
        />
        <path
          className="st11"
          d="M9.3,53.2c-0.1,0-0.1,0-0.2,0.1c0.1,0,0.2,0,0.3,0C9.4,53.3,9.3,53.2,9.3,53.2z"
        />
        <path
          className="st13"
          d="M8,57.9c0.5,0.1,0.3-0.3,0.3-0.6c0-0.2,0.1-0.3,0.3-0.2C9,57.2,9.3,57,9.6,57c0,0,0,0,0-0.1
		c-0.2,0-0.3,0-0.5,0c-0.3,0-0.7-0.1-0.9,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c0,0,0,0,0,0C7.5,57.5,7.6,57.9,8,57.9z"
        />
        <path
          className="st13"
          d="M9.7,56.7C9.7,56.7,9.7,56.7,9.7,56.7c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0
		c0,0-0.1,0-0.1,0h0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.1,0.1,0,0.1,0.1,0.1c0.2,0,0.4,0,0.7,0C9.6,56.8,9.7,56.8,9.7,56.7
		C9.7,56.8,9.8,56.8,9.7,56.7C9.8,56.7,9.8,56.7,9.7,56.7z"
        />
        <path
          className="st16"
          d="M13.4,50.4c0.1,0,0.3,0,0.4,0c0,0-0.1-0.1-0.1-0.1C13.6,50.3,13.5,50.3,13.4,50.4z"
        />
        <path
          className="st16"
          d="M13.9,50.6c-0.1,0-0.2-0.1-0.3,0c-0.2,0-0.3,0-0.5,0c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.2,0.2
		c-0.1,0-0.1,0-0.2,0c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0,0.4,0.1,0.5,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.3-0.2
		C13.9,51.1,13.8,50.8,13.9,50.6z"
        />
        <path
          className="st10"
          d="M14.8,50.9c0,0.2,0,0.4,0,0.5c0.1,0.3,0.6-0.1,0.8-0.1C15.4,51.1,15.1,51,14.8,50.9z"
        />
        <path
          className="st9"
          d="M14.4,52.2C14.4,52.2,14.4,52.2,14.4,52.2C14.4,52.2,14.4,52.2,14.4,52.2C14.4,52.2,14.4,52.2,14.4,52.2z"
        />
        <path
          className="st11"
          d="M13.2,50.1c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.1
		c0.1,0,0.1-0.1,0.1-0.2C13.6,50.1,13.4,50.1,13.2,50.1z"
        />
        <path
          className="st3"
          d="M15.4,50.4C15.4,50.4,15.4,50.4,15.4,50.4c0,0,0.1,0,0.2,0C15.5,50.4,15.4,50.4,15.4,50.4z"
        />
        <path
          className="st3"
          d="M15.2,50.7c0.1,0,0.2-0.1,0.3-0.1c-0.1,0-0.2,0-0.3,0C15.3,50.6,15.2,50.7,15.2,50.7L15.2,50.7z"
        />
        <path
          className="st8"
          d="M12.4,51c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.4,0
		c-0.2,0.1-0.4,0.1-0.6,0.1c0,0.1,0.1,0.2,0.1,0.3C12.3,51,12.4,51,12.4,51z"
        />
        <path
          className="st8"
          d="M12.8,50.1C12.8,50.1,12.8,50.2,12.8,50.1c0,0.2,0,0.2,0,0.2c-0.1,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0
		C12.9,50.3,12.9,50.2,12.8,50.1z"
        />
        <path
          className="st2"
          d="M13.7,48.9C13.7,49,13.7,48.9,13.7,48.9C13.7,48.9,13.7,48.9,13.7,48.9C13.7,48.9,13.7,48.9,13.7,48.9z"
        />
        <path
          className="st5"
          d="M68,53.3c-0.2,0-0.4,0-0.6,0c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2-0.1-0.3-0.3-0.4-0.4
		c-0.1,0-0.1,0-0.2,0c0.3,0.4,0,0.8-0.3,1c-0.5,0.4-0.3-0.2-0.4-0.3h0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.4,0.4l0,0l0,0v0c0.4,0,0.6,0.1,0.8,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4-0.2,0.2-0.3,0.3
		c0,0.4-0.5,0.5-0.6,0.9c0.3,0,0.6,0,0.9,0c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.5-0.5,0.8-0.6c0.2-0.1,0.5-0.2,0.7-0.2
		c0.4,0,0.8,0.2,1.1,0.7c0.1,0,0.1,0,0.2,0c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0.1,0.2,0.1,0.4
		c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0-0.3,0-0.6,0-0.9c-0.2,0-0.3,0-0.5-0.1c0,0,0,0-0.1,0
		c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0.8-0.8,0.9-1.5,0.3c0.5-0.1,1-0.2,1.5-0.3c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1,0-0.3,0.2-0.4,0l0,0c0,0,0,0,0,0C68.1,53.4,68,53.4,68,53.3z"
        />
        <path
          className="st2"
          d="M64.6,51.4c0,0.3-0.3,0.2-0.5,0.2c0.1,0.4-0.6,0.6-0.2,1.1c0.8-0.2,1.5,0.2,2.1,0.7c0-0.4-0.1-0.7-0.5-0.8
		c-0.4-0.1-0.5-0.3-0.3-0.7c0.3-0.5,0.5-0.1,0.8,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4,0
		c0.1,0,0.3,0,0.4,0c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0,0.4,0c0.2-0.2,0.5-0.3,0.5-0.6c-0.2-0.2-0.4-0.4-0.6-0.5
		c-0.6-0.4-1.3-0.5-2-0.4c0,0,0,0,0,0c0,0,0,0,0,0c0-0.3,0.2-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0c0.1-0.3,0.2-0.5,0.2-0.9
		c-0.4,0.2-0.7,0.4-1,0.7c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.7,0.4-1,0.5c-0.3,0.1-0.6,0.1-0.9,0c0.1,0.1,0.1,0.2,0.2,0.2
		C64.3,51.2,64.6,51.1,64.6,51.4z M67,51.4c0.1,0,0.2,0.1,0.1,0.2c-0.1,0-0.2,0.1-0.2,0c0,0-0.1-0.1-0.1-0.2
		C66.9,51.5,67,51.4,67,51.4z"
        />
        <path
          className="st12"
          d="M68.1,47c0.1,0,0.1,0,0.2-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0c0.1,0,0.2,0,0.3,0.1
		C67.9,47,68,47,68.1,47z"
        />
        <path
          className="st12"
          d="M67.9,46.5c-0.2,0-0.3,0-0.5,0C67.5,46.6,67.7,46.6,67.9,46.5z"
        />
        <path
          className="st12"
          d="M68.5,48.8c0.2,0.3,0.6,0.3,0.8,0c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0
		c0,0,0,0,0-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5,0-0.8,0c0,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1C68.7,48.5,68.3,48.5,68.5,48.8z"
        />
        <polygon className="st8" points="71.4,54.8 71.4,54.8 71.4,54.8 	" />
        <path
          className="st8"
          d="M72.4,53.5c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.3-0.6,0.3-1.1,0.3c0,0.1,0,0.1,0.1,0.2c0,0.2,0.1,0.3,0.1,0.5
		c0.4,0,0.7-0.2,1-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.4-0.3-0.8-0.4-1.2
		c-0.2-0.5,0.2-0.3,0.4-0.3c0-0.3,0-0.6,0-0.9c-0.3,0.3-0.6,0.4-0.9,0.7c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0.1c0,0,0,0,0-0.1l0,0
		c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.4,0-0.7-0.6-0.8c0,0.3,0.1,0.7-0.3,0.8c0,0-0.1,0-0.2,0c0,0.2,0,0.3,0,0.5c0.2,0.1,0.4,0.2,0.5,0.3
		C71.8,53,72.6,52.7,72.4,53.5z"
        />
        <polygon className="st8" points="71,52 71,52 71,52 	" />
        <path
          className="st4"
          d="M67.5,52.2c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3-0.2,0.3c-0.2,0-0.2-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.2
		c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2-0.1-0.5-0.5-0.8,0c-0.2,0.4-0.1,0.6,0.3,0.7c0.4,0.1,0.5,0.5,0.5,0.8c0,0.1,0,0.1,0,0.2
		c0.2-0.1,0.4-0.1,0.7-0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4C67.7,52.6,67.6,52.5,67.5,52.2
		z"
        />
        <path
          className="st12"
          d="M70.1,48.4c0.1,0.4-0.1,0.9,0.1,1.2c0.1,0.2,0.3,0.3,0.8,0.3l0,0c0.3-0.7,1.6-0.5,1.3-1.6c0,0,0,0,0,0
		c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.3,0-0.6,0-0.9,0c-0.1,0.1-0.1,0.2-0.1,0.4C70.8,49.4,70.6,48.2,70.1,48.4z"
        />
        <path
          className="st12"
          d="M72,51.9c0,0.2,0.1,0.4,0.2,0.5l0,0v0c0,0,0,0,0,0c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.6-0.5,0.9-0.7
		c0.1-0.2,0.2-0.5,0.2-0.7c0-0.2-0.1-0.4-0.3-0.6c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.5-0.3,0.2-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0.1-0.3,0.3C72,51.2,72,51.6,72,51.9z"
        />
        <path
          className="st5"
          d="M71.1,53.1C71.1,53.1,71.1,53.1,71.1,53.1c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.1,0.3c0.1,0.1,0.3,0.3,0.4,0.4
		c0.4,0,0.8,0,1.1-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.8-0.6-0.5-0.9-0.7c-0.2-0.1-0.4-0.2-0.5-0.3c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0
		C71,52.9,71.1,52.9,71.1,53.1C71.1,53,71.1,53,71.1,53.1z"
        />
        <path
          className="st8"
          d="M73.3,48.2c0,0,0,0.1,0.1,0.1C73.4,48.3,73.4,48.2,73.3,48.2z"
        />
        <path
          className="st8"
          d="M72.5,50.3c-0.2,0.1-0.2,0.3,0,0.4c0.2,0.1,0.4,0.4,0.4-0.1c0-0.2,0.1-0.4,0.2-0.6c0,0,0,0,0,0
		c0-0.1,0-0.3,0-0.4c0,0,0,0,0-0.1c1.2-0.4,0.5-0.9,0.2-1.4c0,0,0,0,0,0c-0.2,0-0.3,0-0.4,0c0,0.1,0,0.1,0,0.2
		C72.7,49.1,72.8,49.7,72.5,50.3z"
        />
        <path
          className="st4"
          d="M69.7,55.2c0.2,0,0.3,0,0.4,0c0.4,0,0.8-0.2,1.3-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.2-0.1-0.3-0.1-0.5
		c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.4,0.1-0.7,0.3-1.1,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0
		C69.7,54.7,69.7,54.9,69.7,55.2z"
        />
        <path
          className="st12"
          d="M67.1,49.7c0.1,0.3,0,0.6-0.2,0.9c0.9,0.3,0.6-1.2,1.5-0.9c-0.1-0.2-0.3-0.4-0.4-0.4
		c-0.1-0.1-0.2-0.1-0.3-0.1C67.5,49.2,67.3,49.4,67.1,49.7z"
        />
        <path
          className="st12"
          d="M70.8,53.7c0-0.1,0-0.2,0.1-0.3s0.2-0.2,0.2-0.3c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.3,0.4-0.4,0.7c0,0,0,0.1-0.1,0.1C70.1,54,70.4,53.9,70.8,53.7z"
        />
        <path
          className="st13"
          d="M68.4,52.4c0.4,0.3,0.9,0.2,1-0.1c0.1-0.3-0.1-0.4-0.3-0.5c-0.1,0-0.2,0-0.3,0h0
		C68.8,52.1,68.6,52.2,68.4,52.4z"
        />
        <path
          className="st13"
          d="M68.8,54.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c-0.1-0.3,0.6-0.5,0.2-0.9c-0.3-0.2-0.5-0.2-0.6,0.2
		C68.7,53.7,68.8,53.9,68.8,54.2L68.8,54.2z"
        />
        <polygon className="st12" points="71,52 71,52 71,52 	" />
        <path
          className="st12"
          d="M70.6,51.4L70.6,51.4L70.6,51.4C70.6,51.4,70.6,51.4,70.6,51.4c0,0.3,0,0.6,0.4,0.6c0-0.2,0-0.5-0.2-0.6
		C70.7,51.4,70.7,51.4,70.6,51.4z"
        />
        <path
          className="st13"
          d="M67.5,52.2c0,0.3,0.2,0.4,0.4,0.4c0-0.1,0-0.2,0-0.2C67.8,52.4,67.7,52.3,67.5,52.2z"
        />
        <path
          className="st12"
          d="M70.6,51.4c0-0.1,0-0.3-0.2-0.3c0,0-0.1,0-0.1,0.1C70.3,51.3,70.4,51.3,70.6,51.4
		C70.6,51.4,70.6,51.4,70.6,51.4L70.6,51.4z"
        />
        <polygon className="st13" points="69.9,52.7 69.9,52.8 69.9,52.8 	" />
        <path
          className="st13"
          d="M68.2,53.5C68.2,53.5,68.2,53.5,68.2,53.5c-0.1-0.1-0.1-0.3-0.2-0.4c0,0.1,0,0.1,0,0.2
		C68,53.4,68.1,53.4,68.2,53.5z"
        />
        <path
          className="st13"
          d="M68.1,57.7c0.6-0.4,0.4-1,0.1-1.6c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.9,0.3-1.7,0.5-2.6,0.2
		c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.4,0.3,0.4,0.6,0.4c0,0,0.1-0.1,0.1-0.1c0,0,0,0.1,0.1,0.1c-0.1,0-0.1,0-0.2,0.1
		c0,0.3-0.2,0.5-0.2,0.8c-0.1,0.3-0.1,0.6-0.1,0.9c0.3-0.2,0.7-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.3c0.1,0,0.1,0,0.2,0
		C67.9,57.8,68,57.7,68.1,57.7z"
        />
        <path
          className="st2"
          d="M62.6,56.1C62.6,56.1,62.6,56.1,62.6,56.1c0,0.7-0.9,0.6-1,1.3c0.4-0.1,0.7-0.2,0.9-0.3
		c0.3-0.1,0.8-0.1,0.9-0.5c0-0.2-0.1-0.3-0.3-0.4C62.9,56.2,62.7,56.2,62.6,56.1L62.6,56.1L62.6,56.1L62.6,56.1z"
        />
        <path
          className="st2"
          d="M65,55.7c-0.4-0.5-1,0.7-1.3-0.2c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.7,0.5,0.8,0.9,0.7
		C64.4,56.2,64.9,56.1,65,55.7z"
        />
        <path
          className="st2"
          d="M62.6,56.1c0.1-0.2,0.1-0.4,0.2-0.6c-0.2-0.3-0.2-0.6-0.7-0.6C62.5,55.2,61.8,55.9,62.6,56.1
		C62.6,56.1,62.6,56.1,62.6,56.1L62.6,56.1z"
        />
        <path
          className="st12"
          d="M66.9,58.5c-0.2,0-0.3,0-0.4,0.1c-0.2,0.1-0.2,0.2-0.3,0.4c0,0.1,0,0.2,0,0.3c0.1,0,0.1,0,0.2,0
		c0.1-0.1,0.3-0.3,0.4-0.4C66.9,58.7,66.9,58.6,66.9,58.5z"
        />
        <path
          className="st11"
          d="M66.3,59.3c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.1,0,0.1,0.1,0.2c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0-0.3,0-0.6,0-0.9
		C66.4,59.3,66.3,59.3,66.3,59.3z"
        />
        <path
          className="st4"
          d="M66.9,58.5c0,0.1,0,0.3,0,0.4c0.1,0,0.3,0,0.4,0c0-0.1,0-0.3,0-0.4C67.2,58.5,67,58.5,66.9,58.5z"
        />
        <path
          className="st3"
          d="M64.6,51.4c0-0.3-0.3-0.2-0.5-0.2c0,0.1,0,0.3,0,0.4C64.3,51.5,64.6,51.6,64.6,51.4z"
        />
        <polygon className="st2" points="47.8,56.4 47.7,56.4 47.8,56.5 	" />
        <path
          className="st3"
          d="M65,55.7c0.1-0.1,0.5,0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.5-0.4-0.8-0.3v0c0,0.2-0.1,0.3-0.3,0.4
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1C64,56.3,64.6,55.2,65,55.7z"
        />
        <path
          className="st2"
          d="M63,54.2c0,0.2-0.1,0.4,0.1,0.4c0,0,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1C63.3,54.3,63.2,54.2,63,54.2z"
        />
        <path
          className="st2"
          d="M65.8,54.7c0.2-0.2,0.6-0.6,0.3-1l0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c-0.2,0.1-0.4,0.1-0.6,0.2c0,0.1,0,0.3,0,0.4
		C65.5,54.5,65.3,55.2,65.8,54.7z"
        />
        <path
          className="st3"
          d="M66,53.7C66,53.7,66,53.7,66,53.7c0.1,0,0.1,0,0.2,0c0.2,0.1,0.3,0.3,0.4,0.4c0-0.3,0-0.6,0-0.8
		c-0.2,0.1-0.4,0.1-0.7,0.2C66,53.6,66,53.7,66,53.7C66,53.7,66,53.7,66,53.7z"
        />
        <path
          className="st8"
          d="M72.9,59.4c-0.2-0.5-0.1-0.8,0.1-1.2c0.1-0.1,0.1-0.2,0.2-0.4c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.2-0.2-0.4-0.2-0.6c0-1.1-0.5-0.9-1.2-0.5c0,0.5-0.2,0.7-0.6,0.4c0,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.5-0.3,0.8l0,0
		c0.4,0.1,0.8,0.2,1.1-0.3c0.1-0.3,0.4-0.4,0.8-0.3c-0.2,1-1.1,1.6-1.6,2.4c0.8,0,1.5,0,2.3,0C73,60,73,59.7,72.9,59.4z"
        />
        <path
          className="st4"
          d="M71.4,58.1c-0.2,0.6-0.6,0.4-1.1,0.3c0,0,0,0,0,0.1c0,0,0,0,0-0.1c0-0.3,0.1-0.5,0.3-0.8
		c0.2-0.2,0.3-0.5,0.4-0.7c-0.9-0.4-1.4,0.3-2,0.8c-0.3,0.3-0.5,0.5-0.9,0.3c0,0.2,0,0.4,0,0.7s0,0.5,0,0.7c0.3-0.3,0.6-0.8,0.9-0.6
		c0.4,0.3,0.7,0.8,0.9,1.2c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0.5-0.8,1.4-1.4,1.6-2.4
		C71.8,57.7,71.5,57.8,71.4,58.1z"
        />
        <path
          className="st2"
          d="M70.3,56.3c-0.1,0-0.1,0-0.2,0c-0.4,0.2-0.9,1.3-1.3,0c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.1-0.2-0.1
		c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0.3,0.6,0.5,1.2-0.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.2
		c0.4,0.2,0.6,0,0.9-0.3c0.5-0.5,1.1-1.1,2-0.8c0.5,0.2,0.7,0.1,0.6-0.4C71.3,56.1,70.8,56.3,70.3,56.3z"
        />
        <path
          className="st3"
          d="M67.6,59.8C67.6,59.7,67.6,59.7,67.6,59.8C67.6,59.7,67.6,59.7,67.6,59.8C67.5,59.7,67.6,59.8,67.6,59.8
		L67.6,59.8z"
        />
        <path
          className="st9"
          d="M88.6,59.7c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0-0.2,0c-0.4-0.2-0.9-0.3-1.2-0.6c-0.1-0.1-0.2-0.3-0.2-0.4
		C87,58,86.7,58,86.5,58c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.3-0.2-0.6-0.4-0.6c0,0,0,0,0,0c-0.6,0.5-0.2,1.2-0.3,1.9
		c0.3-0.2,0.4-0.6,0.7-0.6c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0.3,0.2,0.6,0.3,0.8c0.1,0.1,0.1,0.3,0.2,0.4
		c0.4,0,0.8,0,1.2,0C88.2,60,88.4,59.9,88.6,59.7z"
        />
        <path
          className="st9"
          d="M88.4,60.8L88.4,60.8c-0.1,0-0.1,0-0.1,0C88.3,60.8,88.3,60.8,88.4,60.8z"
        />
        <path
          className="st9"
          d="M84.2,57.1C84.1,57,84,56.8,84,56.6c-0.1-0.4-0.1-0.8-0.1-1.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
		c-0.2,0.3-0.2,0.7-0.2,1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.4c0,0,0.1,0,0.1,0
		c0.2,0.1,0.2,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.3-0.3
		c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2l0,0l0,0C84.4,57.4,84.3,57.3,84.2,57.1z"
        />
        <path
          className="st9"
          d="M85.6,56.7c0-0.2,0-0.4,0-0.6c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.3
		c-0.1,0-0.1,0-0.2,0c-0.2,0.4-0.4,0.9,0,1.3c0.4,0.1,0.7,0.3,1.1,0.4l0,0C85.7,57.3,85.6,57,85.6,56.7z"
        />
        <path
          className="st13"
          d="M84.8,55.9L84.8,55.9C84.8,55.9,84.8,55.9,84.8,55.9C84.8,55.9,84.8,55.9,84.8,55.9c-0.3-0.1-0.6-0.3-0.9-0.4
		c0,0.4,0,0.8,0.1,1.1c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.5l0,0c0.1-0.1,0.1-0.3,0.2-0.4l0,0
		C84.4,56.7,84.6,56.3,84.8,55.9z"
        />
        <path
          className="st15"
          d="M80.9,59.8c-0.3,0.2-0.4,0.3-0.3,0.5c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0c-0.1-0.1-0.1-0.3-0.2-0.5
		C81,59.8,80.9,59.8,80.9,59.8z"
        />
        <path
          className="st13"
          d="M81.5,59.6c0.1,0.3,0.3,0.4,0.6,0.5s0.5,0.1,0.7,0.2c0.1-0.7-0.2-0.8-0.4-0.8S81.8,59.6,81.5,59.6L81.5,59.6
		L81.5,59.6C81.5,59.5,81.5,59.6,81.5,59.6z"
        />
        <path
          className="st9"
          d="M84.8,58.4c0,0.1,0.1,0.1,0.2,0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.2C84.9,58.3,84.8,58.3,84.8,58.4
		z"
        />
        <path
          className="st13"
          d="M80.9,59.8c0.1,0,0.1,0,0.2,0.1c0.2-0.1,0.3-0.2,0.5-0.3c0,0,0,0,0,0l0,0c0-0.2-0.1-0.2-0.1-0.3
		c-0.1,0-0.2,0-0.3,0.1h0C81,59.5,81,59.6,80.9,59.8z"
        />
        <path
          className="st12"
          d="M83.9,57.8c0,0.1,0,0.2,0,0.2C84,58,84,57.9,83.9,57.8z"
        />
        <path
          className="st10"
          d="M83.5,55.5c0.1-0.2,0.1-0.2,0.3,0c0,0,0.1,0,0.1,0c0.3,0.1,0.6,0.3,0.9,0.4c0-0.2,0-0.3,0.1-0.5
		c0.1-0.2,0.3-0.4,0.3-0.7c0-0.7-0.1-1.4-0.6-1.9c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0C84,52.9,84,53,83.9,53.1
		c-0.4,0.9-1.4,0.3-1.9,0.9c0.1,0.5,0.5,0.8,0.9,1.1c0,0,0,0,0.1,0c0,0-0.1,0-0.1,0l0,0c-0.3,0.1-0.5,0.2-0.8,0.2c0,0,0,0,0,0
		c-0.3,0-0.6,0-0.9,0c-0.5,0.4-0.6,1.2-1.3,1.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.4-0.3-0.5,0
		c-0.2,0.4,0.2,0.3,0.4,0.4c1.3,0.5,2.4,0.2,3.5-0.5c0.3-0.2,0.5-0.2,0.8-0.2C83.2,56.2,83.2,55.8,83.5,55.5z"
        />
        <path
          className="st10"
          d="M83.9,53.1c0.1-0.1,0.1-0.1,0.2-0.2l0,0C84,52.9,84,53,83.9,53.1z"
        />
        <path
          className="st5"
          d="M74,59.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4c0.1-0.3,0.3-0.5,0.6-0.4c0.3,0.1,0.2,0.4,0,0.6
		c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2l0,0c0.7-0.5,0.6-1.2,0.6-1.9c0-0.4,0.1-0.8,0.4-1.1
		c-0.1-0.1-0.2-0.1-0.3-0.2l0,0c-0.2,0.3-0.4,0.6-0.6,0.8l0,0c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0.2,0.3,0.1,0.4
		c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3-0.2-0.5-0.3c-0.1,0-0.3,0-0.4,0c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0.1
		c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.4-0.3,0.8-0.1,1.2c0.1,0.3,0.1,0.6,0,0.8c0.2,0,0.3,0,0.5,0c0.2-0.3,0.3-0.6,0.5-0.9l0,0
		c0,0,0,0,0,0C73.8,59.3,73.9,59.3,74,59.3z"
        />
        <path
          className="st10"
          d="M77.9,53.7c0.3,0.1,0.9,0.1,0.7,0.7c0.4,0.3,0.9,0.2,1.3,0.2c0-0.4,0.7-0.6,0.2-1.1c-0.2-0.1-0.4-0.1-0.6-0.2
		c-0.9,0.5-1.1-0.5-1.7-0.6c-0.3,0.1-0.5,0.3-0.6,0.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
		c0,0.1,0.1,0.3,0.2,0.5c0.1,0,0.3,0,0.4,0C77.7,54,77.7,53.6,77.9,53.7z"
        />
        <path
          className="st9"
          d="M79.4,53.3c0-0.2,0-0.4,0-0.5c-0.2-0.6-0.3-1.1,0.6-1.1c0.2,0,0.3-0.3,0.3-0.5c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.2-0.9,0.2-0.8,0.5c0.2,0.7-0.1,0.9-0.5,1c-0.1,0.1-0.3,0.1-0.4,0.2
		C78.3,52.8,78.5,53.8,79.4,53.3z"
        />
        <path
          className="st8"
          d="M73,54.7c0,0-0.1,0.1-0.2,0.1c-0.3,0.1-0.8,0.2-0.7,0.6c0.2,0.5,0.7,0.2,1,0.2c0.1,0,0.1,0,0.2-0.1
		c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0h0c0-0.2,0-0.3,0.1-0.5c0.1-0.4,0.4-0.8,0.8-1.1c0-0.1,0-0.3,0-0.4
		c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2,0-0.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1C73.2,54.3,73.1,54.5,73,54.7z"
        />
        <path
          className="st10"
          d="M75.5,59.5c-0.2,0-0.4,0-0.6,0c0,0.2-0.1,0.5-0.4,0.5c-0.2,0-0.2-0.2-0.3-0.3c0,0,0,0,0,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0c-0.2,0.3-0.3,0.6-0.5,0.9c0.6,0,1.3,0,1.9,0
		C75,60,75.5,59.8,75.5,59.5z"
        />
        <path
          className="st9"
          d="M77,60c0.3,0,0.6,0.1,0.9,0.3c0.2,0,0.5,0,0.7,0c0,0,0,0,0,0C78.2,59.4,77.7,59.4,77,60z"
        />
        <path
          className="st10"
          d="M75.4,52.6c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.2,0.1-0.4,0.2-0.7c0-0.3-0.1-0.6-0.5-0.4c-0.2,0.1-0.5,0-0.4,0.3
		c0.2,0.6-0.1,0.7-0.5,0.9c-0.4,0.2,0.4,0.5-0.1,0.8c0.1,0.1,0.3,0.1,0.4,0.2C75,53.2,75.1,52.9,75.4,52.6z"
        />
        <path
          className="st9"
          d="M79.5,55.7c-0.2,0-0.3,0-0.5,0c-0.6,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4c-0.1-0.2-0.3-0.4-0.4-0.6
		c-0.1,0-0.3,0-0.4,0c-0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.2,0.9C77.8,56,78,56,78.2,56c0.5,0.1,1.1,0.1,1.6,0.1l0,0l0,0
		C79.8,55.8,79.6,55.7,79.5,55.7z"
        />
        <polygon
          className="st9"
          points="77.7,55.9 77.7,55.9 77.7,55.9 77.7,55.9 	"
        />
        <path
          className="st10"
          d="M80.1,57.9c-0.4,0-0.9,0-1,0.4C79,58.9,79.9,58.6,80,59c0.5,0,0.5-0.3,0.6-0.6C80.7,58.1,80.4,57.8,80.1,57.9
		z"
        />
        <path
          className="st11"
          d="M74.1,55c0,0.1-0.1,0.3-0.1,0.5h0l0,0c0.1,0,0.3,0,0.4,0v0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
		c-0.1-0.4,0.4-0.7,0.4-1.1c0-0.1-0.1-0.3-0.2-0.4C74.4,54.2,74.2,54.6,74.1,55z"
        />
        <path
          className="st9"
          d="M81.1,55.2c-0.9-0.4-1,0.4-1.3,0.8c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6v0C80.5,56.4,80.6,55.6,81.1,55.2z"
        />
        <path
          className="st5"
          d="M76.2,60c-0.1-0.3-0.3-0.5-0.7-0.5c0,0.3-0.5,0.4-0.3,0.7c0.3,0,0.7,0,1,0c0,0,0,0,0,0
		C76.2,60.2,76.2,60.1,76.2,60z"
        />
        <path
          className="st10"
          d="M77.2,56.6c0.1,0.2,0.1,0.6,0.4,0.4c0.4-0.2,0.1-0.5,0.1-0.8c0-0.1,0-0.2,0-0.3
		C77.2,55.9,76.9,56.1,77.2,56.6z"
        />
        <polygon className="st10" points="77.7,55.9 77.7,55.9 77.7,55.9 	" />
        <path
          className="st10"
          d="M74.4,55.4L74.4,55.4L74.4,55.4c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.5,0.1,0.8c0,0,0,0,0,0
		c0.2-0.3,0.4-0.6,0.6-0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.3-0.1
		C74.6,55.4,74.5,55.4,74.4,55.4z"
        />
        <path
          className="st10"
          d="M76,53.2c-0.3,0-0.4,0.3-0.5,0.5c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0
		c0.3,0,0.5,0,0.5-0.4C76.3,53.4,76.2,53.2,76,53.2z"
        />
        <path
          className="st8"
          d="M74.9,59.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0v0c-0.2,0-0.4,0-0.6,0c0,0.1,0,0.1,0,0.2
		c0.1,0.1,0.1,0.3,0.2,0.4c0,0,0,0,0,0c0.2-0.1,0.4-0.1,0.6-0.2C74.9,59.5,74.9,59.4,74.9,59.3z"
        />
        <path
          className="st15"
          d="M80.4,50.3c0.1,0,0.1,0,0.2,0l0,0c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0-0.3,0-0.4,0C80.2,50,80.3,50.2,80.4,50.3
		C80.4,50.3,80.4,50.3,80.4,50.3L80.4,50.3C80.4,50.3,80.4,50.3,80.4,50.3z"
        />
        <path
          className="st10"
          d="M77,57.5c0,0-0.1,0.1-0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1C77.1,57.6,77,57.5,77,57.5
		C77,57.5,77,57.5,77,57.5z"
        />
        <path
          className="st15"
          d="M80.2,51.1c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c-0.1,0-0.2,0-0.3,0.1
		C80.1,50.9,80,51,80,51.2l0,0C80.1,51.1,80.2,51.1,80.2,51.1z"
        />
        <path
          className="st15"
          d="M80.6,50.5c0,0.1,0,0.1,0,0.2c0.2-0.1,0.2-0.3,0-0.4l0,0C80.7,50.4,80.6,50.5,80.6,50.5z"
        />
        <path
          className="st9"
          d="M80.4,50.3C80.4,50.3,80.4,50.3,80.4,50.3c0.1,0.1,0.1,0.2,0.2,0.2c0-0.1,0-0.2,0-0.2
		C80.6,50.3,80.5,50.3,80.4,50.3z"
        />
        <path
          className="st12"
          d="M74.7,49.3c-0.7,0.4-0.9,0.8-0.4,1.6c0.2,0.3,0,1.3-0.9,1.4c-0.2,0-0.6-0.1-0.4,0.3c0.1,0.4,0.3,0.8,0.4,1.2
		c0,0.1,0,0.3,0,0.4c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.3-0.1c0-0.1,0.1-0.1,0.1-0.3c0-0.2,0.1-0.4,0.2-0.5
		c0.1,0,0.1-0.1,0.2-0.1h0c0.4-0.2-0.4-0.6,0.1-0.8c0.5-0.2,0.7-0.3,0.5-0.9c-0.1-0.3,0.3-0.2,0.4-0.3c0.4-0.1,0.5,0.1,0.5,0.4
		c0.3,0,0.7-0.1,0.6-0.4c-0.2-0.6,0-1.1,0.2-1.6c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.2
		c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0
		c-0.1,0.1-0.1,0.2-0.2,0.4C75,49,74.8,49.2,74.7,49.3z M76.4,49.2C76.4,49.2,76.4,49.2,76.4,49.2L76.4,49.2
		C76.4,49.2,76.4,49.2,76.4,49.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1-0.1-0.1-0.1C76.1,49.3,76.2,49.2,76.4,49.2z"
        />
        <path
          className="st12"
          d="M80.2,51.1c0,0.2-0.1,0.5-0.3,0.5c-0.9,0-0.8,0.5-0.6,1.1c0.1,0.2,0,0.3,0,0.5c0.2,0.1,0.4,0.1,0.6,0.2l0,0
		c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.2,0-0.3,0.1-0.5c0.1-0.2,0.4-0.3,0.9-0.4c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0C80.4,51,80.3,51.1,80.2,51.1z"
        />
        <path
          className="st8"
          d="M81.5,50.9c-0.1,0.1-0.2,0.1-0.2,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0
		c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2c0.3,0,0.6,0,0.8,0c0-0.1,0-0.2,0-0.2c0.3-0.2,0.6-0.4,0.9-0.6
		c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0.2-0.5c0,0,0,0,0,0c-0.2-0.4-0.2-0.4-0.3-0.2c0,0.1-0.1,0.2-0.1,0.3C82.2,50.9,81.9,51,81.5,50.9
		L81.5,50.9C81.5,50.9,81.5,50.9,81.5,50.9z"
        />
        <path
          className="st13"
          d="M77,50.7c0.2,0.1,0.4,0,0.4-0.2c-0.1-0.6,0.5-0.8,0.7-1.2c-0.7-0.1-1,0.4-1.1,1C76.9,50.4,76.8,50.6,77,50.7z
		"
        />
        <path
          className="st13"
          d="M78.1,49.2C78.1,49.2,78.1,49.2,78.1,49.2C78.1,49.2,78.1,49.2,78.1,49.2C78.1,49.2,78.1,49.2,78.1,49.2z"
        />
        <path
          className="st12"
          d="M80.4,49.9c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.2,0.2-0.2,0.3v0c0,0,0,0,0,0c0,0,0,0,0,0
		C80.2,49.9,80.3,49.9,80.4,49.9z"
        />
        <path
          className="st15"
          d="M80.8,51.3c0.1,0.1,0.2,0.1,0.3,0.1c0-0.1,0-0.2,0-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c0,0,0,0,0,0
		C80.7,51.1,80.7,51.2,80.8,51.3z"
        />
        <path
          className="st15"
          d="M81.5,50.9c-0.1,0-0.2,0-0.2,0C81.4,51,81.4,51.1,81.5,50.9z"
        />
        <path
          className="st11"
          d="M73.2,49.7C73.2,49.7,73.2,49.7,73.2,49.7c0,0.1,0,0.3-0.1,0.4c0,0,0,0,0,0c0.2,0.2,0.3,0.4,0.3,0.6
		c0,0.2,0,0.4-0.2,0.7c0,0.3,0,0.6,0,0.9c0.9-0.1,1-1,0.9-1.4c-0.4-0.8-0.2-1.2,0.4-1.6c0.2-0.1,0.4-0.3,0.2-0.5
		c-0.4,0.1-0.7-0.2-0.9-0.5c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0C73.7,48.8,74.4,49.3,73.2,49.7z"
        />
        <path
          className="st10"
          d="M75,48.4c-0.1,0-0.2,0-0.2,0c-0.3,0-0.4-0.1-0.6-0.1c-0.1,0-0.2,0-0.3,0c0.3,0.2,0.5,0.6,0.9,0.5
		C74.9,48.6,75,48.5,75,48.4z"
        />
        <path
          className="st8"
          d="M75.7,48.4C75.7,48.4,75.7,48.3,75.7,48.4c-0.2,0-0.4,0-0.6,0c0.1,0,0.2,0.1,0.2,0.1
		C75.5,48.5,75.6,48.5,75.7,48.4z"
        />
        <path
          className="st11"
          d="M70.1,48.4C70.1,48.4,70.1,48.4,70.1,48.4C70.1,48.4,70.1,48.4,70.1,48.4c0.4-0.2,0.7,1,0.9,0.3
		c0-0.2,0.1-0.3,0.1-0.4c-0.3,0-0.6,0-0.9,0L70.1,48.4C70.1,48.3,70.1,48.3,70.1,48.4C70.1,48.3,70.1,48.3,70.1,48.4
		C70.1,48.3,70.1,48.3,70.1,48.4L70.1,48.4z"
        />
        <path
          className="st15"
          d="M95.1,47C95.1,47,95.1,47,95.1,47C95.1,47,95.1,47,95.1,47C95.1,47,95.2,47,95.1,47C95.2,47,95.1,47,95.1,47z
		"
        />
        <path
          className="st15"
          d="M95.3,49c0.1,0,0.1,0,0.2,0c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0-0.2,0c0.1,0,0.2,0,0.2,0c0-0.1,0-0.1-0.1-0.2
		c-0.1,0-0.3,0-0.4,0C95.1,48.7,95.2,48.9,95.3,49z"
        />
        <path
          className="st13"
          d="M88.4,50.3c0-0.5-0.2-1-0.7-0.8c-0.5,0.2-0.1,0.6,0.1,1c0.1,0.2,0.1,0.4,0.2,0.5c0,0,0.1,0.1,0.2,0.1
		C88.3,50.7,88.3,50.5,88.4,50.3z"
        />
        <path
          className="st15"
          d="M85,51.8c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0-0.1,0.1-0.2,0.1-0.3
		c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0h0
		C85.2,51.4,85,51.6,85,51.8z"
        />
        <path
          className="st15"
          d="M87,49c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0c0,0.2,0,0.5,0.2,0.7C86.8,49.3,87.1,49.2,87,49z"
        />
        <path
          className="st15"
          d="M86.1,51.6C86.1,51.6,86.1,51.6,86.1,51.6c0,0.3,0.2,0.4,0.4,0.4c0-0.1-0.1-0.2-0.1-0.3
		C86.3,51.6,86.2,51.6,86.1,51.6C86.1,51.6,86.1,51.6,86.1,51.6z"
        />
        <path
          className="st15"
          d="M90.9,55c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0,0.3,0.2c0,0.2-0.2,0.2-0.3,0.2
		c-0.1,0.1-0.2,0.2-0.1,0.3c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1c0.3,0.1,0.4-0.1,0.6-0.3c0.1-0.1,0.1-0.1,0.1-0.2
		c0.1-0.3,0-0.6,0.5-0.6c-0.3-0.9,0-1.2,1-0.9c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.3,0-0.4
		c-0.1-0.4-0.3-0.5-0.7-0.6c-0.2,0.2,0.1,0.7-0.5,0.6c-0.8-0.1-1.4,0.3-1.6,1.1c-0.6-0.6-0.6-0.6-1.1-0.8c-0.1,0.1-0.1,0.3-0.2,0.4
		C90.6,54.8,90.8,54.9,90.9,55z"
        />
        <path
          className="st10"
          d="M88.9,54.4c-0.1,0.2,0.1,0.5,0.3,0.5c0.7-0.2,0.8,0,0.8,0.3c0,0.3-0.2,0.7,0,0.8c0.1,0,0.1,0,0.2,0
		c0-0.1,0-0.1,0-0.2c0.1-0.4,0.2-0.7,0.2-1.1v0c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0-0.1,0-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.6-0.4
		v0c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.6-0.1C89.1,53.8,89,54.1,88.9,54.4z"
        />
        <path
          className="st9"
          d="M94.3,50.3c-0.1-0.1-0.2-0.2-0.2-0.3c0,0-0.1,0.1-0.1,0.1C94,50.2,94.1,50.2,94.3,50.3
		C94.2,50.3,94.3,50.3,94.3,50.3L94.3,50.3z"
        />
        <path
          className="st15"
          d="M94,48.7c0.1,0,0.2,0,0.2-0.1c0,0-0.1,0-0.1,0C94,48.6,94,48.6,94,48.7C93.9,48.6,93.9,48.6,94,48.7z"
        />
        <path
          className="st10"
          d="M86.6,57.8c0.3-0.4,0.5-0.9,1,0c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.2
		c0,0,0,0,0,0c-0.1-0.4-0.1-0.7-0.2-1.1c-0.1-0.1-0.2-0.2-0.3-0.1c-0.9,0.7-0.9-0.2-1-0.7c0-0.1-0.3-0.2-0.3-0.4
		c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1-0.1-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.1,0-0.2,0.1-0.4,0.1c0,0.4-0.3,0.4-0.7,0.4c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1
		c0.6,0.2,0.4,0.4,0,0.6c0,0.3,0.1,0.6,0.2,0.9l0,0c0.2,0.1,0,0.9,0.4,0.6c0.1,0,0.1-0.1,0.2-0.2C86.5,58,86.6,57.9,86.6,57.8z"
        />
        <path
          className="st9"
          d="M90.6,58.5c-0.3-0.1-0.8-0.1-0.5-0.7c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.4
		c0-0.1,0-0.2,0-0.3s-0.1-0.2-0.2-0.3c-0.2,0.1-0.3,0.1-0.4,0c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0-0.4-0.2-0.5c-0.1,0-0.1,0-0.2,0
		c0,0.3,0,0.6-0.4,0.6c0.1,0.4,0.1,0.7,0.2,1.1l0,0l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.1,0.2
		c0.3,1.5,0.7,1.7,2,1.2c-0.1-0.1-0.1-0.3-0.2-0.4C90.8,58.6,90.7,58.5,90.6,58.5z"
        />
        <path
          className="st15"
          d="M89.2,56.3c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2,0,0.4,0c0.1-0.2,0.2-0.3,0.2-0.5c-0.2-0.2,0-0.5,0-0.8
		c0-0.3-0.1-0.5-0.8-0.3c-0.2,0-0.3-0.3-0.3-0.5c0.1-0.3,0.1-0.6-0.1-0.9c0,0,0,0,0,0c0,0,0,0,0,0c-0.8,0.6-0.9,1.2-0.1,1.9
		c0.1,0.1,0.2,0.3,0.3,0.4C89.2,55.9,89.2,56.2,89.2,56.3z"
        />
        <path
          className="st15"
          d="M88.2,56.4c0,0,0.2,0.1,0.3,0.1c0.4,0,0.4-0.3,0.4-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.4-0.2-0.8-0.3-1.3,0
		C87.3,56.2,87.2,57.1,88.2,56.4z"
        />
        <path
          className="st15"
          d="M86.9,54.8c0,0.1,0,0.1,0.1,0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.2,0.5-0.4,0.3-0.8c-0.2-0.5-0.5-0.3-0.8-0.2
		c-0.7,0.3,0.1,0.4,0.2,0.6c0,0.1,0,0.1,0,0.2C87.1,54.6,86.9,54.7,86.9,54.8z"
        />
        <path
          className="st15"
          d="M86.5,54.8c0.1,0,0.2,0,0.3,0c-0.2-0.3-0.4-0.7-0.4-1c0-0.2,0-0.4,0.2-0.7c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0
		C85.8,53.8,85.7,54.4,86.5,54.8z"
        />
        <path
          className="st15"
          d="M92.9,57.5c-0.1-0.2-0.2-0.4-0.5-0.4c-1.2,0.1-1.4-0.4-1.2-1.4c-0.4-0.1-0.7,0.4-1.1,0.2c0,0.1,0,0.1,0,0.2
		c0.3,0.9,1.4,1.3,1.7,2.2c0.2,0.6,0.6,0.6,1.1,0.4c0-0.1,0-0.3,0-0.4C92.9,58,92.9,57.7,92.9,57.5z"
        />
        <path
          className="st9"
          d="M90.9,55c-0.1-0.1-0.3-0.2-0.6-0.2c-0.1,0.4-0.2,0.7-0.2,1.1c0.4,0.2,0.7-0.3,1.1-0.2c0-0.1,0-0.1,0-0.2
		C91.2,55.3,91.1,55.1,90.9,55z"
        />
        <path
          className="st12"
          d="M93.8,58.3c-0.2,0-0.5,0.2-0.7,0.1c0,0-0.1,0-0.1-0.1c0,0.1,0,0.3,0,0.4c0.4,0.2,0.9,0.9,1.1-0.2
		C94,58.3,93.9,58.3,93.8,58.3z"
        />
        <path
          className="st9"
          d="M91.9,55.9c0-0.2-0.2-0.2-0.3-0.2c0,0.1,0,0.3,0,0.4C91.8,56.1,91.9,56.1,91.9,55.9z"
        />
        <path
          className="st9"
          d="M82.4,52c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3,0-0.4,0
		c0,0.1,0.4,0.3,0,0.4c-0.7,0.3-0.5,0.7,0,1.1c0.5-0.5,1.6,0,1.9-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0
		c0.1-0.1,0.2-0.1,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0v0c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.4,0.5-0.7C85.1,52,85,51.9,85,51.8
		c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.2,0.1,0.4-0.2,0.4c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1
		c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.3-0.2-0.5-0.5-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3,0.2-0.6,0.4-0.9,0.6c0.1,0.1,0.2,0.2,0.2,0.2
		C82.1,52,82.3,52,82.4,52z"
        />
        <path
          className="st10"
          d="M82.8,51.4c0-0.3,0-0.6,0-0.9v0c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.1,0,0.2C82.7,51.2,82.8,51.3,82.8,51.4z"
        />
        <path
          className="st10"
          d="M84.1,52c-0.1,0.1-0.2,0.3-0.2,0.4C84.2,52.4,84.2,52.3,84.1,52z"
        />
        <path
          className="st16"
          d="M96.6,50.3L96.6,50.3c0.2-0.1,0.4,0.2,0.7,0c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4
		c-0.3-0.7-0.7-0.4-1.2-0.3c0.2,0.2,0.3,0.4,0.3,0.7C96.6,50.2,96.6,50.2,96.6,50.3z"
        />
        <path
          className="st15"
          d="M98.3,50.7C98.3,50.7,98.3,50.7,98.3,50.7c0,0.2,0,0.4,0,0.6c0.2,0,0.4,0,0.6,0C99,50.9,98.8,50.7,98.3,50.7
		C98.3,50.7,98.3,50.7,98.3,50.7C98.3,50.7,98.3,50.7,98.3,50.7z"
        />
        <path
          className="st16"
          d="M98,50.7c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.1-0.3-0.1-0.5-0.2
		c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3C97.7,50.6,97.9,50.7,98,50.7z"
        />
        <path className="st19" d="M97.5,50.1c-0.1,0.1-0.1,0.2-0.2,0.2" />
        <path
          className="st10"
          d="M96.4,48.8c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.3-0.1c-0.3,0-0.6,0-0.9,0C96.6,48.7,96.5,48.7,96.4,48.8z"
        />
        <path
          className="st10"
          d="M96.2,49.4C96.2,49.4,96.2,49.4,96.2,49.4C96.3,49.4,96.3,49.4,96.2,49.4C96.3,49.4,96.3,49.4,96.2,49.4
		c0.5-0.2,1-0.5,1.2,0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.7,0-1.4-0.1-2.1-0.1C96.3,48.9,96.2,49.1,96.2,49.4z"
        />
        <path
          className="st16"
          d="M95.8,49.4c0.1,0,0.3,0,0.4,0.1c0,0,0.1,0,0.1,0c-0.1-0.3,0-0.5,0.2-0.6c-0.3,0-0.7,0-1,0
		c0,0.1,0.1,0.1,0.1,0.2C95.6,49.1,95.7,49.3,95.8,49.4z"
        />
        <path
          className="st16"
          d="M96.4,48.8c0.1-0.1,0.1-0.1,0.2-0.1c-0.4,0-0.9,0-1.3,0c0,0.1,0,0.1,0.1,0.2C95.8,48.8,96.1,48.8,96.4,48.8z"
        />
        <path
          className="st10"
          d="M95.4,50c0.1,0.2,0.2,0.3,0.4,0.2c0.3-0.1,0.3-0.3,0.2-0.5c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0.1-0.1
		c-0.1,0-0.3,0-0.4-0.1C95.5,49.5,95.2,49.6,95.4,50z"
        />
        <polygon className="st12" points="85,48.6 85,48.6 85,48.6 85,48.6 	" />
        <path
          className="st15"
          d="M95.2,51.8C95.2,51.8,95.2,51.8,95.2,51.8C95.2,51.8,95.2,51.8,95.2,51.8C95.2,51.8,95.2,51.8,95.2,51.8
		C95.2,51.8,95.2,51.8,95.2,51.8z"
        />
        <path
          className="st9"
          d="M86.1,53.1c0.1,0,0.3,0,0.4,0c0-0.4-0.2-0.6-0.4-0.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1,0-0.2,0
		C85.3,52.6,85.6,52.9,86.1,53.1z"
        />
        <path
          className="st16"
          d="M90.1,56c-0.1,0-0.1,0-0.2,0c-0.1,0.2-0.2,0.3-0.2,0.5v0c0.1,0.1,0.2,0.2,0.2,0.3s0,0.2,0,0.3
		c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0.3-0.2,0.4-0.2,0.5-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0,0,0.1,0.1,0.1,0.1
		c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1C91.5,57.3,90.4,57,90.1,56z"
        />
        <path
          className="st15"
          d="M91.4,58.7c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4
		c0.5,0.2,0.7,0.7,0.5,1.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0C92,59.6,91.9,59.1,91.4,58.7z"
        />
        <polygon className="st10" points="93.4,60.1 93.4,60 93.3,60.1 	" />
        <path
          className="st10"
          d="M95.4,57.9c-0.1-0.1-0.2-0.1-0.3,0.1c0,0.1,0,0.2,0,0.2c0.1,0,0.3,0,0.4,0C95.5,58.1,95.5,58,95.4,57.9z"
        />
        <path
          className="st15"
          d="M99.5,50.5C99.5,50.5,99.5,50.6,99.5,50.5C99.5,50.6,99.5,50.5,99.5,50.5C99.5,50.5,99.5,50.5,99.5,50.5
		C99.5,50.5,99.5,50.5,99.5,50.5z"
        />
        <path
          className="st9"
          d="M10.7,52.5c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0-0.1,0c0.1,0,0.2,0.1,0.4,0.1c0-0.1,0-0.2,0-0.2
		C10.7,52.6,10.7,52.6,10.7,52.5z"
        />
        <path
          className="st8"
          d="M8.1,58.7C8.1,58.7,8.2,58.7,8.1,58.7c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0C8.4,58.5,8.3,58.5,8.1,58.7z"
        />
        <path
          className="st13"
          d="M7.1,55.2c-0.2,0-0.2,0.3-0.2,0.5c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0
		c0.2,0.2,0.6,0.1,1.1,0C7.7,55.7,7.6,55.1,7.1,55.2z"
        />
        <path
          className="st5"
          d="M9.3,58.6c0,0-0.1,0-0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.1,0,0.1,0,0.2,0
		C9.4,58.6,9.3,58.6,9.3,58.6z"
        />
        <path
          className="st13"
          d="M12.4,51c0,0.2,0,0.4,0.2,0.5c0,0,0.1,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
		C12.6,51,12.5,51,12.4,51z"
        />
        <path
          className="st3"
          d="M68,56.1c-0.3-0.5-0.7-0.7-1.1-0.7c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.4-0.8,0.6
		C66.3,56.6,67.1,56.4,68,56.1z"
        />
        <path
          className="st8"
          d="M70.1,56.3c0.1,0,0.1,0,0.2,0c0.3-0.5,1.3-0.4,1.1-1.3c-0.4,0.1-0.8,0.2-1.3,0.3c-0.1,0-0.3,0-0.4,0
		c-0.1,0.1-0.1,0.3-0.2,0.4C69.9,55.6,70.1,55.9,70.1,56.3z"
        />
        <path
          className="st4"
          d="M70.1,56.3c0-0.4-0.2-0.7-0.7-0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.3
		C69.3,57.7,69.7,56.5,70.1,56.3z"
        />
        <path
          className="st4"
          d="M67.3,54.5c0.7,0.6,1.2,0.4,1.5-0.3c0,0,0,0,0,0C68.3,54.3,67.9,54.4,67.3,54.5z"
        />
        <path
          className="st2"
          d="M66.7,54.2c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.2-0.5c-0.2,0-0.4,0-0.6,0
		C66.7,53.6,66.7,53.9,66.7,54.2z"
        />
        <path
          className="st4"
          d="M67.2,51.7c0.1-0.2,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.2,0.1,0.2C67,51.7,67.1,51.7,67.2,51.7z"
        />
        <path
          className="st13"
          d="M66.9,52.5c0.2,0,0.2-0.2,0.2-0.3c-0.1,0-0.3,0-0.4,0C66.7,52.4,66.7,52.5,66.9,52.5z"
        />
        <path
          className="st5"
          d="M72.6,54.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.2-0.6,0.5-1,0.5c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c0.2,0.9-0.8,0.8-1.1,1.3c0.4,0,0.9-0.2,1.3,0.2c0.6-0.3,1.2-0.5,1.2,0.5c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.1,0.1,0.2
		c0-0.3-0.1-0.6,0.2-0.9c0-0.1,0-0.3,0-0.4c0.1-0.4,0.1-0.7,0.2-1.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
		c-0.4,0-0.9,0.3-1-0.2c-0.2-0.5,0.3-0.5,0.7-0.6c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.4,0.3-0.5c0-0.1,0-0.3,0-0.4
		c-0.1,0-0.2,0.1-0.3,0.1C72.9,53.9,72.8,54.1,72.6,54.2z"
        />
        <path
          className="st12"
          d="M72.3,52.4C72.3,52.5,72.3,52.5,72.3,52.4C72.3,52.5,72.3,52.5,72.3,52.4C72.3,52.4,72.3,52.4,72.3,52.4
		L72.3,52.4z"
        />
        <path
          className="st3"
          d="M66,56.9C66,56.9,66,56.9,66,56.9c-0.1-0.1-0.2,0-0.2,0c0,0,0,0,0,0C65.9,57,66,56.9,66,56.9z"
        />
        <path
          className="st8"
          d="M70.3,58.5L70.3,58.5C70.3,58.5,70.3,58.5,70.3,58.5C70.3,58.5,70.3,58.5,70.3,58.5z"
        />
        <path
          className="st8"
          d="M91,59.1c-1.3,0.5-1.7,0.3-2-1.2c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0l0,0c0,0,0,0,0,0
		c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.4,0,0.9,0,1.3c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.2,0.6,0c0.1,0.3,0.3,0.6,0.6,0.8c0.3,0,0.6,0,0.9,0
		c0.2,0,0.4,0,0.6,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0C91.7,59.8,91.5,59.3,91,59.1z"
        />
        <path
          className="st15"
          d="M87.9,57.9c-0.1,0-0.2,0-0.3-0.1c-0.5-0.9-0.8-0.4-1,0c0,0.1-0.1,0.1-0.1,0.2c0.2,0,0.5,0,0.6,0.2
		c0,0.2,0.1,0.3,0.2,0.4c0.3,0.4,0.7,0.5,1.2,0.6c0-0.4,0-0.9,0-1.3c-0.1-0.1-0.2-0.2-0.2-0.2C88.1,57.8,88,57.9,87.9,57.9z"
        />
        <path
          className="st15"
          d="M89.2,59.4c-0.1-0.2-0.4,0-0.6,0c0,0.1,0,0.3,0,0.4c0.1,0.1,0.1,0.3,0.1,0.4c0.3,0,0.7,0,1,0
		C89.5,60,89.3,59.7,89.2,59.4z"
        />
        <path
          className="st8"
          d="M88.6,59.7c-0.3,0.1-0.4,0.3-0.6,0.4c0.2,0,0.5,0,0.7,0C88.8,60,88.7,59.9,88.6,59.7z"
        />
        <path
          className="st8"
          d="M88.3,60.7L88.3,60.7C88.3,60.7,88.2,60.7,88.3,60.7C88.3,60.8,88.3,60.7,88.3,60.7z"
        />
        <path
          className="st10"
          d="M86.3,58.9C86.3,58.9,86.3,58.9,86.3,58.9c0-0.1,0-0.1,0-0.1C86.3,58.8,86.3,58.8,86.3,58.9
		C86.3,58.9,86.3,58.9,86.3,58.9C86.3,58.9,86.3,58.9,86.3,58.9z"
        />
        <path
          className="st13"
          d="M83.5,55.5c0.1,0,0.2,0,0.3,0C83.6,55.3,83.5,55.3,83.5,55.5z"
        />
        <path
          className="st12"
          d="M84.7,52.7c-0.1,0.1-0.1,0.1-0.2,0.2l0,0c0,0,0,0,0,0c0.6,0.5,0.6,1.2,0.6,1.9c0,0.3-0.2,0.5-0.3,0.7
		c-0.1,0.1-0.1,0.3-0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.3,0,0.6,0,0.7-0.4c0.2,0,0.3,0,0.4-0.1
		c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0c-0.7-0.4-0.6-1.1-0.4-1.8c-0.4-0.2-0.8-0.5-0.6-1.1
		c-0.1,0-0.1,0-0.2,0C85.1,52.3,84.9,52.5,84.7,52.7z M85.6,55.5C85.6,55.5,85.6,55.5,85.6,55.5C85.6,55.4,85.6,55.4,85.6,55.5
		C85.6,55.4,85.6,55.4,85.6,55.5z"
        />
        <path
          className="st12"
          d="M85.6,56.1c0,0.2,0,0.4,0,0.6C86,56.5,86.2,56.3,85.6,56.1z"
        />
        <path
          className="st12"
          d="M80,53.5C80,53.5,80,53.5,80,53.5c0.5,0.5-0.2,0.7-0.2,1.1c-0.5,0.9-1.1,0.3-1.7,0.2c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0.2,0,0.3,0,0.4c0,0.4,0.3,0.6,0.9,0.5c0.2,0,0.3-0.1,0.5,0c0.2,0,0.3,0.2,0.3,0.4v0c0.3-0.5,0.4-1.3,1.3-0.8c0.3,0,0.6,0,0.9,0
		c0,0,0,0,0,0c0.3,0,0.5-0.1,0.8-0.2l0,0l0,0c0,0,0,0,0,0c-0.4-0.3-0.8-0.5-0.9-1.1c-0.5-0.4-0.7-0.7,0-1.1c0.4-0.2,0-0.3,0-0.4
		c-0.1-0.1-0.1-0.3-0.2-0.4l0,0c-0.3,0-0.6,0-0.8,0C81.3,52.9,80.2,53,80,53.5z M80.9,53.7C80.9,53.7,80.8,53.7,80.9,53.7
		C80.8,53.7,80.9,53.7,80.9,53.7C80.9,53.7,80.9,53.8,80.9,53.7C80.9,53.8,80.9,53.7,80.9,53.7z"
        />
        <path
          className="st12"
          d="M84.3,52.9c0.1,0,0.2,0,0.2,0C84.5,52.8,84.4,52.8,84.3,52.9z"
        />
        <line className="st20" x1="84.1" y1="52.9" x2="84.1" y2="52.9" />
        <path className="st20" d="M84.1,52.9C84,52.9,84,53,83.9,53.1" />
        <path
          className="st12"
          d="M82.8,55C82.8,55,82.9,55.1,82.8,55C82.9,55,82.9,55,82.8,55C82.8,55,82.8,55,82.8,55L82.8,55z"
        />
        <path
          className="st13"
          d="M74.8,56.2c-0.1-0.2-0.2-0.5-0.3-0.7h0c-0.1,0-0.3,0-0.4,0l0,0c0,0,0,0,0,0c0,0.5,0.2,1.1-0.6,1.1
		c0,0.1,0,0.3,0,0.4c0.3,0,0.4,0.2,0.5,0.4c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3
		C74.9,56.6,74.9,56.4,74.8,56.2z"
        />
        <path
          className="st8"
          d="M73.8,57.4c0-0.3-0.2-0.4-0.5-0.4c-0.3,0.2-0.2,0.6-0.2,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.3,0,0.4-0.1
		C73.7,57.7,73.8,57.6,73.8,57.4z"
        />
        <path
          className="st8"
          d="M74.6,58.3c-0.3-0.1-0.5,0.1-0.6,0.4c0.2,0,0.5,0,0.6,0.2C74.8,58.7,74.9,58.5,74.6,58.3z"
        />
        <path
          className="st8"
          d="M74.2,57.4c0.1,0.2,0.2,0.4,0.5,0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.3-0.1-0.4
		C74.5,57.3,74.3,57.3,74.2,57.4z"
        />
        <path
          className="st12"
          d="M74.7,59.1L74.7,59.1c0-0.1,0-0.1,0-0.2c-0.2-0.2-0.4-0.2-0.6-0.2c0,0.1,0,0.3,0,0.4
		C74.2,59.1,74.4,59.1,74.7,59.1z"
        />
        <path
          className="st12"
          d="M74,59.3c-0.1,0-0.1,0-0.2,0c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1C74.1,59.6,74.1,59.5,74,59.3z"
        />
        <path
          className="st15"
          d="M78.1,54.8c0.6,0.1,1.2,0.7,1.7-0.2c-0.4-0.1-0.9,0.1-1.3-0.2C78.2,54.4,78.1,54.5,78.1,54.8z"
        />
        <path
          className="st12"
          d="M78.1,54.8C78.1,54.8,78.1,54.8,78.1,54.8c0-0.3,0.1-0.4,0.4-0.4c0.2-0.6-0.4-0.6-0.7-0.7
		c-0.2-0.1-0.2,0.3-0.2,0.5C77.8,54.4,77.9,54.6,78.1,54.8L78.1,54.8C78.1,54.8,78.1,54.8,78.1,54.8z"
        />
        <path
          className="st11"
          d="M74,55.4c-0.1,0-0.3,0-0.4,0c-0.1,0.4-0.1,0.7-0.2,1.1C74.2,56.5,74,55.9,74,55.4z"
        />
        <path
          className="st12"
          d="M74.2,59.7c0,0.2,0.1,0.4,0.3,0.3c0.3-0.1,0.3-0.3,0.4-0.5c0,0,0,0,0,0c0,0,0,0,0,0l0,0
		C74.6,59.6,74.4,59.7,74.2,59.7z"
        />
        <path
          className="st9"
          d="M76.2,49.5c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.3,0-0.3,0.2C76.1,49.4,76.1,49.5,76.2,49.5z"
        />
        <polygon className="st9" points="76.4,49.2 76.4,49.2 76.4,49.2 	" />
        <path
          className="st9"
          d="M80,52.1c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.3,0.1,0.6,0.2,0.9v0c0.2-0.6,1.2-0.6,0.9-1.5c0-0.1,0-0.2,0-0.2
		C80.4,51.8,80.1,51.9,80,52.1z"
        />
        <path
          className="st12"
          d="M82,52c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.1,0,0.2,0,0.2l0,0C81.8,52,81.9,52,82,52z"
        />
        <path
          className="st9"
          d="M88.2,57.8c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.1-0.3,0.2-0.4C88.5,57.7,88.3,57.7,88.2,57.8z"
        />
        <path
          className="st8"
          d="M90.6,57.6c-0.1-0.1-0.2-0.1-0.5,0.1c-0.3,0.6,0.2,0.5,0.5,0.7c0.1,0,0.2,0.1,0.2,0.2c0.1-0.1,0.1-0.3,0.2-0.4
		c0,0-0.1-0.1-0.1-0.1C90.8,58,90.8,57.7,90.6,57.6z"
        />
        <path
          className="st8"
          d="M82.4,52c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.2,0c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.3,0,0.4,0
		C82.4,52.3,82.4,52.1,82.4,52z"
        />
        <path
          className="st12"
          d="M82.7,52.1C82.7,52,82.7,52,82.7,52.1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.3,0,0.4
		C82.6,52.4,82.7,52.3,82.7,52.1z"
        />
        <path
          className="st10"
          d="M85.6,55.5C85.6,55.5,85.6,55.5,85.6,55.5C85.6,55.4,85.6,55.4,85.6,55.5C85.6,55.4,85.6,55.4,85.6,55.5z"
        />
        <path
          className="st10"
          d="M80.9,53.7C80.9,53.7,80.8,53.7,80.9,53.7C80.8,53.7,80.9,53.7,80.9,53.7L80.9,53.7z"
        />
        <path
          className="st10"
          d="M80.9,53.7C80.9,53.7,80.9,53.8,80.9,53.7C80.9,53.8,80.9,53.7,80.9,53.7L80.9,53.7z"
        />
      </g>
      <g>
        <g>
          <path
            className="st21"
            d="M21.1,21.4c0,5.6,0.5,10.2,1.6,13.9s3,5.5,5.7,5.5c1.3,0,2.4-0.3,3.1-0.8c0.7-0.5,1.1-1.1,1.3-1.8
			s0.3-1.4,0.3-2.3c0-0.9,0.1-2,0.1-3.3v-2.1c0-1.5-0.1-2.5-0.2-3.1c-0.1-1.2-0.3-2-0.7-2.4s-0.9-0.7-1.7-0.8
			c-0.8-0.1-1.5-0.2-2.1-0.2s-1.2,0-1.7-0.1v-0.8h24.8v0.8l-2.4,0.2c-1.1,0.1-1.8,0.4-2.2,0.8s-0.6,1.2-0.7,2.4L46.1,30v6.9l0.1,4.8
			h-0.8c-0.5-1-1.3-2-2.4-2.8c-1.1-0.8-2.4-1.3-3.8-1.3c-1.2,0-3,0.8-5.3,2.3s-5.3,2.3-9.1,2.3c-5.2,0-9.6-1.9-13.2-5.7
			S6.2,28,6.2,22.2c0-6,2-10.8,5.9-14.5c3.9-3.6,8.9-5.5,15-5.5c3.3,0,6.5,0.7,9.4,2.2c3,1.5,4.6,2.3,5,2.3c1,0,1.7-0.4,2.1-1.1
			c0.4-0.8,0.7-1.8,1-3.3h1.1v17.1h-0.9C43.7,15,42,11.3,39.5,8.3s-5.6-4.6-9.4-4.6c-3,0-5.3,1.8-6.8,5.3
			C21.9,12.7,21.1,16.8,21.1,21.4z"
          />
          <path
            className="st21"
            d="M74.1,40.3c2.1,0,4.2-1,6.3-2.9c2.1-1.9,4-4.2,5.6-6.7s3.1-5.2,4.4-8.1h0.8v18.9H49.7v-0.8
			c0.3,0,0.7,0,1.2,0s1.3-0.1,2.3-0.2c1-0.1,1.8-0.4,2.3-0.9s0.7-1.2,0.8-2.3l0.1-2.4V9.6l-0.1-2.4c0-1.2-0.3-1.9-0.6-2.4
			C55.3,4.4,54.8,4.1,54,4c-0.8-0.1-1.4-0.2-2-0.2s-1.1,0-1.5-0.1V2.9H76v0.8l-3.5,0.2c-1.1,0.1-1.8,0.4-2.2,0.8
			c-0.4,0.4-0.6,1.2-0.7,2.4l-0.1,2.4v30.9L74.1,40.3z"
          />
          <path
            className="st21"
            d="M100.7,29.4l-1.6,5.5c-0.4,1.3-0.5,2.3-0.5,3c0,0.8,0.2,1.4,0.6,1.7s0.9,0.5,1.6,0.7
			c0.6,0.1,1.3,0.2,1.9,0.2s1.2,0.1,1.7,0.1v0.8h-15v-0.8l3.1-0.2c1.2-0.1,2.1-0.4,2.7-0.9c0.6-0.4,1.1-1,1.4-1.7s0.6-1.5,1-2.4
			s0.7-1.7,0.9-2.5l10.1-30.3h5.8L125.6,33l1.6,4.1c0.5,1.1,1,1.9,1.5,2.4s1.2,0.8,2.1,0.9l2.2,0.2v0.8h-25.2v-0.8
			c0.4,0,0.8,0,1.1,0c0.3,0,0.9-0.1,1.8-0.2c0.9-0.1,1.5-0.3,1.9-0.6c0.4-0.2,0.6-0.7,0.6-1.5s-0.2-1.9-0.7-3.3l-1.9-5.6
			C110.6,29.4,100.7,29.4,100.7,29.4z M110.3,28.3l-4.7-13l-4.5,13H110.3z"
          />
          <path
            className="st21"
            d="M136.2,9.5v24.7l0.1,2.5c0.1,1.1,0.4,2,0.9,2.6s1.3,0.9,2.4,1.1l3.7,0.3v0.8H128v-0.8c0.5,0,1.1-0.1,1.7-0.1
			c0.6,0,1.4-0.1,2.3-0.2c0.9-0.1,1.6-0.5,2.1-1.1s0.8-1.4,0.9-2.5l0.1-2.5V9.6L135,7.2c-0.2-0.8-0.6-1.6-1.1-2.1
			c-0.5-0.6-1.1-0.9-1.9-1c-0.7-0.1-1.5-0.2-2.2-0.2s-1.2-0.1-1.6-0.1V2.9h19.6l8.9,23l8-23h19.1v0.8h-1.2c-0.4,0-1.1,0.1-2.2,0.2
			c-1.1,0.1-1.9,0.4-2.3,0.9c-0.4,0.5-0.7,1.3-0.8,2.4l-0.1,2.4v25.1l0.1,2.4c0.1,1.1,0.3,1.9,0.7,2.4c0.4,0.4,1.1,0.7,2.2,0.9
			l2.6,0.2v0.8h-24.2v-0.8l2.4-0.2c1.1-0.1,1.9-0.4,2.2-0.8c0.4-0.4,0.6-1.2,0.7-2.4l0.1-2.4V15.2c0-1.1,0-2.4,0.1-3.9
			s0.2-2.9,0.3-4.2h-0.3l-2.2,6.8l-9.4,27.6h-3.3l-12.7-32H136.2z"
          />
        </g>
      </g>
      <g>
        <path
          d="M157.5,59.6h-0.6c-0.2-0.2-0.4-0.5-0.5-0.7s-0.1-0.6-0.1-1l0.4-0.9c1.1-1.9,2.2-3.9,3.3-5.7c4.1-7.3,7.6-12.9,10.4-16.8
		c0.6-0.9,1.2-1.8,1.9-2.7c0.1-0.3,0.5-0.9,1.2-1.7c0.6-0.6,1.1-0.9,1.5-0.9c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8
		c-0.1,0.8-0.5,1.8-1.5,3l-3.3,5c-4.3,6.2-7.7,11.1-9.9,14.9l0.9-0.8c1.6-2,3.7-3.9,6.4-5.6c0.8-0.5,1.5-0.8,2.2-0.8
		c0.5,0,0.9,0.1,1.2,0.4c0.6,0.6,0.9,1.3,0.9,2.1c0,0.3-0.1,0.7-0.2,1c-0.7,2.5-2,4.5-3.9,6.2h-0.1c0.5-0.1,1-0.2,1.5-0.3
		c3-0.9,5.3-2.8,6.9-5.6c0.1-0.2,0.3-0.3,0.5-0.3s0.4,0.1,0.5,0.3s0.2,0.3,0.2,0.4l-0.1,0.3c-1.6,2.8-3.9,4.8-6.8,6.1
		c-1.5,0.6-3,1-4.5,1l-1.7-0.1c-0.6,0.1-1.1,0.2-1.6,0.2c-0.7,0-1.4-0.1-2.1-0.3c-0.4-0.2-0.6-0.5-0.6-1c0-0.3,0.1-0.5,0.4-0.7
		c0.3-0.2,0.6-0.3,0.9-0.3l0.6,0.1c1.8-0.3,3.3-1.2,4.6-2.5s2.2-2.8,2.8-4.5c0-0.2,0-0.4,0.1-0.6h-0.1h0.1h-0.3
		c-1.3,0.4-2.3,1-3.2,1.8c-1.9,1.6-3.5,3.1-4.6,4.6l-2.6,2.7c-0.5,0.6-0.8,1-1.2,1.4c-0.3,0.3-0.6,0.6-0.8,0.9c0,0.1,0,0.1-0.1,0.1
		C157.6,59.5,157.6,59.5,157.5,59.6z"
        />
        <path
          d="M175.2,72.5c-2.1,0-3.1-1.1-3.1-3.3c0-0.8,0.2-1.6,0.6-2.3c0.5-1.3,1.2-2.4,2-3.5c3.4-4.3,8.4-7.9,14.8-10.9l0.5-0.8
		c1-1.8,1.6-2.8,1.7-3.2c0.1-0.4,0.2-0.7,0.2-1s-0.2-0.4-0.7-0.4c-0.3,0-0.8,0.1-1.3,0.3h-0.2c-1.8,0.9-3.4,1.9-4.9,3.3
		c-2.1,1.7-3.9,2.9-5.3,3.4c-0.3,0.2-0.7,0.3-1,0.3c-1.3,0-2-0.7-2.1-2c0-0.7,0.2-1.5,0.5-2.2c1.1-2.3,2.7-4.1,4.6-5.1
		c0.2-0.1,0.3-0.2,0.5-0.2c0.5,0,0.8,0.2,0.9,0.6l0.1,0.4c0,0.4-0.3,0.9-0.8,1.4c-1.5,1.7-2.5,3.3-3.1,4.6l-0.2,0.5
		c0.4-0.1,0.8-0.2,1.1-0.4l0.3-0.1c1.1-0.6,2.5-1.5,4.1-2.7c1.5-1.4,3.3-2.4,5.2-3.2l0.2-0.1c0.7-0.2,1.2-0.3,1.5-0.3
		c1.1,0,1.9,0.5,2.3,1.4c0.2,0.5,0.3,0.9,0.3,1.4c0,0.6-0.3,1.4-0.8,2.5l4.1-1.5c0.5,0,0.7,0.2,0.7,0.6c0,0.1,0,0.2,0,0.3
		s-0.1,0.3-0.4,0.4c-1.8,0.6-3.5,1.3-5.2,2.1c-1.8,3.6-4.1,7.2-6.7,10.9c-0.1,0.2-0.3,0.3-0.4,0.4c-3.1,4.9-6.3,7.7-9.3,8.5
		L175.2,72.5z M174.8,70.2c0.9-0.2,2-0.8,3.3-1.9c2.1-1.9,4-4.1,5.6-6.5s3.1-4.8,4.6-7.2c-7.6,3.8-12.4,8.2-14.3,13
		c-0.1,0.4-0.2,0.9-0.2,1.5C173.8,69.8,174.2,70.2,174.8,70.2z"
        />
      </g>
      <path
        d="M227.3,75.2c-0.3,0-0.6,0.1-0.9,0.3c-3.1,3.3-6.5,5.1-10.1,5.3c-1.5,0-2.3-0.4-2.5-1.2c-0.2-0.8-0.3-1.6-0.3-2.4V75
	c0-1-0.2-1.9-0.5-2.7c-0.3-0.8-0.7-1.2-1.2-1.3c-0.5-0.1-0.8-0.1-1-0.1c-0.6,0-1.2,0.2-1.9,0.5c-1.8,0.9-3.8,2.4-5.8,4.5
	c0.1-0.3,0.3-0.7,0.4-0.9c0.2-0.3,0.3-0.6,0.5-0.9s0.4-0.7,0.6-1c0.9-1.3,1.5-2.6,1.7-4.1c0-0.9-0.3-1.7-0.8-2.3
	c-0.5-0.6-1.2-0.9-2.1-0.9c-0.7,0-1.4,0.3-2.1,1c-0.7,0.7-1.5,1.3-2.3,1.9c0,0.1-0.1,0.2-0.2,0.2c-3.7,3-7.3,6.5-10.8,10.5l3.6-5.1
	c0.8-0.8,1.5-1.7,2.2-2.7l0.3-0.5c0.7-0.7,1.1-1.5,1.4-2.6c0-1.1-0.7-1.7-2.2-1.7c-1.7,0-3.4,1.2-5,3.6h-0.2
	c-1.6,2.1-3.1,4.2-4.6,6.3c-0.5,0.8-1,1.7-1.4,2.6c-2.8,3.2-6.1,5.8-9.9,7.9c-1.7,0.9-3.4,1.4-5,1.4c-2.2,0-3.3-0.9-3.3-2.7
	c0-0.9,0.2-1.8,0.5-2.7l0.2-0.3c0.7-2.3,1.5-4.2,2.6-5.7c1-1.5,1.5-2.7,1.5-3.5c-0.1-1.3-0.9-1.9-2.4-1.9c-1.1,0-2.5,0.9-3.9,2.6
	c-0.2-0.6-0.6-1-1-1.4c-1.4-1.4-3.1-2.1-5.3-2.1c-3.3,0.7-6.3,2.6-8.8,5.7c-0.3-0.2-0.7-0.4-1.3-0.5c-0.8-0.1-1.6-0.1-2.5-0.1l0,0
	l-3.6,0.1c2.9-4.6,5.7-9.9,8.4-15.7l0.5-1c1.6-3.2,2.8-6.6,3.5-10.1c0.2-1.1,0.4-2.1,0.4-3c0-3-1.1-5.7-3.2-8l-0.1-0.1
	c-2.7-2.2-5.9-3.4-9.5-3.5c-6.9,0-13.1,2.1-18.4,6.2c-2.7,2-5,4.5-6.8,7.3c-3.2,4.3-5,8.9-5.3,13.9c0,0.7,0.3,1.3,0.7,1.8
	c0.5,0.5,1.1,0.7,1.7,0.7c0.7,0,1.3-0.3,1.8-0.8c0.4-0.5,0.6-1,0.6-1.7c0.4-2.7,1.1-5.4,2.1-8.1c1-2.6,2.5-5,4.5-7.1
	c3.9-4.2,8.7-7,14.4-8.1c1-0.2,2-0.3,3-0.3c3.8,0,6.4,1.1,8,3.3c0.6,1,0.9,2.2,0.9,3.4c0,2.9-0.8,6-2.3,9.3c-1.6,3.4-3.3,6.6-5,9.8
	c-2.2,4.3-4.6,8.5-7.3,12.6c-12.6,1.5-23.1,5.8-31.2,12.9c-2.8,2.5-5.2,5.3-7.2,8.5c-1.4,2.3-2.1,4.6-2.1,7c0,1.5,0.4,3,1.2,4.5
	c1.6,2.7,4.2,4.1,7.7,4.1c0.7,0,1.4-0.1,2.2-0.3c3.8-0.8,7.3-2.5,10.3-5.1c4.9-3.9,10.4-9.7,16.2-17.3c2.7-3.5,5.4-7.2,7.8-11
	c1.7-0.1,3.7-0.2,5.8-0.2l1.9,0.1c-1.5,2.4-2.3,4.8-2.3,7.1c0,0.9,0.2,1.8,0.7,2.6c0.7,1.1,1.6,1.7,2.7,1.7c2.9-0.1,7-2.5,12.5-7.2
	v0.9c0,1.8,0.6,3.5,1.9,5c1.7,1.7,3.6,2.6,5.7,2.6c1,0,2.1-0.2,3.3-0.7c3.6-1.3,7-3.3,10.1-6c-0.2,0.5-0.3,1-0.5,1.6l-0.5,2.7
	c0,1.4,0.6,2.1,1.9,2.1h0.2c0.2,0,0.4,0,0.5-0.1s0.2-0.1,0.3-0.3c2.3-2.3,4.9-5.3,7.7-9.1c4.7-5.5,8.7-9.4,12.2-11.8
	c-0.2,0.2-0.3,0.4-0.3,0.5c-1.6,3-3.1,5.9-4.5,8.9l-1.7,3.6l-0.2,0.9c0,0.5,0.2,0.9,0.7,1.2c0.1,0.3,0.5,0.5,1,0.5
	c0.2,0,0.5-0.1,0.7-0.3c3.7-3.9,6.9-6.9,9.6-9.1c0.2-0.1,0.4-0.3,0.5-0.5c0.9-0.7,1.5-1.1,1.6-1.1c0.2-0.1,0.3-0.1,0.4-0.3
	c-0.2,0.8-0.3,1.8-0.3,2.9c0,1,0.2,2.1,0.5,3.1c1.1,2.4,3,3.6,5.7,3.6c2.3,0,4.6-0.7,7-2.2c2.3-1.1,4.3-2.6,6.2-4.3
	c0.3-0.3,0.5-0.6,0.5-0.9C228.9,75.7,228.4,75.2,227.3,75.2z M114.7,98.9c-2.3,2.6-4.9,4.9-7.6,6.8c-2.7,1.8-5,2.7-6.8,2.7
	c-2.8,0-4.1-1.3-4.1-4.2c0-0.5,0.1-1.1,0.2-1.6c1-3.1,2.8-5.9,5.3-8.3c7-7.5,16.5-12.2,28.2-14C125.3,86.9,120.2,93.2,114.7,98.9z
	 M160.3,77.5c-0.1,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.3v0.2l-1.5,1.4H158c-2.3,2.2-4.7,4.1-7.4,5.7c-0.9,0.6-1.9,1-2.9,1.2
	c1.1-3.7,3.1-6.9,6-9.8c1.7-1.9,3.3-2.9,4.6-2.9s1.9,0.9,1.9,2.6l0.2,0.5C160.4,77.2,160.4,77.4,160.3,77.5z"
      />
      <g>
        <path
          d="M16.3,52.6h2c0.5,0,0.9,0.1,1.1,0.4c0.2,0.2,0.3,0.4,0.3,0.7l0,0c0,0.5-0.3,0.8-0.6,0.9c0.5,0.2,0.8,0.5,0.8,1.1l0,0
		c0,0.8-0.6,1.2-1.6,1.2h-2.1v-4.3H16.3z M18.8,53.9c0-0.3-0.2-0.4-0.6-0.4h-0.9v0.9h0.9C18.5,54.4,18.8,54.2,18.8,53.9L18.8,53.9z
		 M18.3,55.2h-1.1v1h1.1c0.4,0,0.7-0.1,0.7-0.5l0,0C19,55.3,18.8,55.2,18.3,55.2z"
        />
        <path d="M22.5,52.6h3.3v0.9h-2.3v0.9h2v0.9h-2v0.9h2.4v0.9h-3.3v-4.5H22.5z" />
        <path d="M29.9,52.6h0.9l1.9,4.4h-1l-0.4-1h-1.8l-0.4,1h-1L29.9,52.6z M30.9,55.1l-0.6-1.4l-0.6,1.4H30.9z" />
        <path
          d="M34.8,55.1v-2.5h1v2.5c0,0.7,0.4,1.1,0.9,1.1c0.6,0,0.9-0.4,0.9-1v-2.5h1v2.5c0,1.3-0.7,2-1.9,2
		C35.5,57,34.8,56.4,34.8,55.1z"
        />
        <path d="M42.3,53.5H41v-0.9h3.6v0.9h-1.3V57h-1V53.5z" />
        <path d="M48.3,55.2l-1.7-2.6h1.1l1,1.7l1.1-1.7h1.1l-1.7,2.6v1.7h-1v-1.7H48.3z" />
        <path d="M58.3,52.6h0.9l1.9,4.4h-1l-0.4-1h-1.8l-0.4,1h-1L58.3,52.6z M59.3,55.1l-0.6-1.4l-0.6,1.4H59.3z" />
        <path
          d="M63.5,52.6h2c0.6,0,1,0.2,1.3,0.4c0.2,0.2,0.4,0.6,0.4,1l0,0c0,0.7-0.4,1.1-0.9,1.3l1.1,1.6h-1.1l-0.9-1.4h-0.8v1.4h-1
		L63.5,52.6L63.5,52.6z M65.4,54.7c0.5,0,0.7-0.2,0.7-0.6l0,0c0-0.4-0.3-0.6-0.8-0.6h-0.9v1.2C64.4,54.7,65.4,54.7,65.4,54.7z"
        />
        <path d="M70.7,53.5h-1.3v-0.9H73v0.9h-1.3V57h-1V53.5z" />
        <path d="M75.5,52.6h1v4.3h-1V52.6z" />
        <path
          d="M79,56.3l0.6-0.7c0.4,0.3,0.8,0.5,1.3,0.5c0.4,0,0.6-0.2,0.6-0.4l0,0c0-0.2-0.1-0.4-0.9-0.6c-0.9-0.2-1.4-0.5-1.4-1.3l0,0
		c0-0.8,0.6-1.3,1.5-1.3c0.6,0,1.2,0.2,1.6,0.6l-0.5,0.7c-0.4-0.3-0.8-0.4-1.1-0.4c-0.4,0-0.6,0.2-0.6,0.4l0,0
		c0,0.3,0.2,0.4,0.9,0.6c0.9,0.2,1.4,0.5,1.4,1.3l0,0c0,0.9-0.7,1.3-1.6,1.3C80.2,57,79.5,56.8,79,56.3z"
        />
        <path d="M85.9,53.5h-1.3v-0.9h3.6v0.9h-1.3V57h-1C85.9,57,85.9,53.5,85.9,53.5z" />
        <path
          d="M90.7,52.6h2c0.6,0,1,0.2,1.3,0.4c0.2,0.2,0.4,0.6,0.4,1l0,0c0,0.7-0.4,1.1-0.9,1.3l1.1,1.6h-1.1l-0.9-1.4h-0.8v1.4h-1
		L90.7,52.6L90.7,52.6z M92.6,54.7c0.5,0,0.7-0.2,0.7-0.6l0,0c0-0.4-0.3-0.6-0.8-0.6h-0.9v1.2C91.6,54.7,92.6,54.7,92.6,54.7z"
        />
        <path d="M97.9,55.2l-1.7-2.6h1.1l1,1.7l1.1-1.7h1.1l-1.7,2.6v1.7h-1L97.9,55.2L97.9,55.2z" />
      </g>
      <path
        className="st21"
        d="M177.2,15.5v-4.6C177,12.4,177.1,14,177.2,15.5z"
      />
      <path
        className="st21"
        d="M137.1,39.3c-0.5-0.6-0.8-1.4-0.9-2.6l-0.1-2.5v-5.3c-0.4-0.2-0.7-0.3-1.1-0.4v5.7l-0.1,2.5
	c-0.1,1.1-0.4,1.9-0.9,2.5s-1.2,1-2.1,1.1c-0.4,0.1-0.8,0.1-1.1,0.1c0.1,0.3,0.1,0.7,0,1h12.4v-0.8l-3.7-0.3
	C138.4,40.3,137.6,39.9,137.1,39.3z"
      />
    </svg>
  </Styles>
)

export default Logo
